import * as Yup from 'yup';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import { isEmpty, useFirestoreConnect, useFirestore } from 'react-redux-firebase';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import plusFill from '@iconify/icons-eva/plus-fill';
import eyeOutlined from '@iconify/icons-ant-design/eye-outlined';
import {
  Container,
  Stack,
  Typography,
  Card,
  Grid,
  TextField,
  MenuItem,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Button,
  TablePagination,
  InputAdornment,
  CircularProgress,
  Box,
  Alert
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormik, Form, FormikProvider } from 'formik';
import { ListHead, ListToolbar } from '../../../components/_dashboard/owner/@general';
import {
  ExpendituresEmpty,
  ExpendituresViewDialog,
  ExpendituresDeleteDialog
} from '../../../components/_dashboard/owner/finances';
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';

import { useExpenditures } from '../../../hooks/owner';
import { addExpenditure } from '../../../redux/slices/owner/finances';
import { EditExpenditureDialog } from '../../../components/_adds/owner';
import { checkForRole } from '../../../utils/checkAuthUserRole';

import { condenseText } from '../../../utils/formatText';

const TABLE_HEAD = [
  { id: 'category', label: 'Category', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'amount', label: 'Amount', alignRight: false },
  { id: '' }
];

function FinanceExpenditure() {
  // const { expenditureCategories } = useSelector((state) => state.firestore.ordered);
  const { searchedExpenditures } = useSelector((state) => state.firestore.ordered);
  const { requesting } = useSelector((state) => state.firestore.status);
  const authUserUid = useSelector((state) => state.firebase.auth.uid);
  const { expendituresCount, token, ownerId } = useSelector((state) => state.firebase.profile);
  const firestore = useFirestore();

  // ----------------------------------------------------------------------

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('firstName');
  const [filterName, setFilterName] = useState('');
  const [expendituresNum, setExpendituresNum] = useState(0);
  const [selectedExpenditure, setSelectedExpenditure] = useState(null);
  const [isExpenditureEditOpen, setIsExpenditureEditOpen] = useState(false);
  const [expenditureOpen, setExpenditureOpen] = useState(false);
  const [isExpenditureDeleteOpen, setIsExpenditureDeleteOpen] = useState(false);
  const [cursor, setCursor] = useState(undefined);
  const [error, setError] = useState(null);
  const expenditures = useExpenditures({
    startAfter: cursor,
    limit: rowsPerPage,
    filterName
  });

  // ---------------------------------------------------------------------------

  const dispatch = useDispatch();
  const { identifier } = token && checkForRole(token);
  const ownerUid = identifier === 'staff' ? ownerId : authUserUid;

  // ---------------------------------------------------------------------------

  const FinanceExpenditureSchema = Yup.object().shape({
    category: Yup.string().required('Category is required'),
    amount: Yup.number().required('Amount is required')
  });

  const [initialValues] = useState({
    category: '',
    amount: ''
  });

  const formik = useFormik({
    initialValues,
    validationSchema: FinanceExpenditureSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        setError(null);
        const expenditureInfo = {
          ...values,
          searchMatch: condenseText(values.category)
        };
        await dispatch(addExpenditure({expenditureInfo, ownerId: ownerUid}));
        resetForm();
      } catch (error) {
        setError(error.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  // ---------------------------------------------------------------------------

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setCursor(expenditures.data[expenditures.data.length - 1].createdAt);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleDelete = (item) => {
    setIsExpenditureDeleteOpen(true);
    setSelectedExpenditure(item);
  };

  const handleEdit = (item) => {
    setIsExpenditureEditOpen(true);
    setSelectedExpenditure(item);
  };

  const handleExpenditureClick = (item) => {
    setSelectedExpenditure(item);
    setExpenditureOpen(true);
  };

  // ---------------------------------------------------------------------------

  useEffect(() => {
    if (!isExpenditureEditOpen && !isExpenditureDeleteOpen && !expenditureOpen) {
      setSelectedExpenditure(null);
    }
  }, [isExpenditureEditOpen, isExpenditureDeleteOpen, expenditureOpen]);

  useEffect(() => {
    if (requesting.searchedExpenditures) {
      if (page) setPage(0);
      else setPage(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requesting.searchedExpenditures]);

  useEffect(() => {
    const getOwner = async () => {
      const owner = await firestore.collection('users').doc(ownerId).get()
      setExpendituresNum(owner.data().expendituresCount)
    }

    getOwner()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // ---------------------------------------------------------------------------

  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * rowsPerPage - filterName
            ? searchedExpenditures?.length
            : expenditures.data?.length
        )
      : 0;

  useFirestoreConnect({
    collection: 'expenditureCategories',
    where: ['isActive', '==', true],
    orderBy: ['createdAt', 'desc']
  });

  const { errors, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Page title="Expenditure | FashionsPal">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Expenditure
          </Typography>
        </Stack>
        {error && (
          <Alert sx={{ mb: 2 }} severity="error">
            {error}
          </Alert>
        )}
        <Card sx={{ p: 2.5, mb: 5 }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    type="text"
                    label="Category"
                    {...getFieldProps('category')}
                    error={Boolean(errors.category)}
                    helperText={errors.category}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Amount"
                    {...getFieldProps('amount')}
                    error={Boolean(errors.amount)}
                    helperText={errors.amount}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">₵</InputAdornment>
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <LoadingButton
                    sx={{ mt: 0.5 }}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    startIcon={<Icon icon={plusFill} />}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Add
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Card>

        <Card>
          {!!expenditures.data.length && (
            <ListToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
              requesting={requesting.searchedExpenditures}
            />
          )}

          {expenditures.loading || requesting.searchedExpenditures ? (
            <Box
              sx={{
                height: '60vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgress size={55} />
            </Box>
          ) : (
            <>
              {!expenditures.data.length ? (
                <ExpendituresEmpty />
              ) : (
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <ListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={
                          filterName ? searchedExpenditures?.length || 0 : expendituresCount || 0
                        }
                        onRequestSort={handleRequestSort}
                      />
                      <TableBody>
                        {(filterName
                          ? searchedExpenditures || expenditures.data
                          : expenditures.data
                        )
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            const { category, amount, createdAt } = row;

                            return (
                              <TableRow
                                key={row.id}
                                sx={{ cursor: 'pointer' }}
                                tabIndex={-1}
                                onClick={() => handleExpenditureClick(row)}
                              >
                                <TableCell>
                                  <Typography variant="subtitle2" noWrap>
                                    {rowsPerPage * (page + 1) + (index + 1) - rowsPerPage}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">{category || 'N/A'}</TableCell>
                                <TableCell align="left">
                                  <Typography variant="subtitle2">
                                    {createdAt ? format(createdAt.toDate(), 'dd MMM yyyy') : 'N/A'}
                                  </Typography>
                                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    {createdAt ? format(createdAt.toDate(), 'p') : 'N/A'}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">{amount ? `₵${amount}` : 'N/A'}</TableCell>
                                <TableCell align="right">
                                  <Button
                                    variant="contained"
                                    component="div"
                                    startIcon={<Icon icon={eyeOutlined} />}
                                    onClick={() => handleExpenditureClick(row)}
                                    sx={{ mr: 1.5 }}
                                  >
                                    View
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      {isEmpty(searchedExpenditures) && filterName ? (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : null}
                    </Table>
                  </TableContainer>
                </Scrollbar>
              )}
            </>
          )}

          {!requesting.searchedExpenditures && !!expenditures.data.length && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filterName ? searchedExpenditures?.length || 0 : expendituresNum || expendituresCount || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>

        {/* ----- Dialog Start ----- */}
        {isExpenditureDeleteOpen && (
          <ExpendituresDeleteDialog
            isOpen={isExpenditureDeleteOpen}
            setIsOpen={setIsExpenditureDeleteOpen}
            selectedExpenditure={selectedExpenditure}
          />
        )}
        {isExpenditureEditOpen && (
          <EditExpenditureDialog
            isOpen={isExpenditureEditOpen}
            setIsOpen={setIsExpenditureEditOpen}
            selectedExpenditure={selectedExpenditure}
          />
        )}
        {expenditureOpen && (
          <ExpendituresViewDialog
            isOpen={expenditureOpen}
            setIsOpen={setExpenditureOpen}
            expenditure={selectedExpenditure}
            handleEdit={() => handleEdit(selectedExpenditure)}
            handleDelete={() => handleDelete(selectedExpenditure)}
          />
        )}
        {/* ----- Dialog End ----- */}
      </Container>
    </Page>
  );
}

export default FinanceExpenditure;
