import ownerRoutes from '../routes/owner';
import superAdminRoutes from '../routes/super_admin';
import staffRoutes from '../routes/staff';

export const checkForRole = (token) => {
  const { admin, superAdmin, staff } = token.claims;
  if (superAdmin) {
    return {
      displayName: 'Super Admin',
      identifier: 'super_admin',
      routes: superAdminRoutes
    };
  }
  if (admin) {
    return {
      displayName: 'Admin',
      identifier: 'owner',
      routes: ownerRoutes
    };
  }
  if (staff) {
    return {
      displayName: 'Staff',
      identifier: 'staff',
      permissions: staff,
      routes: staffRoutes
    };
  }
  return {
    displayName: 'Customer',
    identifier: 'customer',
    routes: []
  };
};
