import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const addSubscriptionsCategory = createAsyncThunk(
  'subscriptionsCategory/add',
  async (categoryData, { extra: { getFirestore, getFirebase } }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    const superAdminId = firebase.auth().currentUser.uid;
    const superAdminDocRef = firestore.collection('users').doc(superAdminId);
    const categoryDocRefId = firestore.collection('subscriptionsCategories').doc().id;
    const categoryDocRef = firestore.collection('subscriptionsCategories').doc(categoryDocRefId);
    const timestamp = firestore.FieldValue.serverTimestamp();

    await firestore.runTransaction(async (transaction) => {
      const superAdminDoc = await transaction.get(superAdminDocRef);

      if (!superAdminDoc.exists) {
        throw new Error('Super Admin does not exist!');
      }

      const newCount = superAdminDoc.data().subscriptionsCategoriesCount + 1;
      transaction.set(categoryDocRef, {
        ...categoryData,
        isActive: true,
        createdAt: timestamp,
        updatedAt: timestamp
      });

      transaction.update(superAdminDocRef, { subscriptionsCategoriesCount: newCount });
    });
  }
);

// --------------------------------------------------------------

export const updateSubscriptionsCategory = createAsyncThunk(
  'subscriptionsCategory/update',
  async (measurementData, { extra: { getFirestore } }) => {
    const firestore = getFirestore();

    const timestamp = firestore.FieldValue.serverTimestamp();

    firestore.update(
      { collection: 'subscriptionsCategories', doc: measurementData.id },
      {
        ...measurementData,
        updatedAt: timestamp
      }
    );
  }
);

// ------------------------------------------------------------------------

export const removeSubscriptionsCategory = createAsyncThunk(
  'subscriptionsCategory/delete',
  async (categoryData, { extra: { getFirestore, getFirebase } }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    const batch = firestore.batch();

    const superAdminId = firebase.auth().currentUser.uid;
    const superAdminDocRef = firestore.collection('users').doc(superAdminId);
    const categoryDocRef = firestore.collection('subscriptionsCategories').doc(categoryData.id);
    const subscriptionsPlansWithCategory = firestore
      .collection('subscriptionsPlans')
      .where('categoryId', '==', categoryData.id);

    const subscriptionsTopupsWithCategory = firestore
      .collection('subscriptionsTopups')
      .where('categoryId', '==', categoryData.id);

    await firestore.runTransaction(async (transaction) => {
      const superAdminDoc = await transaction.get(superAdminDocRef);
      const categoryDoc = await transaction.get(categoryDocRef);
      const subscriptionsPlansWithCategoryQuery = await subscriptionsPlansWithCategory.get();
      const subscriptionsTopupsWithCategoryQuery = await subscriptionsTopupsWithCategory.get();

      if (!superAdminDoc.exists) {
        throw new Error('Super Admin does not exist!');
      }

      if (!categoryDoc.exists) {
        throw new Error('Subscription category does not exist!');
      }

      const newCategoriesCount = superAdminDoc.data().subscriptionsCategoriesCount - 1;
      let plansCount = superAdminDoc.data().subscriptionsPlansCount;
      let topupsCount = superAdminDoc.data().subscriptionTopupsCount;

      transaction.delete(categoryDocRef);
      subscriptionsPlansWithCategoryQuery.docs.forEach((doc) => {
        batch.delete(doc.ref);
        plansCount -= 1;
      });
      subscriptionsTopupsWithCategoryQuery.docs.forEach((doc) => {
        batch.delete(doc.ref);
        topupsCount -= 1;
      });

      transaction.update(superAdminDocRef, {
        subscriptionsCategoriesCount: newCategoriesCount,
        subscriptionsPlansCount: plansCount,
        subscriptionTopupsCount: topupsCount
      });

      await batch.commit();
    });
  }
);

// -----------------------------------------------------------------------------------------

// =====================================  PLAN =============================================

// -----------------------------------------------------------------------------------------

export const addPlan = createAsyncThunk(
  'plan/add',
  async (planData, { extra: { getFirestore, getFirebase } }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    const superAdminId = firebase.auth().currentUser.uid;
    const superAdminDocRef = firestore.collection('users').doc(superAdminId);
    const planDocRefId = firestore.collection('subscriptionsPlans').doc().id;
    const planDocRef = firestore.collection('subscriptionsPlans').doc(planDocRefId);
    const timestamp = firestore.FieldValue.serverTimestamp();

    await firestore.runTransaction(async (transaction) => {
      const superAdminDoc = await transaction.get(superAdminDocRef);

      if (!superAdminDoc.exists) {
        throw new Error('Super Admin does not exist!');
      }

      const newCount = superAdminDoc.data().subscriptionsPlansCount + 1;
      transaction.set(planDocRef, {
        ...planData,
        isActive: true,
        createdAt: timestamp,
        updatedAt: timestamp
      });

      transaction.update(superAdminDocRef, { subscriptionsPlansCount: newCount });
    });
  }
);

// --------------------------------------------------------------------

export const updatePlan = createAsyncThunk(
  'plan/update',
  async (planData, { extra: { getFirestore } }) => {
    const firestore = getFirestore();

    const timestamp = firestore.FieldValue.serverTimestamp();

    firestore.update(
      { collection: 'subscriptionsPlans', doc: planData.id },
      {
        ...planData,
        updatedAt: timestamp
      }
    );
  }
);

// ------------------------------------------------------------------------

export const removePlan = createAsyncThunk(
  'plan/delete',
  async (planData, { extra: { getFirestore, getFirebase } }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    const superAdminId = firebase.auth().currentUser.uid;
    const superAdminDocRef = firestore.collection('users').doc(superAdminId);
    const planDocRef = firestore.collection('subscriptionsPlans').doc(planData.id);

    await firestore.runTransaction(async (transaction) => {
      const superAdminDoc = await transaction.get(superAdminDocRef);
      const planDoc = await transaction.get(planDocRef);

      if (!superAdminDoc.exists) {
        throw new Error('Super Admin does not exist!');
      }

      if (!planDoc.exists) {
        throw new Error('Plan does not exist!');
      }

      const newCount = superAdminDoc.data().subscriptionsPlansCount - 1;
      transaction.delete(planDocRef);

      transaction.update(superAdminDocRef, { subscriptionsPlansCount: newCount });
    });
  }
);

// -----------------------------------------------------------------------------------------

// =====================================  INDIVIDUAL =======================================

// -----------------------------------------------------------------------------------------

export const addIndividual = createAsyncThunk(
  'individual/add',
  async (individualData, { extra: { getFirestore, getFirebase } }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    const superAdminId = firebase.auth().currentUser.uid;
    const superAdminDocRef = firestore.collection('users').doc(superAdminId);
    const individualDocRefId = firestore.collection('subscriptionsTopups').doc().id;
    const individualDocRef = firestore.collection('subscriptionsTopups').doc(individualDocRefId);
    const timestamp = firestore.FieldValue.serverTimestamp();

    await firestore.runTransaction(async (transaction) => {
      const superAdminDoc = await transaction.get(superAdminDocRef);

      if (!superAdminDoc.exists) {
        throw new Error('Super Admin does not exist!');
      }

      const newCount = superAdminDoc.data().subscriptionTopupsCount + 1;
      transaction.set(individualDocRef, {
        ...individualData,
        isActive: true,
        createdAt: timestamp,
        updatedAt: timestamp
      });

      transaction.update(superAdminDocRef, { subscriptionTopupsCount: newCount });
    });
  }
);

// --------------------------------------------------------------------------------------

export const updateIndividual = createAsyncThunk(
  'individual/update',
  async (individualData, { extra: { getFirestore } }) => {
    const firestore = getFirestore();

    const timestamp = firestore.FieldValue.serverTimestamp();

    firestore.update(
      { collection: 'subscriptionsTopups', doc: individualData.id },
      {
        ...individualData,
        updatedAt: timestamp
      }
    );
  }
);

// ------------------------------------------------------------------------

export const removeIndividual = createAsyncThunk(
  'individual/delete',
  async (individualData, { extra: { getFirestore, getFirebase } }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    const superAdminId = firebase.auth().currentUser.uid;
    const superAdminDocRef = firestore.collection('users').doc(superAdminId);
    const individualDocRef = firestore.collection('subscriptionsTopups').doc(individualData.id);

    await firestore.runTransaction(async (transaction) => {
      const superAdminDoc = await transaction.get(superAdminDocRef);
      const individualDoc = await transaction.get(individualDocRef);

      if (!superAdminDoc.exists) {
        throw new Error('Super Admin does not exist!');
      }

      if (!individualDoc.exists) {
        throw new Error('Top-up does not exist!');
      }

      const newCount = superAdminDoc.data().subscriptionTopupsCount - 1;
      transaction.delete(individualDocRef);

      transaction.update(superAdminDocRef, { subscriptionTopupsCount: newCount });
    });
  }
);

// ---------------------------------------------------------------------------------

const initialState = {
  error: null
};

const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addSubscriptionsCategory.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(addSubscriptionsCategory.rejected, (state, action) => {
      state.error = action.error;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });

    // --------------------------------------------------------------

    builder.addCase(updateSubscriptionsCategory.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(updateSubscriptionsCategory.rejected, (state, action) => {
      state.error = action.error;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });

    // ----------------------------------------------------------------------

    builder.addCase(removeSubscriptionsCategory.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(removeSubscriptionsCategory.rejected, (state, action) => {
      state.error = action.error;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });

    // ---------------------------------------------------------------------------

    builder.addCase(addPlan.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(addPlan.rejected, (state, action) => {
      state.error = action.error;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });

    // ------------------------------------------------------------------------

    builder.addCase(updatePlan.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(updatePlan.rejected, (state, action) => {
      state.error = action.error;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });

    // ----------------------------------------------------------------------

    builder.addCase(removePlan.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(removePlan.rejected, (state, action) => {
      state.error = action.error;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });

    // -----------------------------------------------------------------------

    builder.addCase(addIndividual.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(addIndividual.rejected, (state, action) => {
      state.error = action.error;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });

    // -----------------------------------------------------------------------

    builder.addCase(updateIndividual.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(updateIndividual.rejected, (state, action) => {
      state.error = action.error;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });

    // -----------------------------------------------------------------------

    builder.addCase(removeIndividual.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(removeIndividual.rejected, (state, action) => {
      state.error = action.error;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });
  }
});

export default subscriptionsSlice.reducer;
