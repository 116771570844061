import * as Yup from 'yup';
import { useState, forwardRef, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
import closeFill from '@iconify/icons-eva/close-fill';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { /* alpha, */ styled } from '@mui/material/styles';
import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Box,
  Container,
  TextField,
  Stack,
  Alert,
  MenuItem,
  InputAdornment
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
//
import Toaster from '../../Toaster';
//
import { addPlan, updatePlan } from '../../../redux/slices/super_admin/subscriptions';
//
import { condenseText } from '../../../utils/formatText';
import { getErrorMessage } from '../../../utils/firebaseError';
//
import { useSubscriptionsCategories } from '../../../hooks/super_admin';

AddSubscriptionsPlanDialog.propTypes = {
  children: PropTypes.node,
  selectedPlan: PropTypes.object,
  isPlanOpen: PropTypes.bool,
  setIsPlanOpen: PropTypes.func
};

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

// ----------------------------------------------------------------------

const ChildrenButtonStyle = styled(Button)(() => ({
  width: '100%',
  padding: 0
}));

// ----------------------------------------------------------------------

const DURATION = [
  {
    label: '1 month',
    value: 1
  },
  {
    label: '3 months',
    value: 3
  },
  {
    label: '6 months',
    value: 6
  },
  {
    label: '1 year',
    value: 12
  }
];

export default function AddSubscriptionsPlanDialog({
  children,
  selectedPlan,
  isPlanOpen,
  setIsPlanOpen
}) {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [initialValues] = useState({
    name: '',
    price: '',
    plans: []
  });

  // -----------------------------------------------------------------

  const planContainerRef = useRef(null);
  const toastRef = useRef();
  const categories = useSubscriptionsCategories();
  const dispatch = useDispatch();

  // -----------------------------------------------------------------

  const planSchema = Yup.object().shape({
    name: Yup.string().required('Plan name is required'),
    price: Yup.number().required('Plan price is required')
  });

  const formik = useFormik({
    initialValues,
    validationSchema: planSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        setError(null);
        const plan = {
          ...values,
          searchMatch: condenseText(values.name)
        };
        if (selectedPlan) {
          await dispatch(updatePlan(plan));
        } else {
          await dispatch(addPlan(plan));
        }
        resetForm();
        handleClose();
      } catch (err) {
        planContainerRef.current.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        setError(getErrorMessage(error) || error.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  // -----------------------------------------------------------------

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setError(null);
    setOpen(false);
    if (setIsPlanOpen) setIsPlanOpen(false);
  };

  const handleAdd = () => {
    const lastPlan = formik.values.plans[formik.values.plans.length - 1];
    if (
      (lastPlan && lastPlan.category && (lastPlan.quantity || lastPlan.duration)) ||
      formik.values.plans.length === 0
    ) {
      formik.setFieldValue('plans', [
        ...formik.values.plans,
        { category: '', quantity: '', duration: '' }
      ]);
    } else {
      toastRef.current.handleOpen('Please complete the current fields');
    }
  };

  const handleRemove = (index) => {
    formik.setFieldValue(
      'plans',
      formik.values.plans.filter((_, i) => i !== index)
    );
  };

  // -----------------------------------------------------------------

  // watch isSewOpen change
  useEffect(() => {
    if (isPlanOpen) {
      setOpen(true);
      if (selectedPlan) {
        formik.setValues(selectedPlan);
      }
    } else {
      formik.resetForm();
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlanOpen]);

  // -----------------------------------------------------------------

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <ChildrenButtonStyle onClick={handleClickOpen}>{children}</ChildrenButtonStyle>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <div ref={planContainerRef} style={{ overflowY: 'auto' }}>
          <AppBar sx={{ position: 'sticky' }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <Icon icon={closeFill} width={24} height={24} />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {`${selectedPlan ? 'Edit' : 'Add'} Plan`}
              </Typography>
            </Toolbar>
          </AppBar>
          <Container maxWidth="sm" sx={{ pb: 5 }}>
            <Box sx={{ pb: 3, pt: 4 }}>
              <Typography variant="h4">{`${selectedPlan ? 'Edit' : 'Add New'} Plan`}</Typography>
            </Box>
            {error && (
              <Alert sx={{ mt: -1.5, mb: 3 }} severity="error">
                {error}
              </Alert>
            )}
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack direction="column">
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mb: 3.5 }}>
                    <TextField
                      fullWidth
                      label="Plan Name"
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />

                    <TextField
                      fullWidth
                      label="Price"
                      type="number"
                      {...getFieldProps('price')}
                      error={Boolean(touched.price && errors.price)}
                      helperText={touched.price && errors.price}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">₵</InputAdornment>
                      }}
                    />
                  </Stack>

                  {formik.values.plans.map((plan, index) => (
                    <Stack key={index} direction="column" alignItems="flex-end">
                      <Stack key={index} direction="row" spacing={2} sx={{ width: '100%' }}>
                        <TextField
                          fullWidth
                          select
                          label="Category"
                          {...getFieldProps(`plans[${index}].category`)}
                        >
                          {categories.data.map((option) => (
                            <MenuItem key={option.id} value={option.identifier}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>

                        <TextField
                          fullWidth
                          label="Quantity"
                          type="number"
                          {...getFieldProps(`plans[${index}].quantity`)}
                        />

                        <TextField
                          fullWidth
                          select
                          label="Duration"
                          {...getFieldProps(`plans[${index}].duration`)}
                        >
                          {DURATION.map((option) => (
                            <MenuItem key={option.label} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Stack>
                      <Button
                        size="small"
                        color="error"
                        sx={{ my: 0.5 }}
                        onClick={() => handleRemove(index)}
                      >
                        Remove
                      </Button>
                    </Stack>
                  ))}

                  <Stack direction="row" justifyContent="flex-end" sx={{ my: 2 }}>
                    <Button size="small" startIcon={<Icon icon={plusFill} />} onClick={handleAdd}>
                      Add
                    </Button>
                  </Stack>

                  <LoadingButton
                    id="sign-up-button"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    {selectedPlan ? 'Update' : 'Save'}
                  </LoadingButton>
                </Stack>
              </Form>
            </FormikProvider>
          </Container>
        </div>
      </Dialog>

      <Toaster ref={toastRef} variant="error" />
    </div>
  );
}
