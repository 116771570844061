import sumBy from 'lodash/sumBy';
import { useState, useEffect, useRef} from 'react';
import { Link as RouterLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Stack,
  Switch,
  Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  // CircularProgress,
  TableContainer,
  TablePagination,
  FormControlLabel,
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useTabs from '../../hooks/useTabs';
import useSettings from '../../hooks/useSettings';
import useTable, { getComparator, emptyRows } from '../../hooks/useTable';
// _mock_
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { TableNoData, TableEmptyRows, TableHeadCustom, TableSelectedActions } from '../../components/table';
// sections
import InvoiceAnalytic from '../../sections/@dashboard/invoice/InvoiceAnalytic';
import { InvoiceTableRow, InvoiceTableToolbar } from '../../sections/@dashboard/invoice/list';
import { useInvoices } from '../../hooks/owner';
import { removeInvoice} from '../../redux/slices/owner/invoices';
import Toaster from '../../components/Toaster';
import ShopSelect from './ShopSelect';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'invoiceNumber', label: 'Client', align: 'left' },
  { id: 'createDate', label: 'Create', align: 'left' },
  { id: 'dueDate', label: 'Due', align: 'left' },
  { id: 'price', label: 'Amount', align: 'right', width: 140 },
  { id: 'pay', label: 'Pay', align: 'center', width: 140 },
  { id: 'status', label: 'Status', align: 'left' },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function InvoiceList() {
  const {state} = useLocation();
  // console.log(state);
  const customerId = state === null ? null:state.id;
  const updated = state === null? null:state.updated;
  const added = state === null? null:state.added;
  // console.log(customerId);
  const theme = useTheme();

  const dispatch = useDispatch();

  const { themeStretch } = useSettings();

  const navigate = useNavigate();
  // const params = useParams()
  const toastRef = useRef();

  const currentUser = useSelector((state) => state.firebase.profile);

  const [selectedShopName, setShop] = useState('All');

  const [del, setDelete] = useState(false);

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: 'createDate' });

  const [filterName, setFilterName] = useState('');
  // const [loader, setLoader] = useState(false)
  const [pay, setPay] = useState(false);
  const invoiceData = useInvoices({
    startAfter: undefined,
    limit: 1000,
    filterName: ''
  });

  const [_tableData, setTableData] = useState([]);

  const [filterService, setFilterService] = useState('all');

  const [filterStartDate, setFilterStartDate] = useState(null);

  const [filterEndDate, setFilterEndDate] = useState(null);

  const { currentTab: filterStatus, onChangeTab: onFilterStatus } = useTabs('all');

  useEffect(() => {
    if((invoiceData.data.length > _tableData.length) ) {
      if(del !== true && invoiceData.data.length === _tableData.length + 1) {
        toastRef.current.handleOpen('Invoice added successfully');
        // added = false;
      }
      if (del !== true) setTableData(invoiceData.data);
      // setLoader(true)
    }
    else if (pay === true || (invoiceData.data.length > 0
      && invoiceData.data.length === _tableData.length && invoiceData.data[0] !== _tableData[0]))  {

      if (pay !== true)
      {
        let flag = false;
        _tableData.forEach((item, index)=> {
          if (item.invoiceData.createdAt!==null && invoiceData.data[index].invoiceData.createdAt!==null && item.invoiceData.createdAt?.seconds !== invoiceData.data[index].invoiceData.createdAt?.seconds) flag = true;
        });
        if (flag===true) {toastRef.current.handleOpen('Invoice updated successfully');}
      }
      else toastRef.current.handleOpen('Invoice has been paid successfully');
      setPay(false);
      setTableData(invoiceData.data);
    }
  }, [pay, invoiceData.data]);


  let tableData = [];
  if (selectedShopName === 'All') tableData = _tableData;
  else {
    _tableData.forEach (invoiceItem => {
      const tempItems = [];
      let totalP = 0;
      invoiceItem.items.forEach(item=>{
        if (item.shopName === selectedShopName) {
          tempItems.push(item);
          totalP += item.total;
        }
      });
      if (tempItems.length > 0) {
        tableData.push ({
          ...invoiceItem,
          items: tempItems,
          totalPrice: totalP - invoiceItem.taxes - invoiceItem.discount,
        });
      }
    });
  }


  // const __tableData = tableData.filter (item=>
  //   item.invoiceFrom.phoneNumber === currentUser.phoneNumber &&
  //   item.invoiceFrom.address === currentUser.address &&
  //   (customerId !== null && customerId !== undefined ? item.invoiceTo.customerId === customerId: true)
  // );


  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleFilterService = (event) => {
    setFilterService(event.target.value);
  };

  const handleDeleteRow = async (id) => {
    const deleteRow = tableData.filter((row) => row.id !== id);
    setSelected([]);
    setDelete(true);
    setTableData(deleteRow);
    removeInvoice(id);
    await dispatch(removeInvoice(id));
  };

  const handleDeleteRows = (selected) => {
    const remainedRows = tableData.filter((row) => !selected.includes(row.id));
    setSelected([]);
    setDelete(true);
    setTableData(remainedRows);
    const deletedRows = tableData.filter((row) => selected.includes(row.id));
    deletedRows.forEach(deleteRow => {
      removeInvoice(deleteRow.id);
      dispatch(removeInvoice(deleteRow.id));
    });
  };

  const handleEditRow = (id) => {
    navigate(PATH_DASHBOARD.invoice.edit(id));
  };

  const handleViewRow = (id) => {
    // console.log("path: ", PATH_DASHBOARD.invoice.view(id))
    navigate(PATH_DASHBOARD.invoice.view(id));
  };

  // const dataFiltered = applySortFilter({
  //   tableData,
  //   comparator: getComparator(order, orderBy),
  //   filterStartDate,
  //   filterEndDate,
  //   filterName,
  //   filterService,
  //   filterStatus,
  // }).reverse().filter(item=>  (customerId !== null) ? (item.invoiceFrom.phoneNumber === currentUser.phoneNumber
  //       && item.invoiceFrom.companyName === currentUser.companyName
  //       && item.invoiceFrom.address === currentUser.address
  //       && item.invoiceFrom.firstName === currentUser.firstName
  //       && item.invoiceTo.customerId === customerId):
  //       (item.invoiceFrom.phoneNumber === currentUser.phoneNumber
  //         && item.invoiceFrom.companyName === currentUser.companyName
  //         && item.invoiceFrom.address === currentUser.address
  //         && item.invoiceFrom.firstName === currentUser.firstName)
  // );

  // const isNotFound =
  //   (!dataFiltered.length && !!filterName) ||
  //   (!dataFiltered.length && !!filterStatus) ||
  //   (!dataFiltered.length && !!filterService) ||
  //   (!dataFiltered.length && !!filterEndDate) ||
  //   (!dataFiltered.length && !!filterStartDate);

  const isNotFound = !invoiceData.data.length

  const denseHeight = dense ? 56 : 76;
  const getLengthByStatus = (status) => {
    if (status === 'overdue') {
      return invoiceData.data.filter((item) => item.dueDate?.seconds*1000 < new Date().getTime() && item.status !== 'paid').length
    }
    return invoiceData.data.filter((item) => (item.status === status) && (item.dueDate?.seconds*1000 >= new Date().getTime() || item.status === 'paid')).length;
  }
  const getTotalPriceByStatus = (status) => {
    if (status === 'overdue') {
      return (
        sumBy(
          invoiceData.data.filter((item) => item.dueDate?.seconds*1000 < new Date().getTime() && item.status !== 'paid'),
          'gTotalPrice'
        )
      );
    }
    return (
      sumBy(
        invoiceData.data.filter((item) => (item.status === status && item.dueDate?.seconds*1000 >= new Date().getTime() || item.status === 'paid')),
        'totalPrice'
      )
    );
  }

  const getPercentByStatus = (status) => (getLengthByStatus(status) / invoiceData.data.length) * 100;

  const TABS = [
    { value: 'all', label: 'All', color: 'info', count: invoiceData.data.length },
    { value: 'paid', label: 'Paid', color: 'success', count: getLengthByStatus('paid') },
    { value: 'partial', label: 'PartialPaid', color: 'primary', count: getLengthByStatus('partial') },
    { value: 'unpaid', label: 'Unpaid', color: 'warning', count: getLengthByStatus('unpaid') },
    { value: 'overdue', label: 'Overdue', color: 'error', count: getLengthByStatus('overdue') },
    { value: 'draft', label: 'Draft', color: 'default', count: getLengthByStatus('draft') },
  ];

  return (
    <Page title="Invoice: List">
      <Toaster ref={toastRef} />
      {/* {loader ?  */}
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Invoice List"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Invoices', href: PATH_DASHBOARD.invoice.root },
            { name: 'List' },
          ]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.invoice.new}
              startIcon={<Iconify icon={'eva:plus-fill'} />}
            >
              New Invoice
            </Button>
          }
        />

        <ShopSelect setShop={setShop}/>
        <Card sx={{ mb: 5 }}>
          <Scrollbar>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />}
              sx={{ py: 2 }}
            >
              <InvoiceAnalytic
                title="Total"
                total={invoiceData.data.length}
                percent={100}
                price={sumBy(invoiceData.data, 'totalPrice')}
                icon="ic:round-receipt"
                color={theme.palette.info.main}
              />
              <InvoiceAnalytic
                title="Paid"
                total={getLengthByStatus('paid')}
                percent={getPercentByStatus('paid')}
                price={getTotalPriceByStatus('paid')}
                icon="eva:checkmark-circle-2-fill"
                color={theme.palette.success.main}
              />
              <InvoiceAnalytic
                title="PartialPaid"
                total={getLengthByStatus('partial')}
                percent={getPercentByStatus('partial')}
                price={getTotalPriceByStatus('partial')}
                icon="eva:clock-fill"
                color={theme.palette.error.primary}
              />
              <InvoiceAnalytic
                title="Unpaid"
                total={getLengthByStatus('unpaid')}
                percent={getPercentByStatus('unpaid')}
                price={getTotalPriceByStatus('unpaid')}
                icon="eva:clock-fill"
                color={theme.palette.warning.main}
              />
              <InvoiceAnalytic
                title="Overdue"
                total={getLengthByStatus('overdue')}
                percent={getPercentByStatus('overdue')}
                price={getTotalPriceByStatus('overdue')}
                icon="eva:bell-fill"
                color={theme.palette.error.main}
              />
              <InvoiceAnalytic
                title="Draft"
                total={getLengthByStatus('draft')}
                percent={getPercentByStatus('draft')}
                price={getTotalPriceByStatus('draft')}
                icon="eva:file-fill"
                color={theme.palette.text.secondary}
              />
            </Stack>
          </Scrollbar>
        </Card>

        <Card>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterStatus}
            onChange={onFilterStatus}
            sx={{ px: 2, bgcolor: 'background.neutral' }}
          >
            {TABS.map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                value={tab.value}
                icon={<Label color={tab.color}> {tab.count} </Label>}
                label={tab.label}
              />
            ))}
          </Tabs>

          <Divider />

          <InvoiceTableToolbar
            filterName={filterName}
            filterService={filterService}
            filterStartDate={filterStartDate}
            filterEndDate={filterEndDate}
            onFilterName={handleFilterName}
            onFilterService={handleFilterService}
            onFilterStartDate={(newValue) => {
              setFilterStartDate(newValue);
            }}
            onFilterEndDate={(newValue) => {
              setFilterEndDate(newValue);
            }}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                  actions={
                    <Stack spacing={1} direction="row">
                      <Tooltip title="Sent">
                        <IconButton color="primary">
                          <Iconify icon={'ic:round-send'} />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Download">
                        <IconButton color="primary">
                          <Iconify icon={'eva:download-outline'} />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Print">
                        <IconButton color="primary">
                          <Iconify icon={'eva:printer-fill'} />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Delete">
                        <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                          <Iconify icon={'eva:trash-2-outline'} />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                  {invoiceData.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <InvoiceTableRow
                      key={row.id}
                      row={row}
                      selected={selected.includes(row.id)}
                      onSelectRow={() => onSelectRow(row.id)}
                      onViewRow={() => handleViewRow(row.id)}
                      onEditRow={() => handleEditRow(row.id)}
                      onDeleteRow={() => handleDeleteRow(row.id)}
                      setPay = {setPay}
                    />
                  ))}

                  <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={invoiceData.data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
      {/* :
      <div style={{display: 'flex', justifyContent: 'center' }}>
      <CircularProgress />
      </div>
      } */}
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({
  tableData,
  comparator,
  filterName,
  filterStatus,
  filterService,
  filterStartDate,
  filterEndDate,
}) {
  const stabilizedThis = tableData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  // console.log("unfiltered tableData=========>", tableData);
  // tableData = tableData.filter((item)=> (item.category !== 'Product' || item.invOption === 'Invoice'))

  // console.log("filtered tableData=========>", tableData);

  if (filterName) {
    tableData = tableData.filter(
      (item) =>
        item.id.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        item.invoiceTo.customerData.firstName.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  if (filterStatus !== 'all') {
    if (filterStatus === 'overdue') {
      tableData = tableData.filter((item) => item.dueDate?.seconds*1000 < new Date().getTime() && item.status !== 'paid');
    }
    else tableData = tableData.filter((item) => item.status === filterStatus && (item.dueDate?.seconds*1000 >= new Date().getTime() || item.status === 'paid'));
  }

  if (filterService !== 'all') {
    tableData = tableData.filter((item) => item.items.some((c) => c.service === filterService));
  }

  if (filterStartDate && filterEndDate) {
    tableData = tableData.filter(
      (item) =>
        (item.createDate?.seconds >= filterStartDate.getTime()/1000) && (item.dueDate?.seconds <= filterEndDate.getTime()/1000)
    );
  }

  return tableData;
}
