import { useState, forwardRef, useEffect } from 'react';
import { /* alpha, */ styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Slide, Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, FormLabel, FormControlLabel, Checkbox, FormGroup, Grid, Stack } from '@mui/material';
import DateRangePicker from 'react-bootstrap-daterangepicker';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

// ----------------------------------------------------------------------

const ChildrenButtonStyle = styled('div')(() => ({
  width: '100%',
  padding: 0
}));

const DateInputStyle = styled('input')(() => ({
  padding: '.6rem .5rem',
  borderRadius: '5px',
  border: '1px solid',
  outline: 'none',
  '&:disabled': {
    color: '#b4bec8',
    backgroundColor: '#fff'
  }
}));

const INITIAL_STATE = {
  status: [],
  paymentStatus: [],
  dateRange: null
}

const SewingFilterDialog = ({ children, isFilterOpen, setIsFilterOpen, applyFilter, filters }) => {
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState(INITIAL_STATE);
  const [isDateRangeActive, setIsDateRangeActive] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (setIsFilterOpen) setIsFilterOpen(false);
  };

  const handleChange = (event) => {
    const targetName = event.target.name
    const targetValue = event.target.value
    setFilter((currState) => ({
      [targetName]: !filter[targetName].includes(targetValue) ?
        [...currState[targetName], targetValue] : currState[targetName].filter((i) => i !== targetValue )
    }))
  }

  const handleApply = () => {
    const sentFilter = {
      ...filter,
      dateRange: isDateRangeActive ? filter.dateRange : null
    }
    applyFilter(sentFilter)
    handleClose()
  }

  const handleDatePick = (e, picker) => {
    setFilter((currFil) => ({
      ...currFil,
      dateRange: {
        startDate: picker.startDate._d,
        endDate: picker.endDate._d
      }
    }))
  }

  // -----------------------------------------------------------------------------------------------

  useEffect(() => {
    if(isFilterOpen) setOpen(true)
    else handleClose()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilterOpen])

  useEffect(() => {
    if(!filters.length) setFilter(INITIAL_STATE)
  }, [filters])

  return (
    <div>
      <ChildrenButtonStyle onClick={handleClickOpen}>{children}</ChildrenButtonStyle>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        scroll="paper"
        TransitionComponent={Transition}
      >
        <DialogTitle>Filters</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormControl component="fieldset" sx={{ mb: 3 }}>
                <FormLabel component="legend">Payment Status</FormLabel>
                <FormGroup aria-label="position" column="true">
                  <FormControlLabel
                    value="top"
                    control={<Checkbox />}
                    label="Fully Paid"
                  />
                  <FormControlLabel
                    value="top"
                    control={<Checkbox />}
                    label="Partially Paid"
                  />
                </FormGroup>
              </FormControl>

              <FormControl component="fieldset">
                <FormLabel component="legend" sx={{ mb: 1 }}>Date Range</FormLabel>
                <FormGroup aria-label="position" column="true">
                  <Stack direction="row" alignItems="center">
                    <FormControlLabel
                      control={<Checkbox onChange={() => setIsDateRangeActive(!isDateRangeActive)} />}
                      label={
                        <DateRangePicker onApply={handleDatePick} initialSettings={{
                            showDropdowns: true,
                            autoApply: true,
                            startDate: filter.dateRange?.startDate || new Date(),
                            endDate: filter.dateRange?.endDate || new Date()
                          }}
                        >
                          <DateInputStyle disabled={!isDateRangeActive} />
                        </DateRangePicker>
                      }
                      checked={isDateRangeActive}
                    />
                  </Stack>
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Status</FormLabel>
                <FormGroup aria-label="position" column="true">
                  <FormControlLabel
                    value="ordered"
                    control={<Checkbox />}
                    checked={filter.status.includes("ordered")}
                    label="Ordered"
                    onChange={handleChange}
                    name="status"
                  />
                  <FormControlLabel
                    value="cutting"
                    control={<Checkbox />}
                    label="Cutting"
                    checked={filter.status.includes("cutting")}
                    onChange={handleChange}
                    name="status"
                  />
                  <FormControlLabel
                    value="sewing"
                    control={<Checkbox />}
                    label="Sewing"
                    checked={filter.status.includes("sewing")}
                    onChange={handleChange}
                    name="status"
                  />
                  <FormControlLabel
                    value="completed"
                    control={<Checkbox />}
                    label="Completed"
                    checked={filter.status.includes("completed")}
                    onChange={handleChange}
                    name="status"
                  />
                  <FormControlLabel
                    value="delivered"
                    control={<Checkbox />}
                    label="Delivered"
                    checked={filter.status.includes("delivered")}
                    onChange={handleChange}
                    name="status"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleApply} variant="contained">Apply Filter</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}


SewingFilterDialog.propTypes = {
  children: PropTypes.node,
  isFilterOpen: PropTypes.bool,
  setIsFilterOpen: PropTypes.func,
  applyFilter: PropTypes.func,
  filters: PropTypes.array
}

export default SewingFilterDialog