import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { sentenceCase } from 'change-case';
import { isEmpty } from 'react-redux-firebase';
import eyeOutlined from '@iconify/icons-ant-design/eye-outlined';
import {
  Container,
  Stack,
  Typography,
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  CircularProgress,
  Button,
  Box,
  TablePagination
} from '@mui/material';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
// components
import Label from '../../../components/Label';
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
//
import {
  ListHead,
  ListToolbar,
  DeleteDialog,
  StatusDialog
} from '../../../components/_dashboard/super_admin/@general';
//
import { useSubscriptionsPlans } from '../../../hooks/super_admin';
//
import { AddSubscriptionsPlansDialog } from '../../../components/_adds/super_admin';
//
import { removePlan, updatePlan } from '../../../redux/slices/super_admin/subscriptions';
import { SubscriptionsPlanViewDialog } from '../../../components/_dashboard/super_admin/subscriptions';

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'price', label: 'Price', alignRight: false },
  { id: '' }
];

export default function SubscriptionsPlans() {
  const { searchedSubscriptionPlans } = useSelector((state) => state.firestore.ordered);
  const { requesting } = useSelector((state) => state.firestore.status);
  const { subscriptionsPlansCount } = useSelector((state) => state.firebase.profile);

  // -----------------------------------------------------------------

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [cursor, setCursor] = useState(undefined);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isPlanEditOpen, setIsPlanEditOpen] = useState(false);
  const [isPlanStatusOpen, setIsPlanStatusOpen] = useState(false);
  const [planOpen, setPlanOpen] = useState(false);
  const [isPlanDeleteOpen, setIsPlanDeleteOpen] = useState(false);
  const subscriptionsPlans = useSubscriptionsPlans({
    startAfter: cursor,
    limit: rowsPerPage,
    filterName
  });

  // -----------------------------------------------------------------

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setCursor(subscriptionsPlans.data[subscriptionsPlans.data.length - 1].createdAt);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeletePlan = (planItem) => {
    setSelectedPlan(planItem);
    setIsPlanDeleteOpen(true);
  };

  const handleEditPlan = (e, planItem) => {
    e.stopPropagation();
    setSelectedPlan(planItem);
    setIsPlanEditOpen(true);
  };

  const handleChangeStatus = (planItem) => {
    setSelectedPlan(planItem);
    setIsPlanStatusOpen(true);
  };

  const handleViewPlan = (planItem) => {
    setSelectedPlan(planItem);
    setPlanOpen(true);
  };

  // -----------------------------------------------------------------

  useEffect(() => {
    if (!isPlanEditOpen && !isPlanDeleteOpen && !isPlanStatusOpen && !planOpen) {
      setSelectedPlan(null);
    }
  }, [isPlanEditOpen, isPlanDeleteOpen, isPlanStatusOpen, planOpen]);

  useEffect(() => {
    if (requesting.searchedSubscriptionPlans) {
      if (page) setPage(0);
      else setPage(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requesting.searchedSubscriptionPlans]);

  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * rowsPerPage - filterName
            ? searchedSubscriptionPlans?.length
            : subscriptionsPlans.data?.length
        )
      : 0;

  return (
    <Page title="Super Admin: Subscription Plans | FashionsPal">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Subscription Plans
          </Typography>
          <AddSubscriptionsPlansDialog
            isPlanOpen={isPlanEditOpen}
            setIsPlanOpen={setIsPlanEditOpen}
            selectedPlan={selectedPlan}
          >
            <Button variant="contained" component="div" startIcon={<Icon icon={plusFill} />}>
              Add Plan
            </Button>
          </AddSubscriptionsPlansDialog>
        </Stack>

        <Card>
          {!!subscriptionsPlans.data.length && (
            <ListToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
              requesting={requesting.searchedSubscriptionPlans}
            />
          )}
          {subscriptionsPlans.loading || requesting.searchedSubscriptionPlans ? (
            <Box
              sx={{
                height: '60vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgress size={55} />
            </Box>
          ) : (
            <>
              {!subscriptionsPlans.data.length ? (
                'Is empty'
              ) : (
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <ListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={
                          filterName
                            ? searchedSubscriptionPlans?.length || 0
                            : subscriptionsPlansCount || 0
                        }
                        onRequestSort={handleRequestSort}
                      />
                      <TableBody>
                        {(filterName
                          ? searchedSubscriptionPlans || subscriptionsPlans.data
                          : subscriptionsPlans.data
                        )
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            const { name, isActive, price } = row;

                            return (
                              <TableRow key={row.id} tabIndex={-1}>
                                <TableCell>
                                  <Typography variant="subtitle2" noWrap>
                                    {rowsPerPage * (page + 1) + (index + 1) - rowsPerPage}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">{name}</TableCell>
                                <TableCell align="left">
                                  <Label
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => handleChangeStatus(row)}
                                    variant="ghost"
                                    color={(!isActive && 'error') || 'success'}
                                  >
                                    {isActive ? sentenceCase('active') : sentenceCase('inactive')}
                                  </Label>
                                </TableCell>
                                <TableCell align="left">{`₵${price}`}</TableCell>
                                <TableCell align="right">
                                  <Button
                                    variant="contained"
                                    component="div"
                                    onClick={() => handleViewPlan(row)}
                                    startIcon={<Icon icon={eyeOutlined} />}
                                    sx={{ mr: 1.5 }}
                                  >
                                    View
                                  </Button>
                                  <Button
                                    variant="contained"
                                    component="div"
                                    onClick={(e) => handleEditPlan(e, row)}
                                    sx={{ mr: 1.5 }}
                                  >
                                    Edit
                                  </Button>
                                  <Button
                                    variant="contained"
                                    component="div"
                                    color="error"
                                    onClick={() => handleDeletePlan(row)}
                                  >
                                    Delete
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      {isEmpty(searchedSubscriptionPlans) && filterName ? (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : null}
                    </Table>
                  </TableContainer>
                </Scrollbar>
              )}
            </>
          )}

          {!requesting.searchedSubscriptionPlans && !!subscriptionsPlans.data.length && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={
                filterName ? searchedSubscriptionPlans?.length || 0 : subscriptionsPlansCount || 0
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>

        {planOpen && (
          <SubscriptionsPlanViewDialog
            isOpen={planOpen}
            setIsOpen={setPlanOpen}
            plan={selectedPlan}
          />
        )}
        {isPlanDeleteOpen && (
          <DeleteDialog
            isOpen={isPlanDeleteOpen}
            setIsOpen={setIsPlanDeleteOpen}
            selectedItem={selectedPlan}
            delFunc={removePlan}
          />
        )}
        {isPlanStatusOpen && (
          <StatusDialog
            isOpen={isPlanStatusOpen}
            setIsOpen={setIsPlanStatusOpen}
            selectedItem={selectedPlan}
            statusFunc={updatePlan}
          />
        )}
      </Container>
    </Page>
  );
}
