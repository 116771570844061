// @mui
import { useEffect, useState } from 'react';
import { Grid, Card, CardHeader, TextField} from '@mui/material';
import {
    EcommerceBestSalesman,
} from '../../../../sections/@dashboard/general/e-commerce';

import {
    AnalyticsConversionRates,
} from '../../../../sections/@dashboard/general/analytics';
  
import Page from '../../../Page';
import { useCustomers, useInvoices } from '../../../../hooks/owner';
// ----------------------------------------------------------------------

const title="Most Purchasing Customers";
const subheader="";
const limitData = [5, 10, 25, 50, 100];

export default function PurchasingCustomerStatistics() {

  const [limit, setLimit] = useState(5);
  
  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
  } 
  
  const customerData = useCustomers ({
    startAfter: undefined,
    limit: 20,
    filterName: '',
  });
  const invoiceData = useInvoices ({
    startAfter: undefined,
    limit: 20,
    filterName: '',
  });

  const allCustomers = [];

  customerData.data.forEach ((item)=>{
    let totalCount = 0;
    let totalAmount = 0;
    invoiceData.data.forEach(invoiceItem=>{
      if (item.customerId === invoiceItem.invoiceTo.customerId) {
        totalAmount += invoiceItem.gTotalPrice;
        const total = invoiceItem.items.reduce((prev, next) => prev + next.quantity, 0);
        totalCount += total;
      }
    });
    allCustomers.push({
      customerData: item.customerData,
      id: item.customerId,
      count: totalCount,
      amount: totalAmount,
    });
  });

  const topCustomers = allCustomers.sort((a, b)=> a.amount < b.amount ? 1: -1);
  const chartDataAmount = [];
  const chartDataCount = [];

  topCustomers.forEach(item=> {
    chartDataAmount.push(
      {
        label: item.customerData.firstName,
        value: item.amount,
      }
    );
    chartDataCount.push(
      {
        label: item.customerData.firstName,
        value: item.count,
      }
    );
  });

  return topCustomers.length>0?(
    <Page>
      <Card container spacing={3}>

      <CardHeader
        title={title}
        subheader={subheader}
        action={
          <TextField
            select
            fullWidth
            value={limit}
            SelectProps={{ native: true }}
            onChange={handleChangeLimit}
            sx={{
              '& fieldset': { border: '0 !important' },
              '& select': { pl: 1, py: 0.5, pr: '24px !important', typography: 'subtitle2' },
              '& .MuiOutlinedInput-root': { borderRadius: 0.75, bgcolor: 'background.neutral' },
              '& .MuiNativeSelect-icon': { top: 4, right: 0, width: 20, height: 20 },
            }}
          >
            {limitData.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </TextField>
        }
      />


      <Grid container spacing={3} sx={{marginBottom: 5}}>
          <Grid item xs={12} md={6} lg={6}>
            <EcommerceBestSalesman
              title=""
              tableData={topCustomers.slice(0, limit)}
              tableLabels={[
                { id: 'customer', label: 'Customer' },
                { id: 'count', label: 'Purchased Count' },
                { id: 'amount', label: 'Total Amount' },
                { id: 'rank', label: 'Rank', align: 'right' },
                { id: 'detail', label: 'Link', align: 'center' },
              ]}
            />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
            <AnalyticsConversionRates
              // title="Most Purchased Customers"
              // subheader=""
              chartDataAmount={chartDataAmount.slice(0, limit)}
              chartDataCount={chartDataCount.slice(0, limit)}
            />
          </Grid>
      </Grid>
    </Card>
    </Page>    

  ):null;
}
