// material
import { Box, Grid, Container, Typography } from '@mui/material';
// components
import Page from '../../components/Page';
import {
  AppCustomersTotal,
  AppSewingsTotal,
  AppIncomeTotal,
  AppExpenditureTotal,
  AppAddCustomer,
  AppAddSewing,
  AppAddStaff
} from '../../components/_dashboard/owner/app';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  return (
    <Page title="Dashboard | FashionPal">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome back</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppCustomersTotal />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppSewingsTotal />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppIncomeTotal />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppExpenditureTotal />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppAddCustomer />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppAddSewing />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppAddStaff />
          </Grid>
          {/*
          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
