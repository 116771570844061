import * as React from 'react';
import * as Yup from 'yup';
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Container,
  Grid,
  Box,
  TextField,
  Autocomplete
  // CircularProgress
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import sendOutlined from '@iconify/icons-ant-design/send-outlined';
import closeFill from '@iconify/icons-eva/close-fill';
import { useFormik, Form, FormikProvider } from 'formik';

import CUSTOMERS from '../../../_mocks_/customers';

// ----------------------------------------------------------------------

SendSMSDialog.propTypes = {
  children: PropTypes.node
};

// ----------------------------------------------------------------------

const ChildrenButtonStyle = styled(Button)(() => ({
  width: '100%',
  padding: 0
}));

const SaveButtonStyle = styled(Button)(({ theme }) => ({
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.lighter, theme.palette.action.hoverOpacity)
  }
}));

// ----------------------------------------------------------------------

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function SendSMSDialog({ children }) {
  const [open, setOpen] = React.useState(false);
  const [initialValues] = React.useState({
    customer: [],
    subject: '',
    message: ''
  });
  const [options, setOptions] = React.useState([]);
  const [customersValue, setCustomersValue] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');
  // const [loading, setLoading] = React.useState(false);

  // ----------------------------------------------------------------------

  const CustomerSchema = Yup.object().shape({
    customers: Yup.array()
      .min(1, 'Please select at least one customer')
      .required('Please select at least one customer'),
    message: Yup.string().required('Please enter a message')
  });

  const formik = useFormik({
    initialValues,
    // enableReinitialize: true,
    validationSchema: CustomerSchema,
    onSubmit: (values, { resetForm }) => {
      console.log(values);

      // reset form
      resetForm();
      setCustomersValue([]);

      // clsoe dialog
      setOpen(false);
    }
  });

  // ----------------------------------------------------------------------

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // ----------------------------------------------------------------------

  // real time search
  const fetch = React.useMemo(
    () =>
      throttle((request /* , callback */) => {
        // autocompleteService.current.getPlacePredictions(request, callback);
        // console.log(request);
        setOptions(
          CUSTOMERS.filter(
            (customer) =>
              customer.firstName.toLowerCase().includes(request.input.toLowerCase()) ||
              customer.lastName.toLowerCase().includes(request.input.toLowerCase())
          )
        );
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(customersValue || []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (customersValue.length) {
          newOptions = [customersValue];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [customersValue, inputValue, fetch]);

  // ----------------------------------------------------------------------

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <ChildrenButtonStyle onClick={handleClickOpen}>{children}</ChildrenButtonStyle>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <Icon icon={closeFill} width={24} height={24} />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Send Email
            </Typography>
            <SaveButtonStyle
              autoFocus
              variant="contained"
              onClick={handleSubmit}
              startIcon={<Icon icon={sendOutlined} />}
            >
              Send
            </SaveButtonStyle>
          </Toolbar>
        </AppBar>
        <Container maxWidth="sm" sx={{ pb: 5 }}>
          <Box sx={{ pb: 3, pt: 4 }}>
            <Typography variant="h4">Send New Email</Typography>
          </Box>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    getOptionLabel={(option) =>
                      option.firstName ? `${option.firstName} ${option.lastName}` : ''
                    }
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    filterOptions={(x) => x}
                    options={[...customersValue, ...options]}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    value={customersValue}
                    onChange={(event, newValue) => {
                      setOptions(newValue ? [...newValue, ...options] : options);
                      setCustomersValue(newValue);
                      formik.setFieldValue('customers', newValue);
                    }}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    // loading={loading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Customer(s)"
                        error={Boolean(errors.customers)}
                        helperText={errors.customers}
                        // InputProps={{
                        //   ...params.InputProps,
                        //   endAdornment: (
                        //     <>
                        //       {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        //       {params.InputProps.endAdornment}
                        //     </>
                        //   )
                        // }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth type="text" label="Subject" {...getFieldProps('subject')} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Message"
                    multiline
                    rows={6}
                    {...getFieldProps('message')}
                    error={Boolean(touched.message && errors.message)}
                    helperText={touched.message && errors.message}
                  />
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Container>
      </Dialog>
    </div>
  );
}
