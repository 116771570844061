import * as React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
// import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { format } from 'date-fns';
import { fDate } from '../../../../utils/formatTime';

// --------------------------------------------------

CustomersViewDialog.propTypes = {
  isOpen: PropTypes.bool,
  customer: PropTypes.object,
  setIsOpen: PropTypes.func,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func
};

// --------------------------------------------------

export default function CustomersViewDialog({
  customer,
  isOpen,
  setIsOpen,
  handleEdit,
  handleDelete
}) {
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = React.useCallback(() => {
    setOpen(false);
    setIsOpen(false);
  }, [setIsOpen]);

  const handleDeleteClick = () => {
    handleClose();
    handleDelete();
  };

  const handleEditClick = () => {
    handleClose();
    handleEdit();
  };

  const handleViewSewingsClick = ({ customerId }) => {
    navigate(`/dashboard/sewings/${customerId}`);
  }

  // --------------------------------------------------

  React.useEffect(() => {
    if (isOpen) handleOpen();
    else handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClose, isOpen]);

  // --------------------------------------------------

  const {
    firstName,
    lastName,
    phoneNumber,
    email,
    sex,
    country,
    city,
    address,
    birthday,
    createdAt
  } = customer.customerData;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          Customer
          <IconButton
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Icon icon={closeFill} width={24} height={24} />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={12}>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Customer Name
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {`${firstName || ''} ${lastName || ''}`}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Phone Number
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {phoneNumber || 'N/A'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Email
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {email || 'N/A'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Sex
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {sex || 'N/A'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Date of Birth
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {birthday ? fDate(birthday.toDate()) : 'N/A'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Country
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  style={{ marginBottom: '20px', textTransform: 'capitalize' }}
                >
                  {country || 'N/A'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <Typography variant="subtitle1" component="p">
                  City
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {city || 'N/A'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Address
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {address || 'N/A'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Created on
                </Typography>
                <Typography variant="body2">
                  {createdAt ? format(createdAt.toDate(), 'dd MMM yyyy') : 'N/A'}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {createdAt ? format(createdAt.toDate(), 'p') : 'N/A'}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleViewSewingsClick(customer)}>
            View Sewings
          </Button>
          <Button onClick={handleDeleteClick} color="error">
            Delete
          </Button>
          <Button onClick={handleEditClick} autoFocus>
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
