import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// material
import { useTheme } from '@mui/material/styles';
import { Box, Grid, Container, Typography } from '@mui/material';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import {
  AppWidgetSummary,
  AppYearlySummary
  // AppActivities
} from '../../components/_dashboard/super_admin/app';

// ----------------------------------------------------------------------

const categories = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export default function DashboardApp() {
  const { metrics } = useSelector((state) => state.firestore.ordered);

  const theme = useTheme();
  const { themeStretch } = useSettings();
  const { ownersCount, customersCount, sewingsCount } = useSelector(
    (state) => state.firebase.profile
  );
  const [chartData, setChartData] = useState([]);

  // ----------------------------------------------------------------------

  useEffect(() => {
    const data = [
      { name: 'Owners', data: [] },
      { name: 'Customers', data: [] },
      { name: 'Sewings', data: [] }
    ];

    categories.forEach((category) => {
      if (metrics && metrics.length > 0) {
        const currMetric = metrics[0].data[category];
        if (currMetric) {
          data[0].data.push(currMetric.ownersCount);
          data[1].data.push(currMetric.customersCount);
          data[2].data.push(currMetric.sewingsCount);
        } else {
          data[0].data.push(null);
          data[1].data.push(null);
          data[2].data.push(null);
        }
      } else {
        data[0].data.push(null);
        data[1].data.push(null);
        data[2].data.push(null);
      }
    });

    setChartData(data);
  }, [metrics]);

  return (
    <Page title="Super admin: Dashboard | FashionsPal">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome back</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <AppWidgetSummary
              title="Total Owners"
              percent={2.6}
              total={ownersCount}
              chartColor={theme.palette.primary.main}
              chartData={chartData[0]?.data || []}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <AppWidgetSummary
              title="Total Customers"
              percent={0.2}
              total={customersCount}
              chartColor={theme.palette.chart.green[0]}
              chartData={chartData[1]?.data || []}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <AppWidgetSummary
              title="Total Sewings"
              percent={-0.1}
              total={sewingsCount}
              chartColor={theme.palette.chart.red[0]}
              chartData={chartData[2]?.data || []}
            />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppActivities />
          </Grid> */}

          <Grid item xs={12} /* md={6} lg={8} */>
            <AppYearlySummary />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
