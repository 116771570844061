import { Navigate } from 'react-router-dom';
import SuperAdminDashboard from '../../pages/super_admin/Dashboard';
import SuperAdminOwners from '../../pages/super_admin/Owners';
import SuperAdminCustomer from '../../pages/super_admin/Customers';
import SuperAdminSewings from '../../pages/super_admin/Sewings';
import SuperAdminCommunications from '../../pages/super_admin/Communications';
import {
  MeasurementsParent as SuperAdminMeasurementsParent,
  MeasurementsList as SuperAdminMeasurementsList
} from '../../pages/super_admin/measurements';
import { Expenditure as SuperAdminExpenditure } from '../../pages/super_admin/finances';
import {
  SubscriptionsPlans as SuperAdminSubscriptionsPlans,
  SubscriptionsIndividual as SuperAdminSubscriptionsIndividual,
  SubscriptionsCategories as SuperAdminSubscriptionsCategories
} from '../../pages/super_admin/subscriptions';

const ownerRoutes = [
  { element: <Navigate to="/dashboard/app" replace /> },
  { path: 'app', element: <SuperAdminDashboard /> },
  { path: 'owners', element: <SuperAdminOwners /> },
  { path: 'customers', element: <SuperAdminCustomer /> },
  { path: 'sewings', element: <SuperAdminSewings /> },
  { path: 'communications', element: <SuperAdminCommunications /> },
  { path: 'measurements/parent', element: <SuperAdminMeasurementsParent /> },
  { path: 'measurements/list', element: <SuperAdminMeasurementsList /> },
  { path: 'finances/expenditure', element: <SuperAdminExpenditure /> },
  { path: 'subscriptions/categories', element: <SuperAdminSubscriptionsCategories /> },
  { path: 'subscriptions/plans', element: <SuperAdminSubscriptionsPlans /> },
  { path: 'subscriptions/individual', element: <SuperAdminSubscriptionsIndividual /> }
];

export default ownerRoutes;
