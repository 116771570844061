import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const sendSMS = createAsyncThunk(
  'communication/sendSMS',
  async ({ destination, message, senderId }, { extra: { getFirebase } }) => {
    const firebase = getFirebase();
    const sendSMS = firebase.functions().httpsCallable('sendSMS');
    await sendSMS({ destination, message, senderId });
  }
);

const initialState = {
  error: null
};

const communicationSlice = createSlice({
  name: 'communication',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendSMS.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });
  }
});

export default communicationSlice.reducer;
