import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fPhoneNumberToEmail } from '../../../utils/formatPhoneNumber';
import { firebaseConfig } from '../../../config/firebase';

export const addOwner = createAsyncThunk(
  'owners/add',
  async (ownerData, { extra: { getFirestore, getFirebase } }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    const {
      phoneNumber,
      password,
      firstName,
      lastName,
      companyName,
      address,
      profileImage,
      searchMatch,
      senderId,
      role,
      isEnabled
    } = ownerData;
    const timestamp = firestore.FieldValue.serverTimestamp();

    const formattedPhoneNumberToEmail = fPhoneNumberToEmail(phoneNumber);

    const secondaryApp = firebase.initializeApp(firebaseConfig, 'Secondary');

    const ownerAuth = await secondaryApp
      .auth()
      .createUserWithEmailAndPassword(formattedPhoneNumberToEmail, password);

    const ownerAuthUid = ownerAuth.user.uid;

    await secondaryApp.auth().signOut();

    const addAdminRole = firebase.functions().httpsCallable('addAdminRole');
    await addAdminRole({ email: formattedPhoneNumberToEmail });

    firestore.set(
      { collection: 'users', doc: ownerAuthUid },
      {
        firstName,
        lastName,
        phoneNumber,
        companyName,
        address,
        profileImage,
        searchMatch,
        customersCount: 0,
        sewingsCount: 0,
        totalIncome: 0,
        totalExpenditure: 0,
        isEnabled,
        senderId,
        role,
        createdAt: timestamp,
        updatedAt: timestamp
      }
    );
  }
);

export const changeOwnerStatus = createAsyncThunk(
  'owners/changeStatus',
  async (ownerData, { extra: { getFirestore, getFirebase } }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    const { phoneNumber, isEnabled } = ownerData;
    const formattedPhoneNumber = fPhoneNumberToEmail(phoneNumber);
    const timestamp = firestore.FieldValue.serverTimestamp();

    if (isEnabled) {
      const enableUser = firebase.functions().httpsCallable('enableUser');
      await enableUser({ email: formattedPhoneNumber });
    } else {
      const disableUser = firebase.functions().httpsCallable('disableUser');
      await disableUser({ email: formattedPhoneNumber });
    }

    firestore.update(
      { collection: 'users', doc: ownerData.id },
      {
        ...ownerData,
        updatedAt: timestamp
      }
    );
  }
);

export const updateOwner = createAsyncThunk(
  'owners/update',
  async (ownerData, { extra: { getFirestore, getFirebase } }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    const { phoneNumber, firstName, lastName, companyName, address, searchMatch, senderId } =
      ownerData;
    const timestamp = firestore.FieldValue.serverTimestamp();

    if (ownerData.oldPhoneNumber) {
      const updateUser = firebase.functions().httpsCallable('updateUser');
      await updateUser({
        oldEmail: fPhoneNumberToEmail(ownerData.oldPhoneNumber),
        newEmail: fPhoneNumberToEmail(phoneNumber)
      });
    }

    firestore.update(
      { collection: 'users', doc: ownerData.id },
      {
        firstName,
        lastName,
        phoneNumber,
        companyName,
        address,
        senderId,
        searchMatch,
        updatedAt: timestamp
      }
    );
  }
);

export const removeOwner = createAsyncThunk(
  'owners/remove',
  async (ownerData, { extra: { getFirestore, getFirebase } }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    const deleteUser = firebase.functions().httpsCallable('deleteUser');
    await deleteUser({ email: fPhoneNumberToEmail(ownerData.phoneNumber) });

    firestore.delete({ collection: 'users', doc: ownerData.id });
  }
);

export const resetOwnerPassword = createAsyncThunk(
  'owners/resetPassword',
  async (ownerData, { extra: { getFirebase } }) => {
    const firebase = getFirebase();
    const defaultPassword = '123456';

    const resetPassword = firebase.functions().httpsCallable('resetPassword');
    await resetPassword({
      email: fPhoneNumberToEmail(ownerData.phoneNumber),
      password: defaultPassword
    });
  }
);

const initialState = {
  error: null
};

const ownersSlice = createSlice({
  name: 'owners',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addOwner.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(addOwner.rejected, (state, action) => {
      state.error = action.error;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });

    // ----------------------------------------------------------------

    builder.addCase(changeOwnerStatus.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(changeOwnerStatus.rejected, (state, action) => {
      state.error = action.error;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });

    // ----------------------------------------------------------------

    builder.addCase(updateOwner.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(updateOwner.rejected, (state, action) => {
      state.error = action.error;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });

    // ----------------------------------------------------------------

    builder.addCase(removeOwner.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(removeOwner.rejected, (state, action) => {
      state.error = action.error;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });

    // ----------------------------------------------------------------

    builder.addCase(resetOwnerPassword.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(resetOwnerPassword.rejected, (state, action) => {
      state.error = action.error;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });
  }
});

export default ownersSlice.reducer;
