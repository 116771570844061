// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: 'AIzaSyAHe_rtYxCZBcDAKQS9uXQnbgm51FKEJlg',
  authDomain: 'fashionsgh-84af1.firebaseapp.com',
  databaseURL: 'https://fashionsgh-84af1-default-rtdb.firebaseio.com',
  projectId: 'fashionsgh-84af1',
  storageBucket: 'fashionsgh-84af1.appspot.com',
  messagingSenderId: '735887246149',
  appId: '1:735887246149:web:0140d914bbce67e05c742c',
  measurementId: 'G-PNG7XVSRFR'
};

// see all configurations in store.js in the redux folder
