import * as Yup from 'yup';
import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'react-redux-firebase';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import {
  Container,
  Stack,
  Typography,
  Card,
  Grid,
  TextField,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  CircularProgress,
  Box,
  Alert
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormik, Form, FormikProvider } from 'formik';
import {
  ShopListToolbar,
  ShopEmpty,
  ShopListHead,
  ShopViewDialog,
  ShopDeleteDialog,
} from '../../components/_dashboard/owner/shop';

import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';

import Toaster from '../../components/Toaster';
import { addShop } from '../../redux/slices/owner/shops';
import { useShops } from '../../hooks/owner';

const TABLE_HEAD = [
  { id: 'Shop Name', label: 'Shop Name', alignRight: false },
  { id: 'Location', label: 'Location', alignRight: false }
  // { id: '' }
];

export default function Shop() {
  const { requesting } = useSelector((state) => state.firestore.status);
  const { searchedShops } = useSelector((state) => state.firestore.ordered);
  const { ownersCount } = useSelector((state) => state.firebase.profile);

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('firstName');
  const [error, setError] = useState(null);
  const [initialValues] = useState({
    shopName: '',
    location: ''
  });
  const [cursor, setCursor] = useState(undefined);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterName, setFilterName] = useState('');

  const [selectedShop, setSelectedShop] = useState(null);
  const [shopOpen, setShopOpen] = useState(false);
  const [isShopDeleteOpen, setIsShopDeleteOpen] = useState(false);
  const currentUser = useSelector((state) => state.firebase.profile);
  const ownerId = useSelector((state) => state.firebase.auth).uid;


  const toastRef = useRef();
  const dispatch = useDispatch();

  const shops = useShops({
    startAfter: cursor,
    limit: rowsPerPage,
    filterName
  });
  // --------------------------------------------------

  const ShopSchema = Yup.object().shape({
    shopName: Yup.string().required('ShopName is required'),
    location: Yup.string().required('Location is required')
  });

  const formik = useFormik({
    initialValues,
    validationSchema: ShopSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      const shopInfo = {
        ...values,
        owner: currentUser.phoneNumber,
      };
      await dispatch(addShop(shopInfo));
      toastRef.current.handleOpen('Shop added successfully');
    }
  });

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setCursor(shops.data[shops.data.length - 1].createdAt);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleShopClick = (shop) => {
    setSelectedShop(shop);
    setShopOpen(true);
  };

  const handleDelete = (item) => {
    setIsShopDeleteOpen(true);
    setSelectedShop(item);
  };

  // --------------------------------------------------

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;
  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * rowsPerPage - filterName ? searchedShops?.length : searchedShops.data?.length
        )
      : 0;
  return (
    <Page title="Shop | FashionsPal">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Shops
          </Typography>
        </Stack>
        {error && (
          <Alert sx={{ mb: 2 }} severity="error">
            {error}
          </Alert>
        )}
        <Card sx={{ p: 2.5, mb: 5 }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={5}>
                  <TextField
                    fullWidth
                    label="Shop Name"
                    {...getFieldProps('shopName')}
                    error={Boolean(touched.shopName && errors.shopName)}
                    helperText={touched.shopName && errors.shopName}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    fullWidth
                    label="Location"
                    {...getFieldProps('location')}
                    error={Boolean(touched.location && errors.location)}
                    helperText={touched.location && errors.shopName}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <LoadingButton
                    sx={{ mt: 0.5 }}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    startIcon={<Icon icon={plusFill} />}
                    loading={isSubmitting}
                  >
                    Add
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Card>
        {!!shops.data.length && (
          <ShopListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            requesting={requesting.searchedShops}
          />
        )}
        {shops.loading || requesting.searchedShops ? (
          <Box
            sx={{
              height: '60vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <CircularProgress size={55} />
          </Box>
        ) : (
          <>
            {!shops.data.length ? (
              <ShopEmpty />
            ) : (
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <ShopListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={filterName ? searchedShops?.length || 0 : ownersCount || 0}
                      // onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {(filterName ? searchedShops || shops.data : shops.data)
                        .filter (item => item.ownerId === ownerId)
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          const { shopName, location } = row;

                          return (
                            <TableRow
                              hover
                              key={row.id}
                              sx={{ cursor: 'pointer' }}
                              tabIndex={-1}
                              onClick={() => handleShopClick(row)}
                            >
                              <TableCell>
                                <Typography variant="subtitle2" noWrap>
                                  {rowsPerPage * (page + 1) + (index + 1) - rowsPerPage}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">{shopName}</TableCell>
                              <TableCell align="left">{location}</TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isEmpty(searchedShops) && filterName ? (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : null}
                  </Table>
                </TableContainer>
              </Scrollbar>
            )}
          </>
        )}

        {!requesting.searchedShops && !!shops.data.length && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filterName ? searchedShops?.length || 0 : ownersCount || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}

        {/* ----- Dialogs start ----- */}
        {shopOpen && (
          <ShopViewDialog
            isOpen={shopOpen}
            setIsOpen={setShopOpen}
            shop={selectedShop}
            handleDelete={() => handleDelete(selectedShop)}
          />
        )}
        {isShopDeleteOpen && (
          <ShopDeleteDialog
            isOpen={isShopDeleteOpen}
            setIsOpen={setIsShopDeleteOpen}
            selectedShop={selectedShop}
          />
        )}


      </Container>
      <Toaster ref={toastRef} />
    </Page>
  );
}
