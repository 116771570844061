import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Card, CardHeader, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
import {
  EcommerceSaleByGender,
  EcommerceLatestProducts,
} from '../../../../sections/@dashboard/general/e-commerce';

import {
  AnalyticsTopProducts,
} from '../../../../sections/@dashboard/general/analytics';

import Page from '../../../Page';
import { useStocks, useInvoices } from '../../../../hooks/owner';

// ----------------------------------------------------------------------

const title="Most Sales Products";
const subheader="";
const limitData = [5, 10, 25, 50, 100];


export default function SalesProductStatistics() {
  const [limit, setLimit] = useState(5);
  
  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
  } 
  const theme = useTheme();

  const stockData = useStocks ({
    startAfter: undefined,
    limit: 20,
    filterName: '',
  });
  const invoiceData = useInvoices ({
    startAfter: undefined,
    limit: 20,
    filterName: '',
  });

  const ownerId = useSelector((state) => state.firebase.auth).uid;

  const allStocks = [];
  let sellByMale = 0;
  let sellByFemale = 0;

  stockData.data.filter(item => item.ownerId === ownerId).forEach ((item)=>{
    let totalCount = 0;
    let totalAmount = 0;
    invoiceData.data.forEach(invoiceItem=>{
      invoiceItem.items.forEach(stockItem=> {
        if (item.itemName === stockItem.service) {
          totalCount += stockItem.quantity;
          totalAmount += stockItem.total;
        }
      })
    });
    if (item.gender === 'Male') sellByMale += totalCount;
    else sellByFemale += totalCount;
    allStocks.push({
      stockData: item,
      count: totalCount,
      amount: totalAmount,
    });
  });

  const totalSell = sellByMale + sellByFemale;
  sellByMale = Math.floor(sellByMale / totalSell * 100);
  sellByFemale = Math.floor(100-sellByMale);

  const topStocks = allStocks.sort((a, b)=> a.count < b.count ? 1: -1);
  const chartDataAmount = [];
  const chartDataCount = [];

  topStocks.forEach(item=> {
    chartDataAmount.push(
      {
        label: item.stockData.itemName,
        value: item.amount,
      }
    );
    chartDataCount.push(
      {
        label: item.stockData.itemName,
        value: item.count,
      }
    );
  });

  return (
    <Page>
      <Card container spacing={3}>

      <CardHeader
        title={title}
        subheader={subheader}
        action={
          <TextField
            select
            fullWidth
            value={limit}
            SelectProps={{ native: true }}
            onChange={handleChangeLimit}
            sx={{
              '& fieldset': { border: '0 !important' },
              '& select': { pl: 1, py: 0.5, pr: '24px !important', typography: 'subtitle2' },
              '& .MuiOutlinedInput-root': { borderRadius: 0.75, bgcolor: 'background.neutral' },
              '& .MuiNativeSelect-icon': { top: 4, right: 0, width: 20, height: 20 },
            }}
          >
            {limitData.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </TextField>
        }
      />

      <Grid container spacing={3} sx={{marginBottom: 5}}>

        <Grid item xs={12} md={6} lg={3}>
                <EcommerceLatestProducts title="" list={topStocks.slice(0, limit)} />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
            <AnalyticsTopProducts
              title=""
              subheader=""
              chartDataAmount={chartDataAmount.slice(0, limit)}
              chartDataCount={chartDataCount.slice(0, limit)}
            />
          </Grid>

        <Grid item xs={12} md={6} lg={5}>
        <EcommerceSaleByGender
            title="Sale By Gender"
            total={ totalSell }
            chartData={[
            { label: 'Men', value: sellByMale },
            { label: 'Women', value: sellByFemale },
            ]}
            chartColors={[
            [theme.palette.primary.light, theme.palette.primary.main],
            [theme.palette.warning.light, theme.palette.warning.main],
            ]}
        />
        </Grid>
      </Grid> 
      </Card>
    </Page>    
  );
}
