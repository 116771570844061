import { useState } from 'react';
import { Grid, Card, CardHeader, TextField } from '@mui/material';
import {
  EcommerceBestSewing,
} from '../../../../sections/@dashboard/general/e-commerce';

import {
  AnalyticsSewingCustomers,
} from '../../../../sections/@dashboard/general/analytics';

import Page from '../../../Page';
// ----------------------------------------------------------------------

import { useCustomers, useSewings } from '../../../../hooks/owner';


const title="Most Sewing Customers";
const subheader="";
const limitData = [5, 10, 25, 50, 100];


export default function SewingCustomerStatistics() {

  const [limit, setLimit] = useState(5);
  
  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
  } 

  const customerData = useCustomers ({
    startAfter: undefined,
    limit: 20,
    filterName: '',
  });
  const sewingData = useSewings ({
    startAfter: undefined,
    limit: 1000,
    filterName: '',
  });

  const allCustomers = [];

  customerData.data.forEach ((item)=>{
    let totalCount = 0;
    let totalAmount = 0;
    sewingData.data.forEach(sewingItem=>{
      if (item.customerId === sewingItem.customerId && sewingItem.sewingData.customerData !== null && sewingItem.sewingData.customerData.role !== 'staff') {
        totalAmount += Number(sewingItem.sewingData.cost);
        totalCount += 1;
      }
    });

    if (totalCount>0) allCustomers.push({
      customerData: item.customerData,
      count: totalCount,
      amount: totalAmount,
    });
  });

  const topCustomers = allCustomers.sort((a, b)=> a.amount < b.amount ? 1: -1);
  const chartDataAmount = [];
  const chartDataCount = [];

  topCustomers.forEach(item=> {
    chartDataAmount.push(
      {
        label: item.customerData.firstName,
        value: item.amount,
      }
    );
    chartDataCount.push(
      {
        label: item.customerData.firstName,
        value: item.count,
      }
    );
  });


  return (
    <Page>
      <Card container spacing={3}>

      <CardHeader
        title={title}
        subheader={subheader}
        action={
          <TextField
            select
            fullWidth
            value={limit}
            SelectProps={{ native: true }}
            onChange={handleChangeLimit}
            sx={{
              '& fieldset': { border: '0 !important' },
              '& select': { pl: 1, py: 0.5, pr: '24px !important', typography: 'subtitle2' },
              '& .MuiOutlinedInput-root': { borderRadius: 0.75, bgcolor: 'background.neutral' },
              '& .MuiNativeSelect-icon': { top: 4, right: 0, width: 20, height: 20 },
            }}
          >
            {limitData.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </TextField>
        }
      />

      <Grid container spacing={3} sx={{marginBottom: 5}}>
          <Grid item xs={12} md={6} lg={4}>
            <EcommerceBestSewing
              title=""
              tableData={topCustomers.slice(0, limit)}
              tableLabels={[
                { id: 'customer', label: 'Name' },
                { id: 'amount', label: 'Total Amount' },
                { id: 'count', label: 'Total Count'},
              ]}
            />
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
            <AnalyticsSewingCustomers
              title=""
              subheader=""
              chartDataAmount={chartDataAmount.slice(0, limit)}
              chartDataCount={chartDataCount.slice(0, limit)}
            />
          </Grid>
      </Grid>
      </Card>
    </Page>    

  );
}
