import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const addExpenditureCategory = createAsyncThunk(
  'expenditureCategory/add',
  async (expenditureData, { extra: { getFirestore, getFirebase } }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    const superAdminId = firebase.auth().currentUser.uid;
    const superAdminDocRef = firestore.collection('users').doc(superAdminId);
    const categoryDocRefId = firestore.collection('expenditureCategories').doc().id;
    const categoryDocRef = firestore.collection('expenditureCategories').doc(categoryDocRefId);
    const timestamp = firestore.FieldValue.serverTimestamp();

    await firestore.runTransaction(async (transaction) => {
      const superAdminDoc = await transaction.get(superAdminDocRef);

      if (!superAdminDoc.exists) {
        throw new Error('Super Admin does not exist!');
      }

      const newCategoriesCount = superAdminDoc.data().expenditureCategoriesCount + 1;
      transaction.set(categoryDocRef, {
        ...expenditureData,
        isActive: true,
        createdAt: timestamp,
        updatedAt: timestamp
      });

      transaction.update(superAdminDocRef, { expenditureCategoriesCount: newCategoriesCount });
    });
  }
);

// -----------------------------------------------------------------------------------------

export const updateExpenditureCategory = createAsyncThunk(
  'expenditureCategory/update',
  async (expenditureData, { extra: { getFirestore } }) => {
    const firestore = getFirestore();

    const timestamp = firestore.FieldValue.serverTimestamp();

    firestore.update(
      { collection: 'expenditureCategories', doc: expenditureData.id },
      {
        ...expenditureData,
        updatedAt: timestamp
      }
    );
  }
);

// -----------------------------------------------------------------------------------------

export const removeExpenditureCategory = createAsyncThunk(
  'expenditureCategory/delete',
  async (expenditureData, { extra: { getFirestore, getFirebase } }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    const superAdminId = firebase.auth().currentUser.uid;
    const superAdminDocRef = firestore.collection('users').doc(superAdminId);
    const categoryDocRef = firestore.collection('expenditureCategories').doc(expenditureData.id);

    await firestore.runTransaction(async (transaction) => {
      const superAdminDoc = await transaction.get(superAdminDocRef);
      const categoryDoc = await transaction.get(categoryDocRef);

      if (!superAdminDoc.exists) {
        throw new Error('Super Admin does not exist!');
      }

      if (!categoryDoc.exists) {
        throw new Error('Category item does not exist!');
      }

      const newCategoriesCount = superAdminDoc.data().expenditureCategoriesCount - 1;
      transaction.delete(categoryDocRef);

      transaction.update(superAdminDocRef, { expenditureCategoriesCount: newCategoriesCount });
    });
  }
);

const initialState = {
  error: null
};

const financesSlice = createSlice({
  name: 'finances',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addExpenditureCategory.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(addExpenditureCategory.rejected, (state, action) => {
      state.error = action.error;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });

    // ------------------------------------------------------------

    builder.addCase(updateExpenditureCategory.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(updateExpenditureCategory.rejected, (state, action) => {
      state.error = action.error;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });

    // ------------------------------------------------------------

    builder.addCase(removeExpenditureCategory.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(removeExpenditureCategory.rejected, (state, action) => {
      state.error = action.error;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });
  }
});

export default financesSlice.reducer;
