import { Navigate } from 'react-router-dom';
import StaffSewings from '../../pages/owner/Sewings';
import StaffCustomers from '../../pages/owner/Customers';
import StaffCommunication from '../../pages/owner/Communication';
import {
  FinanceExpenditure as StaffFinanceExpenditure
} from '../../pages/owner/finances';
import StaffInventory from '../../pages/owner/Inventory';
import StaffInvoiceList from '../../pages/owner/InvoiceList';
import StaffInvoiceDetails from '../../pages/owner/InvoiceDetails';
import StaffInvoiceCreate from '../../pages/owner/InvoiceCreate';
import StaffInvoiceEdit from '../../pages/owner/InvoiceEdit';
import StaffReport from '../../pages/owner/Report';

const staffRoutes = [
  { element: <Navigate to="/dashboard/my-sewings" replace /> },
  { path: 'my-sewings', element: <StaffSewings title="My Orders" /> },
  { path: 'sewings', element: <StaffSewings /> },
  { path: 'customers', element: <StaffCustomers /> },
  { path: 'finances/expenditure', element: <StaffFinanceExpenditure /> },
  { path: 'communication', element: <StaffCommunication /> },
  { path: 'ecommerce/inventory', element: <StaffInventory /> },
  { path: 'ecommerce/invoiceList', element: <StaffInvoiceList /> },
  { path: 'ecommerce/invoiceDetails/:id', element: <StaffInvoiceDetails /> },
  { path: 'ecommerce/invoiceCreate', element: <StaffInvoiceCreate /> },
  { path: 'ecommerce/invoiceEdit/:id', element: <StaffInvoiceEdit /> },
  { path: 'ecommerce/report', element: <StaffReport /> },
]

export default staffRoutes;