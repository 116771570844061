import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { imagesUrlArrayGet } from '../../../utils/imagesUrlGenerator';

export const addCategory = createAsyncThunk(
  'categories/add',
  async ({ info, uid }, { extra: { getFirestore } }) => {
    const firestore = getFirestore();

    // const superAdminId = firebase.auth().currentUser.uid;
    const newShopsRefId = firestore.collection('categories').doc().id;
    const timestamp = firestore.FieldValue.serverTimestamp();
    firestore.set(
      { collection: 'categories', doc: newShopsRefId },
      {
        name: info.name,
        ownerId: uid,
        createdAt: timestamp,
      }
    );
  }
);

export const updateCategory = createAsyncThunk(
  'categories/update',
  async (category, { extra: { getFirestore } }) => {
    const firestore = getFirestore();
    const {id} = category;
    const newShopsRefId = firestore.collection('categories').doc().id;
    const timestamp = firestore.FieldValue.serverTimestamp();

    const newCategory = await firestore.update(
      { collection: 'categories', doc: id },
      {
        name: category
      }
    );
  return newCategory;
  }
);

export const removeCategory = createAsyncThunk(
  'categories/remove',
  async (id, { extra: { getFirestore } }) => {
    console.log("delted id----->", id);
    const firestore = getFirestore();
    firestore.delete({ collection: 'categories', doc: id });
  }
);

const initialState = {
  error: null
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addCategory.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(addCategory.rejected, (state, action) => {
      state.error = action.error;
    });

    builder.addCase(updateCategory.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(updateCategory.rejected, (state, action) => {
      state.error = action.error;
    });

    // ----------------------------------------------------------------

    builder.addCase(removeCategory.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(removeCategory.rejected, (state, action) => {
      state.error = action.error;
    });
  }
});

export default categoriesSlice.reducer;
