import { format, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd-MM-yyyy HH:mm:ss');
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

export function parseMonthCount(months) {
  const year = months / 12 || 0;
  const month = months % 12;

  const cYear = year > 1 ? `${year} years` : `${year} year`;
  const cMonth = month > 1 ? `${month} & months` : `${month} & month`;

  return `${year ? cYear : ''} ${month ? cMonth : ''}`;
}
