import * as React from 'react';
import Proptypes from 'prop-types';
import { useDispatch } from 'react-redux';
// material
import {
  Box,
  // Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Radio,
  Alert,
  RadioGroup,
  FormControlLabel
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

StatusDialog.propTypes = {
  isOpen: Proptypes.bool,
  setIsOpen: Proptypes.func,
  selectedItem: Proptypes.object,
  statusFunc: Proptypes.func
};

export default function StatusDialog({ isOpen, setIsOpen, selectedItem, statusFunc }) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const _isMounted = React.useRef(true);

  // ----------------------------------------------------------------------

  const dispatch = useDispatch();

  // ----------------------------------------------------------------------

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = React.useCallback(() => {
    setOpen(false);
    setIsOpen(false);
  }, [setIsOpen]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleSave = async () => {
    if (selectedItem) {
      try {
        setIsLoading(true);
        const itemInfo = {
          ...selectedItem,
          isActive: value === 'true'
        };
        await dispatch(statusFunc(itemInfo));
        handleClose();
      } catch (error) {
        setError(error.message);
      } finally {
        if (_isMounted.current) {
          setIsLoading(false);
        }
      }
    }
  };

  // ----------------------------------------------------------------------

  React.useEffect(() => {
    if (isOpen) {
      handleOpen();
      if (selectedItem) {
        setValue(selectedItem.isActive.toString());
      }
    } else handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClose, isOpen]);

  React.useEffect(
    () => () => {
      _isMounted.current = false;
    },
    []
  );

  // ----------------------------------------------------------------------

  return (
    <>
      <Dialog maxWidth="xs" open={open} onClose={handleClose}>
        {error && (
          <Alert sx={{ maxWidth: '230px' }} severity="error">
            {error}
          </Alert>
        )}
        <DialogTitle sx={{ textAlign: 'center' }}>Status</DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content'
            }}
          >
            <FormControl sx={{ mt: 1, minWidth: 160 }}>
              <RadioGroup
                name="staffing-status"
                value={value}
                onChange={handleChange}
                sx={{ ml: 'auto', mr: 'auto' }}
              >
                <FormControlLabel value="true" control={<Radio color="success" />} label="Active" />
                <FormControlLabel
                  value="false"
                  control={<Radio color="error" />}
                  label="Inactive"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={isLoading} onClick={handleSave}>
            Save
          </LoadingButton>
          {/* <Button onClick={handleClose}>Cancel</Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
}
