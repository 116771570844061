import merge from 'lodash/merge';
import { useEffect, useState } from 'react';
// @mui
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import { Card, CardHeader, Box, TextField } from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';
import { BaseOptionChart } from '../../../chart';
  
import Page from '../../../Page';
import { useInvoices, useExpenditures } from '../../../../hooks/owner';
  // ----------------------------------------------------------------------

const salesColor = 'rgb(24, 144, 255)';
const profitColor = 'rgb(84, 214, 44)';
const expendColor = 'rgb(255, 72, 66)';
const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
const INPUT_WIDTH = 160;

export default function SalesBalanceStatistics() {

  const [seriesData, setSeriesData] = useState('Today');
  const today = new Date();
  const yesterday = new Date(today.getTime() - 86400000 + 3600000);
  const lastWeek = new Date(today.getTime() - 86400000 * 6);
  const lastMonth = new Date(today.getTime() - 86400000 * 30);
  const lastYear = new Date(today.getTime() - 86400000 * 360);
  const currentYear = today.getFullYear().toString();
  const threeMonthsBefore = new Date(today.getTime() - 86400000 * 30 * 3);
  const title="Sales Statistics";
  const subheader="";
  const [chartLabels, setChartLabels] = useState([]);
  const currentUser = useSelector((state) => state.firebase.auth);

  const [chartDatas, setChartDatas] = useState([]);
  const invoiceData = useInvoices({
    startAfter: undefined,
    limit: 1000,
    filterName: '',
  });
  const expenditures = useExpenditures({
    startAfter: undefined,
    limit: 1000,
    filterName: '',
  });

  const showSales = (data, st, ed) => {
    const filtered = data.filter(item=> 
      item.createDate.seconds * 1000 >= st && item.createDate.seconds * 1000 <=ed
      && item.ownerId === currentUser.uid
      ).reduce((prev, next) => 
      prev + next.gTotalPrice, 0
    );
    return filtered;
  }
  
  const showProfits = (data, st, ed) => {
    const filtered = data.filter(item=> 
      item.createDate.seconds * 1000 >= st && item.createDate.seconds * 1000 <=ed
      && item.ownerId === currentUser.uid
    ).reduce((prev, next) => 
      prev + next.totalProfit, 0
    );
    return filtered;
  }

  const showExpenditures = (data, st, ed) => {
    const filtered = data.filter(item=> 
      item.createdAt.seconds * 1000 >= st && item.createdAt.seconds * 1000 <=ed
    ).reduce((prev, next) => 
      prev + next.amount, 0
    );
    return filtered;
  }

  const [filterStartDate, setFilterStartDate] = useState(new Date());
  const [filterEndDate, setFilterEndDate] = useState(new Date());



  const [chartData] = useState([
    {
      viewOption: 'Today',
    },
    {
        viewOption: 'This Week',
    },
    {
        viewOption: 'This Month',
    },
    {
        viewOption: 'Last 3 Months',
    },
    {
        viewOption: 'Range of Date',
    },
    ]);

    const changeGraph = (item) => {
      // if (invoiceData.data.length === 0) return;
        const labels = [];
        const salesDatas = [];
        const profitDatas = [];
        const expendDatas = [];
        const datas = [];
        if (item === 'Today') {
            for (let t = yesterday.getTime(); t <= today.getTime(); t += 3600000) {
                const curr = new Date(t);
                const h = curr.getHours().toString();
                labels.push(h.concat(':00'));
                const st = curr.getTime() - 3600000;
                const ed = curr.getTime();
                const sdata = showSales(invoiceData.data, st, ed);
                const pdata = showProfits(invoiceData.data, st, ed);
                const edata = showExpenditures(expenditures.data, st, ed);
                salesDatas.push(sdata);
                profitDatas.push(pdata);
                expendDatas.push(edata);
            }
            datas.push({
                name: 'Sales',
                data: salesDatas,
                color: salesColor,
            });
            datas.push({
                name: 'Profit',
                data: profitDatas,
                color: profitColor,
            });
            datas.push({
                name: 'Expenditure',
                data: expendDatas,
                color: expendColor,
            });
        }
        else if (item === 'This Week') {
            for (let t = lastWeek.getTime(); t <= today.getTime(); t += 86400000) {
                const curr = new Date(t);
                const st = curr.getTime() - 86400000;
                const ed = curr.getTime();
                const d = days[curr.getDay()].toString();
                const m = curr.getMonth().toString();
                if (d==='1') labels.push(m.concat('/', d));
                else labels.push(d);
                const sdata = showSales(invoiceData.data, st, ed);
                const pdata = showProfits(invoiceData.data, st, ed);
                const edata = showExpenditures(expenditures.data, st, ed);
                salesDatas.push(sdata);
                profitDatas.push(pdata);
                expendDatas.push(edata);
            }
            datas.push({
                name: 'Sales',
                data: salesDatas,
                color: salesColor,
            });
            datas.push({
                name: 'Profit',
                data: profitDatas,
                color: profitColor,
            });
            datas.push({
                name: 'Expenditure',
                data: expendDatas,
                color: expendColor,
            });
        }
        else if (item === 'This Month') {
            for (let t = lastMonth.getTime(); t <= today.getTime(); t += 86400000 ) {
                const curr = new Date(t);
                const st = curr.getTime() - 86400000;
                const ed = curr.getTime();
                const d = curr.getDate().toString();
                const m = (curr.getMonth() + 1).toString();
                if (d==='1') labels.push(m.concat('/', d));                
                else labels.push(d);
                const sdata = showSales(invoiceData.data, st, ed);
                const pdata = showProfits(invoiceData.data, st, ed);
                const edata = showExpenditures(expenditures.data, st, ed);
                salesDatas.push(sdata);
                profitDatas.push(pdata);
                expendDatas.push(edata);
            }
            datas.push({
                name: 'Sales',
                data: salesDatas,
                color: salesColor,
            });
            datas.push({
                name: 'Profit',
                data: profitDatas,
                color: profitColor,
            });
            datas.push({
                name: 'Expenditure',
                data: expendDatas,
                color: expendColor,
            });
        }
        else if (item === 'Last 3 Months') {
            for (let t = threeMonthsBefore.getTime(); t <= today.getTime(); t += 86400000 * 3 ) {
                const curr = new Date(t);
                const st = curr.getTime() - 86400000 * 3;
                const ed = curr.getTime();
                const d = curr.getDate().toString();
                const m = (curr.getMonth() + 1).toString();
                if (d==='1' || d==='2' || d==='3') labels.push(m.concat('/', d));                
                else labels.push(d);
                const sdata = showSales(invoiceData.data, st, ed);
                const pdata = showProfits(invoiceData.data, st, ed);
                const edata = showExpenditures(expenditures.data, st, ed);
                salesDatas.push(sdata);
                profitDatas.push(pdata);
                expendDatas.push(edata);
            }
            datas.push({
                name: 'Sales',
                data: salesDatas,
                color: salesColor,
            });
            datas.push({
                name: 'Profit',
                data: profitDatas,
                color: profitColor,
            });
            datas.push({
                name: 'Expenditure',
                data: expendDatas,
                color: expendColor,
            });
        }
        else {
            const step = Math.max((filterEndDate.getTime() - filterStartDate.getTime()) / 20, 86400000);
            for (let t = filterStartDate.getTime(); t <= filterEndDate.getTime(); t += step) {
              const curr = new Date(t);
              const st = curr.getTime() - step;
              const ed = curr.getTime();
              const d = curr.getDate().toString();
              const m = (curr.getMonth() + 1).toString();
              if (labels.length === 0 || labels[labels.length - 1] > Math.floor(d) ) labels.push(m.concat('/', d));                
              else labels.push(d);
              const sdata = showSales(invoiceData.data, st, ed);
              const pdata = showProfits(invoiceData.data, st, ed);
              const edata = showExpenditures(expenditures.data, st, ed);
              salesDatas.push(sdata);
              profitDatas.push(pdata);
              expendDatas.push(edata);
          }
          datas.push({
              name: 'Sales',
              data: salesDatas,
              color: salesColor,
          });
          datas.push({
              name: 'Profit',
              data: profitDatas,
              color: profitColor,
          });
          datas.push({
              name: 'Expenditure',
              data: expendDatas,
              color: expendColor,
          });

        }
        // else if (item === 'This Year') {
        //     for (let t = lastYear.getTime(), mm = lastYear.getMonth() + 1; t <= today.getTime(); t += 86400000 * 30, mm += 1 ) {
        //         const curr = new Date(t);
        //         const st = curr.getTime() - 86400000 * 30;
        //         const ed = curr.getTime();
        //         if (mm === 13) mm = 1;
        //         if (mm === 1) labels.push(currentYear.concat('.', mm));
        //         else labels.push(mm);
        //         const sdata = showSales(invoiceData.data, st, ed);
        //         const pdata = showProfits(invoiceData.data, st, ed);
        //         const edata = showExpenditures(expenditures.data, st, ed);
        //         salesDatas.push(sdata);
        //         profitDatas.push(pdata);
        //         expendDatas.push(edata);
        //     }
        //     datas.push({
        //         name: 'Sales',
        //         data: salesDatas,
        //         color: salesColor,
        //     });
        //     datas.push({
        //         name: 'Profit',
        //         data: profitDatas,
        //         color: profitColor,
        //     });
        //     datas.push({
        //         name: 'Expenditure',
        //         data: expendDatas,
        //         color: expendColor,
        //     });
        // }



        setChartLabels(labels);
        setChartDatas(datas);
      }


    const onFilterStartDate = (newValue) => {
      setFilterStartDate(newValue);
      changeGraph('Range of Date');
    }
  
    const onFilterEndDate = (newValue) => {
      setFilterEndDate(newValue);
      changeGraph('Range of Date');
    }

    useEffect (() => {
      if (chartLabels.length === 0) changeGraph(seriesData);
    },[invoiceData]);

    useEffect (() => {
      changeGraph(seriesData);
    },[filterStartDate]);

    useEffect (() => {
      changeGraph(seriesData);
    },[filterEndDate]);


    const handleChangeSeriesData = (event) => {
    setSeriesData(event.target.value);
    if (event.target.value === 'Range of Date') setDisp('block');
    else setDisp('none');
    changeGraph(event.target.value);
  };

  const chartOptions = merge(BaseOptionChart(), {
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: chartLabels,
    },
    tooltip: {
      y: {
        formatter: (val) => `₵${val}`,
      },
    },
  });

  const [disp, setDisp] = useState('none');

  return (
    <Page>
    <Card sx={{marginBottom: 5, position: 'relative'}}>
      <CardHeader
        title={title}
        subheader={subheader}
        action={
          <TextField
            select
            fullWidth
            value={seriesData}
            SelectProps={{ native: true }}
            onChange={handleChangeSeriesData}
            sx={{
              '& fieldset': { border: '0 !important' },
              '& select': { pl: 1, py: 0.5, pr: '24px !important', typography: 'subtitle2' },
              '& .MuiOutlinedInput-root': { borderRadius: 0.75, bgcolor: 'background.neutral' },
              '& .MuiNativeSelect-icon': { top: 4, right: 0, width: 20, height: 20 },
            }}
          >
            {chartData.map((option) => (
              <option key={option.viewOption} value={option.viewOption}>
                {option.viewOption}
              </option>
            ))}
          </TextField>
        }
      />

      <DatePicker
        id="startDate"
        label="Start date"
        value={filterStartDate}
        onChange={ onFilterStartDate }
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            sx={{
              maxWidth: { md: INPUT_WIDTH, float: 'right'},
              position: 'absolute',
              top: '15px',
              right: '360px',
              display: `${disp}` ,
            }}
          />
        )}
      />

      <DatePicker
        id="endDate"
        label="End date"
        value={filterEndDate}
        onChange={ onFilterEndDate}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            sx={{
              maxWidth: { md: INPUT_WIDTH, float: 'right'},
              position: 'absolute',
              top: '15px',
              right: '180px',
              display: `${disp}` ,
            }}
          />
        )}
      />


        <Box sx={{ mt: 3, mx: 3 }} dir="ltr">
            <ReactApexChart type="bar" series={chartDatas} options={chartOptions} height={364} />
        </Box>
      </Card>


    </Page>    

  );
}
