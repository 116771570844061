import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import IconButton from '@mui/material/IconButton';
import Scrollbar from '../../../Scrollbar';
import { fDate } from '../../../../utils/formatTime';

StockViewDialog.propTypes = {
  isOpen: PropTypes.bool,
  stock: PropTypes.object,
  setIsOpen: PropTypes.func,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func
};

// --------------------------------------------------

const ImageListStyles = styled(ImageList)({
  position: 'relative',
  listStyle: 'none',
  // marginTop: '12px',
  padding: '0px',
  display: 'flex'
});

const ThumbBoxStyles = styled(Box)({
  top: '0px',
  left: '0px',
  right: '0px',
  bottom: '0px',
  lineHeight: 0,
  position: 'absolute',
  backgroundSize: 'cover !important'
});

const ImageListItemStyles = styled(ImageListItem)({
  justifyContent: 'flex-start',
  alignItems: 'center',
  boxSizing: 'border-box',
  textAlign: 'left',
  minWidth: '80px !important',
  height: '80px !important',
  position: 'relative',
  display: 'inline-flex',
  textDecoration: 'none',
  padding: '0px',
  margin: '4px',
  borderRadius: '10px',
  overflow: 'hidden',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'rgba(145, 158, 171, 0.24)',
  borderImage: 'initial'
});

const imgStyles = {
  width: '100%',
  height: '100%',
  objectFit: 'cover'
};

// --------------------------------------------------

export default function StockViewDialog({ stock, isOpen, setIsOpen, handleEdit, handleDelete }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = React.useCallback(() => {
    setOpen(false);
    setIsOpen(false);
  }, [setIsOpen]);

  const handleDeleteClick = () => {
    handleClose();
    handleDelete();
  };

  const handleEditClick = () => {
    handleClose();
    handleEdit();
  };

  // --------------------------------------------------

  React.useEffect(() => {
    if (isOpen) handleOpen();
    else handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClose, isOpen]);

  // --------------------------------------------------

  const fabricsThumb = stock?.fabrics?.map((file) => (
    <ImageListItemStyles key={file.name || file}>
      <ThumbBoxStyles>
        <img
          src={file.preview || file}
          style={imgStyles}
          srcSet={file.preview || file}
          alt="preview"
          loading="lazy"
        />
      </ThumbBoxStyles>
    </ImageListItemStyles>
  ));

  return stock.length > 0 ? (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {/* {title} */}
          {stock[0].status === 'added' || stock[0].status === 'updated' ? 'Stock History' : 'Sales History'}
          <IconButton
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Icon icon={closeFill} width={24} height={24} />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
              <Scrollbar>
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <Box>
                    <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                      Date
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box>
                    <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                      Quantity
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box>
                    <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                      {stock[0].status === 'added' || stock[0].status === 'updated' ? 'Status' : 'Customer'}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
                {



                  stock.map(item=> {return(
                <Grid container>
                <Grid item xs={12} sm={4}>
                  <Box>
                    <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                      {item.status === 'added' || item.status === 'updated' ? fDate(item.date.seconds * 1000) : fDate(item.date * 1000)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box>
                    <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                        {item.quantity} 
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box>
                    <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                      {item.status === 'added' || item.status === 'updated' ? item.status: item.customer}
                    </Typography>
                  </Box>
                </Grid>

              </Grid>
                    );}
                  )
                }
              </Scrollbar>
        </DialogContent>
      </Dialog>
    </div>
  ):null;
}
