import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { useLocation } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import Logo from './Logo';

AuthIsLoaded.propTypes = {
  children: PropTypes.node.isRequired
};

const PaperStyles = styled(Paper)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: 'calc(100vh - 16px)',
  width: 'calc(100vw - 16px)'
}));

function AuthIsLoaded({ children }) {
  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);

  const location = useLocation();

  if (!isLoaded(auth) || (!isLoaded(profile) && location.pathname !== '/verification'))
    return (
      <PaperStyles elevation={0}>
        <Logo sx={{ width: '130px', height: '130px', mb: 1.5 }} />
        <CircularProgress size={20} color="grey" />
      </PaperStyles>
    );
  return children;
}

export default AuthIsLoaded;
