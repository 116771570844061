import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
// form
import { useFormik, Form, FormikProvider, Formik } from 'formik';
// @mui
import {
  Stack,
  IconButton,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  TextField,
  Alert
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../Iconify';

import { newPassword } from '../../../redux/slices/auth';
import { getErrorMessage } from '../../../utils/firebaseError';

// ----------------------------------------------------------------------

export default function NewPasswordForm() {
  const navigate = useNavigate();
  const _isMounted = useRef(true);
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const profile = useSelector((state) => state.firebase.profile);

  const { enqueueSnackbar } = useSnackbar();

  const [showPassword, setShowPassword] = useState(false);

  const phoneRecovery = sessionStorage.getItem('phone-recovery');

  const VerifyCodeSchema = Yup.object().shape({
    code1: Yup.string().required('Code is required'),
    code2: Yup.string().required('Code is required'),
    code3: Yup.string().required('Code is required'),
    code4: Yup.string().required('Code is required'),
    code5: Yup.string().required('Code is required'),
    code6: Yup.string().required('Code is required'),
    phoneNumber: Yup.string()
      .max(9, 'Invalid phone number')
      .min(9, 'Invalid phone number')
      .required('Phone number is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
    confirmPassword: Yup.string()
      .required('Confirm password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
  });

  const defaultValues = {
    code1: '',
    code2: '',
    code3: '',
    code4: '',
    code5: '',
    code6: '',
    phoneNumber: phoneRecovery || '',
    password: '',
    confirmPassword: ''
  };

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: VerifyCodeSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const info = {
          code: `${values.code1}${values.code2}${values.code3}${values.code4}${values.code5}${values.code6}`,
          password: values.password
        };

        await dispatch(newPassword(info));

        sessionStorage.removeItem('phone-recovery');

        if (isLoaded(profile)) navigate('/dashboard', { replace: true });

        if (_isMounted.current) {
          enqueueSnackbar('Change password success!');
        }
      } catch (error) {
        setError(getErrorMessage(error) || error.message);
        window.scrollTo(0, 0);
      } finally {
        if (_isMounted.current) {
          setSubmitting(false);
        }
      }
    }
  });

  useEffect(() => {
    const target = document.querySelector('input.field-code');

    target?.addEventListener('paste', handlePaste);

    return () => {
      target?.removeEventListener('paste', handlePaste);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => () => {
      _isMounted.current = false;
    },
    []
  );

  const handlePaste = (event) => {
    let data = event.clipboardData.getData('text');

    data = data.split('');

    [].forEach.call(document.querySelectorAll('.field-code'), (node, index) => {
      node.value = data[index];

      const fieldIndex = `code${index + 1}`;

      formik.setFieldValue(fieldIndex, data[index]);
    });

    event.preventDefault();
  };

  const handleChangeWithNextField = (event, handleChange) => {
    const { maxLength, value, name } = event.target;
    const fieldIndex = name.replace('code', '');
    const fieldIntIndex = Number(fieldIndex);
    if (value.length >= maxLength) {
      if (fieldIntIndex < 6) {
        const nextfield = document.querySelector(`input[name=code${fieldIntIndex + 1}]`);
        if (nextfield !== null) {
          nextfield.focus();
        }
      }
    }
    handleChange(event);
  };

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, handleChange } = formik;

  return (
    <FormikProvider value={formik}>
      {error && (
        <Alert sx={{ mt: -2, mb: 3 }} severity="error">
          {error}
        </Alert>
      )}
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            label="Phone number"
            {...getFieldProps('phoneNumber')}
            error={Boolean(touched.phoneNumber && errors.phoneNumber)}
            helperText={touched.phoneNumber && errors.phoneNumber}
            disabled={!!phoneRecovery}
          />

          <Stack direction="row" spacing={2} justifyContent="center">
            {['code1', 'code2', 'code3', 'code4', 'code5', 'code6'].map((name, index) => (
              <Formik key={name} name={`code${index + 1}`}>
                {() => (
                  <OutlinedInput
                    {...getFieldProps(name)}
                    error={Boolean(touched[name] && errors[name])}
                    autoFocus={index === 0}
                    placeholder="-"
                    onChange={(event) => handleChangeWithNextField(event, handleChange)}
                    inputProps={{
                      className: 'field-code',
                      maxLength: 1,
                      sx: {
                        p: 0,
                        textAlign: 'center',
                        width: { xs: 36, sm: 56 },
                        height: { xs: 36, sm: 56 }
                      }
                    }}
                  />
                )}
              </Formik>
            ))}
          </Stack>

          {(!!errors.code1 ||
            !!errors.code2 ||
            !!errors.code3 ||
            !!errors.code4 ||
            !!errors.code5 ||
            !!errors.code6) && (
            <FormHelperText error sx={{ px: 2 }}>
              Code is required
            </FormHelperText>
          )}

          <TextField
            label="Password"
            type={showPassword ? 'text' : 'password'}
            {...getFieldProps('password')}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <TextField
            label="Confirm New Password"
            {...getFieldProps('confirmPassword')}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            helperText={touched.confirmPassword && errors.confirmPassword}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{ mt: 3 }}
          >
            Change password
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
