import { Icon } from '@iconify/react';
import appstoreFilled from '@iconify/icons-ant-design/appstore-filled';
import bookOpenFill from '@iconify/icons-eva/book-open-fill';
import idcardFilled from '@iconify/icons-ant-design/idcard-filled';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import messageCircleFill from '@iconify/icons-eva/message-circle-fill';
import sewingMachine from '@iconify/icons-si-glyph/sewing-machine';
import SvgIconStyle from '../../../components/SvgIconStyle';
import { checkForRole } from '../../../utils/checkAuthUserRole';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;
const getIconAlt = (name) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

// owner sidenav items
const ownerNavConfig = () => [
  {
    items: [
      {
        title: 'App',
        icon: getIcon(appstoreFilled),
        path: '/dashboard/app'
      },
      {
        title: 'Customers',
        icon: getIcon(peopleFill),
        path: '/dashboard/customers'
      },
      {
        title: 'Orders',
        icon: getIcon(sewingMachine),
        path: '/dashboard/sewings'
      },
      {
        title: 'E-Commerce',
        icon: getIconAlt('ic_cart'),
        path: '/dashboard/ecommerce',
        isVip: true,
        children: [
          {
            title: 'Shop',
            path: '/dashboard/ecommerce/shop'
          },
          {
            title: 'Inventory',
            path: '/dashboard/ecommerce/inventory'
          },
          // {
          //   title: 'Pos',
          //   path: '/dashboard/ecommerce/pos'
          // },
          {
            title: 'invoice',
            path: '/dashboard/ecommerce/invoice',
            // icon: getIcon(personAddFill),
            children: [
              { title: 'list', path: '/dashboard/ecommerce/invoiceList' },
              // { title: 'details', path: PATH_DASHBOARD.invoice.view },
              { title: 'create', path: '/dashboard/ecommerce/invoiceCreate' },
              // { title: 'edit', path: PATH_DASHBOARD.invoice.edit }
            ]
          },
          {
            title: 'Sales History',
            path: '/dashboard/ecommerce/report'
          }
        ]
      },
      {
        title: 'Report',
        icon: getIconAlt('ic_banking'),
        path: '/dashboard/finances',
        children: [
          {
            title: 'Overview',
            path: '/dashboard/finances/overview'
          },
          {
            title: 'Sewings',
            path: '/dashboard/finances/overview'
          },
          {
            title: 'Shop',
            path: '/dashboard/finances/overview'
          },
          {
            title: 'Expenditure',
            path: '/dashboard/finances/expenditure'
          },
          // {
          //   title: 'Reports',
          //   path: '/dashboard/finances/reports',
          //   children: [
          //     {
          //       title: 'Shop Reports',
          //       path: '/dashboard/finances/reports/shopReports'
          //     },
          //     {
          //       title: 'Sewing Reports',
          //       path: '/dashboard/finances/reports/sewingReports',
          //     },
          //   ],
          // }
        ]
      },
      {
        title: 'Staff',
        icon: getIcon(personAddFill),
        path: '/dashboard/staff'
      },
      {
        title: 'Communication',
        icon: getIcon(messageCircleFill),
        path: '/dashboard/communication'
      },
      {
        title: 'Subscription',
        icon: getIconAlt('ic_booking'),
        path: '/dashboard/subscriptions'
      },
      {
        title: 'Transactions',
        icon: getIconAlt('ic_ecommerce'),
        path: '/dashboard/transactions',
        children: [
          // {
          //   title: 'Sewings',
          //   path: '/dashboard/transactions/sewings'
          // },
          {
            title: 'Subscriptions',
            path: '/dashboard/transactions/subscriptions'
          }
        ]
      }
    ]
  },
];

// super admin side bar nav items
const superAdminNavConfig = () => [
  {
    subheader: 'general',
    items: [
      {
        title: 'App',
        icon: getIcon(pieChart2Fill),
        path: '/dashboard/app'
      },
      {
        title: 'Customers',
        icon: getIcon(peopleFill),
        path: '/dashboard/customers'
      },
      {
        title: 'Sewings',
        icon: getIcon(sewingMachine),
        path: '/dashboard/sewings'
      },
      {
        title: 'Owners',
        icon: getIcon(idcardFilled),
        path: '/dashboard/owners'
      },
      {
        title: 'Communications',
        icon: getIcon(messageCircleFill),
        path: '/dashboard/communications'
      }
    ]
  },
  {
    subheader: 'management',
    items: [
      {
        title: 'Measurements',
        icon: getIcon(bookOpenFill),
        path: '/dashboard/measurements',
        children: [
          {
            title: 'Parent',
            path: '/dashboard/measurements/parent'
          },
          {
            title: 'List',
            path: '/dashboard/measurements/list'
          }
        ]
      },
      {
        title: 'Finances',
        icon: getIconAlt('ic_banking'),
        path: '/dashboard/finances',
        children: [
          {
            title: 'Expenditure',
            path: '/dashboard/finances/expenditure'
          }
        ]
      },
      {
        title: 'Subscriptions',
        icon: getIconAlt('ic_booking'),
        path: '/dashboard/subscriptions',
        children: [
          {
            title: 'Categories',
            path: '/dashboard/subscriptions/categories'
          },
          {
            title: 'Plans',
            path: '/dashboard/subscriptions/plans'
          },
          {
            title: 'Individual',
            path: '/dashboard/subscriptions/individual'
          }
        ]
      }
    ]
  }
];

// staff sidenav items
const staffNavConfig = (permissions) => [
  {
    subheader: 'general',
    items: [
      {
        title: 'My Orders',
        icon: getIcon(sewingMachine),
        path: '/dashboard/my-sewings'
      },
      {
        title: 'Orders',
        icon: getIcon(sewingMachine),
        path: '/dashboard/sewings'
      },
      permissions.includes(1) ?
      {
        title: 'Customers',
        icon: getIcon(peopleFill),
        path: '/dashboard/customers'
      } : null,
      permissions.includes(2) ?
      {
        title: 'Finances',
        icon: getIconAlt('ic_banking'),
        path: '/dashboard/finances',
        children: [
          {
            title: 'Expenditure',
            path: '/dashboard/finances/expenditure'
          },
        ]
      } : null,
      permissions.includes(4) ?
      {
        title: 'E-Commerce',
        icon: getIconAlt('ic_cart'),
        path: '/dashboard/ecommerce',
        isVip: true,
        children: [
          {
            title: 'Inventory',
            path: '/dashboard/ecommerce/inventory'
          },
          // {
          //   title: 'Pos',
          //   path: '/dashboard/ecommerce/pos'
          // },
          {
            title: 'invoice',
            path: '/dashboard/ecommerce/invoice',
            icon: getIcon(personAddFill),
            children: [
              { title: 'list', path: '/dashboard/ecommerce/invoiceList' },
              // { title: 'details', path: PATH_DASHBOARD.invoice.view },
              { title: 'create', path: '/dashboard/ecommerce/invoiceCreate' },
              // { title: 'edit', path: PATH_DASHBOARD.invoice.edit }
            ]
          },
          {
            title: 'Sales History',
            path: '/dashboard/ecommerce/report'
          }
        ]
      } : null,
      permissions.includes(3) ?
      {
        title: 'Communication',
        icon: getIcon(messageCircleFill),
        path: '/dashboard/communication'
      } : null,
    ]
  }
]

const customerNavConfig = () => [];

const navConfig = (token) => {
  const { identifier, permissions } = token && checkForRole(token);

  if (identifier === 'owner') {
    return ownerNavConfig();
  }
  if (identifier === 'super_admin') {
    return superAdminNavConfig();
  }
  if (identifier === 'staff') {
    return staffNavConfig(permissions);
  }
  return customerNavConfig();

};

export default navConfig;
