import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { isEmpty } from 'react-redux-firebase';
import plusFill from '@iconify/icons-eva/plus-fill';
import eyeOutlined from '@iconify/icons-ant-design/eye-outlined';
// material
import {
  Card,
  Box,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  CircularProgress,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import {
  StaffStatusDialog,
  StaffViewDialog,
  StaffEmpty
} from '../../components/_dashboard/owner/staff';
import { ListHead, ListToolbar } from '../../components/_dashboard/owner/@general';
import { AddStaffDialog } from '../../components/_adds/owner';

import { useStaff } from '../../hooks/owner';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'contact', label: 'Contact', alignRight: false },
  { id: 'sex', label: 'Sex', alignRight: false },
  { id: 'joinedOn', label: 'Joined On', alignRight: false },
  { id: 'accountStatus', label: 'Account Status', alignRight: false },
  { id: '' }
];

// -----------------------------------------------------------------------

export default function Staff() {
  const { searchedStaff } = useSelector((state) => state.firestore.ordered);
  const { requesting } = useSelector((state) => state.firestore.status);
  const { staffCount } = useSelector((state) => state.firebase.profile);

  // ----------------------------------------------------------------------

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('firstName');
  const [filterName, setFilterName] = useState('');
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [isStaffEditOpen, setIsStaffEditOpen] = useState(false);
  const [staffOpen, setStaffOpen] = useState(false);
  const [isStaffStatusOpen, setIsStaffStatusOpen] = useState(false);
  const [cursor, setCursor] = useState(undefined);
  const staff = useStaff({
    startAfter: cursor,
    limit: rowsPerPage,
    filterName
  });

  // -----------------------------------------------------------------------

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setCursor(staff.data[staff.data.length - 1].staffData.createdAt);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleEdit = (item) => {
    setIsStaffEditOpen(true);
    setSelectedStaff(item);
  };

  const handleStaffClick = (item) => {
    setSelectedStaff(item);
    setStaffOpen(true);
  };

  const handleStaffStatus = (e, item) => {
    e.stopPropagation();
    setSelectedStaff(item);
    setIsStaffStatusOpen(true);
  };

  // ----------------------------------------------------------------------

  useEffect(() => {
    if (!isStaffEditOpen && !staffOpen && !isStaffStatusOpen) {
      setSelectedStaff(null);
    }
  }, [isStaffEditOpen, staffOpen, isStaffStatusOpen]);

  useEffect(() => {
    if (requesting.searchedStaff) {
      if (page) setPage(0);
      else setPage(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requesting.searchedStaff]);

  // -----------------------------------------------------------------

  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * rowsPerPage - filterName ? searchedStaff?.length : staff.data?.length
        )
      : 0;

  return (
    <Page title="Staff | FashionsPal">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Staff
          </Typography>
          <AddStaffDialog
            isStaffOpen={isStaffEditOpen}
            setIsStaffOpen={setIsStaffEditOpen}
            selectedStaff={selectedStaff}
          >
            <Button variant="contained" component="div" startIcon={<Icon icon={plusFill} />}>
              Add Staff
            </Button>
          </AddStaffDialog>
        </Stack>

        <Card>
          {!!staff.data.length && (
            <ListToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
              requesting={requesting.searchedStaff}
            />
          )}

          {staff.loading || requesting.searchedStaff ? (
            <Box
              sx={{
                height: '60vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgress size={55} />
            </Box>
          ) : (
            <>
              {!staff.data.length ? (
                <StaffEmpty />
              ) : (
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <ListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={filterName ? searchedStaff?.length || 0 : staffCount || 0}
                        onRequestSort={handleRequestSort}
                      />
                      <TableBody>
                        {(filterName ? searchedStaff || staff.data : staff.data)
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            const {
                              firstName,
                              lastName,
                              phoneNumber,
                              sex,
                              isAccountEnabled,
                              createdAt,
                              deletedAt
                            } = row.staffData;

                            return (
                              <TableRow
                                key={row.id}
                                sx={{ cursor: 'pointer' }}
                                tabIndex={-1}
                                onClick={() => handleStaffClick(row)}
                              >
                                <TableCell>
                                  <Typography
                                    variant="subtitle2"
                                    noWrap
                                    sx={{ color: `${deletedAt ? 'text.disabled' : ''}` }}
                                  >
                                    {rowsPerPage * (page + 1) + (index + 1) - rowsPerPage}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{ color: `${deletedAt ? 'text.disabled' : ''}` }}
                                >
                                  {`${firstName} ${lastName}` || 'N/A'}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{ color: `${deletedAt ? 'text.disabled' : ''}` }}
                                >
                                  {phoneNumber || 'N/A'}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{ color: `${deletedAt ? 'text.disabled' : ''}` }}
                                >
                                  {sex || 'N/A'}
                                </TableCell>
                                <TableCell align="left">
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ color: `${deletedAt ? 'text.disabled' : ''}` }}
                                  >
                                    {createdAt ? format(createdAt.toDate(), 'dd MMM yyyy') : 'N/A'}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: `${deletedAt ? 'text.disabled' : 'text.secondary'}`
                                    }}
                                  >
                                    {createdAt ? format(createdAt.toDate(), 'p') : 'N/A'}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Label
                                    sx={{ cursor: 'pointer' }}
                                    onClick={(e) => handleStaffStatus(e, row)}
                                    variant="ghost"
                                    color={(!isAccountEnabled && 'error') || 'success'}
                                  >
                                    {isAccountEnabled
                                      ? sentenceCase('Enabled')
                                      : sentenceCase('Disabled')}
                                  </Label>
                                </TableCell>
                                <TableCell align="right">
                                  <Button
                                    variant="contained"
                                    component="div"
                                    startIcon={<Icon icon={eyeOutlined} />}
                                    onClick={() => handleStaffClick(row)}
                                    sx={{ mr: 1.5 }}
                                  >
                                    View
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      {isEmpty(searchedStaff) && filterName ? (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : null}
                    </Table>
                  </TableContainer>
                </Scrollbar>
              )}
            </>
          )}

          {!requesting.searchedStaff && !!staff.data.length && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filterName ? searchedStaff?.length || 0 : staffCount || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>

        {/* ----- Dialog Start ----- */}
        <StaffStatusDialog
          isOpen={isStaffStatusOpen}
          setIsOpen={setIsStaffStatusOpen}
          selectedStaff={selectedStaff}
        />
        {staffOpen && (
          <StaffViewDialog
            isOpen={staffOpen}
            setIsOpen={setStaffOpen}
            staff={selectedStaff}
            handleEdit={() => handleEdit(selectedStaff)}
          />
        )}
        {/* ----- Dialog End ----- */}
      </Container>
    </Page>
  );
}
