import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
// material
import { Typography, ImageList, ImageListItem, Box, IconButton } from '@mui/material';

// ----------------------------------------------------------------------

const RootStyles = styled('div')({
  width: '100%',
  marginTop: '12px !important'
});

const DropzoneStyles = styled('div')({
  backgroundColor: 'rgb(244, 246, 248)',
  outline: 'none',
  padding: '40px 8px',
  borderRadius: '8px',
  borderWidth: '1px',
  borderStyle: 'dashed',
  borderColor: 'rgba(145, 158, 171, 0.32)',
  borderImage: 'initial',
  textAlign: 'center',
  '&:hover': {
    opacity: '0.72',
    cursor: 'pointer'
  }
});

const ImageListItemStyles = styled(ImageListItem)({
  justifyContent: 'flex-start',
  alignItems: 'center',
  boxSizing: 'border-box',
  textAlign: 'left',
  minWidth: '80px !important',
  height: '80px !important',
  position: 'relative',
  display: 'inline-flex',
  textDecoration: 'none',
  padding: '0px',
  margin: '4px',
  borderRadius: '10px',
  overflow: 'hidden',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'rgba(145, 158, 171, 0.24)',
  borderImage: 'initial'
});

const BoxStyles = styled(Box)({
  top: '0px',
  left: '0px',
  right: '0px',
  bottom: '0px',
  lineHeight: 0,
  position: 'absolute',
  backgroundSize: 'cover !important'
});

const ImageListStyles = styled(ImageList)({
  position: 'relative',
  listStyle: 'none',
  marginTop: '12px',
  padding: '0px',
  display: 'flex'
});

const IconButtonStyles = styled(IconButton)({
  position: 'absolute',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
  webkitTapHighlightColor: 'transparent',
  cursor: 'pointer',
  userSelect: 'none',
  verticalAlign: 'middle',
  webkitAppearance: 'none',
  textAlign: 'center',
  fontSize: '1.125rem',
  top: '6px',
  right: '6px',
  color: 'rgb(255, 255, 255)',
  backgroundColor: 'rgba(22, 28, 36, 0.72)',
  outline: '0px',
  borderWidth: '0px',
  borderStyle: 'initial',
  borderColor: 'initial',
  borderImage: 'initial',
  borderRadius: '50%',
  overflow: 'visible',
  transition: 'background-color 150ms cubic - bezier(0.4, 0, 0.2, 1) 0ms',
  padding: '2px',
  width: '20px',
  height: '20px',
  lineHeight: '0',
  '&:hover': {
    backgroundColor: 'rgba(22, 28, 36, 0.48)'
  }
});

const imgStyles = {
  width: '100%',
  height: '100%',
  objectFit: 'cover'
};

// ----------------------------------------------------------------------

UploadDropzone.propTypes = {
  // maxFiles: PropTypes.element,
  onChange: PropTypes.func,
  images: PropTypes.array
};

// ----------------------------------------------------------------------

export default function UploadDropzone({ onChange, images, maxFiles }) {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      if (maxFiles > files.length) {
        setFiles((prevFiles) => [
          ...prevFiles,
          ...acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file)
            })
          )
        ]);
      }
    }
  });

  const handleRemove = (file) => {
    setFiles(files.filter((f) => f.name !== file.name || file !== f));
  };

  // ----------------------------------------------------------------------

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    if (files && files.length > 0) {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
      onChange(files);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  useEffect(() => {
    setFiles(images || []);
  }, [images]);

  // ----------------------------------------------------------------------

  const thumbs = files
    ? files.map((file) => (
        <ImageListItemStyles key={file.name || file}>
          <BoxStyles>
            <img
              src={file.preview || file}
              style={imgStyles}
              srcSet={file.preview || file}
              alt="preview"
              loading="lazy"
            />
          </BoxStyles>
          <IconButtonStyles onClick={() => handleRemove(file)}>×</IconButtonStyles>
        </ImageListItemStyles>
      ))
    : [];

  return (
    <RootStyles>
      <DropzoneStyles maxFiles={1} {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <Typography variant="h5">Drop or Select file</Typography>
        <Typography variant="body2">Drop files here or click through your machine</Typography>
      </DropzoneStyles>
      <ImageListStyles>{thumbs}</ImageListStyles>
    </RootStyles>
  );
}
