import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { isEmpty } from 'react-redux-firebase';
// material
import {
  Card,
  Box,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  CircularProgress,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import { ListHead, ListToolbar } from '../../../components/_dashboard/owner/@general';

import { useTransactionsSubscriptions } from '../../../hooks/owner';
import { useSubscriptionsPlans, useSubscriptionsIndividual } from '../../../hooks/super_admin';

// ---------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'reference', label: 'Reference ID', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'type', label: 'Type', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'amount', label: 'Amount', alignRight: false }
];

const TransactionsSubscriptions = () => {
  const { searchedSubscriptionTransactions } = useSelector((state) => state.firestore.ordered);
  const { requesting } = useSelector((state) => state.firestore.status);
  const { subscriptionTransactionsCount } = useSelector((state) => state.firebase.profile);

  // ---------------------------------------------------------------------

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('firstName');
  const [filterName, setFilterName] = useState('');
  const [cursor, setCursor] = useState(undefined);
  const subscriptionsPlans = useSubscriptionsPlans();
  const subscriptionsIndividual = useSubscriptionsIndividual();
  const subscriptionTransactions = useTransactionsSubscriptions({
    startAfter: cursor,
    limit: rowsPerPage,
    filterName
  });

  // ---------------------------------------------------------------------

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setCursor(subscriptionTransactions.data[subscriptionTransactions.data.length - 1].createdAt);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const getSubscriptionName = (subscription) => {
    if (subscription.type === 'plan') {
      return subscriptionsPlans.data.find((plan) => plan.id === subscription.planId)?.name;
    }
    if (subscription.type === 'topup') {
      return subscriptionsIndividual.data.find(
        (individual) => individual.id === subscription.topupId
      )?.name;
    }
    return '';
  };

  // ---------------------------------------------------------------------

  useEffect(() => {
    if (requesting.searchedSubscriptionTransactions) {
      if (page) setPage(0);
      else setPage(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requesting.searchedSubscriptionTransactions]);

  // ---------------------------------------------------------------------

  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * rowsPerPage - filterName
            ? searchedSubscriptionTransactions?.length
            : subscriptionTransactions.data?.length
        )
      : 0;

  return (
    <Page title="Transaction | Subscriptions">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Subscriptions History
          </Typography>
        </Stack>

        <Card>
          {!!subscriptionTransactions.data.length && (
            <ListToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
              requesting={requesting.searchedSubscriptionTransactions}
            />
          )}

          {subscriptionTransactions.loading || requesting.searchedSubscriptionTransactions ? (
            <Box
              sx={{
                height: '60vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgress size={55} />
            </Box>
          ) : (
            <>
              {!subscriptionTransactions.data.length ? (
                <Box
                  sx={{
                    height: '60vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    No transactions found
                  </Typography>
                </Box>
              ) : (
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <ListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={
                          filterName
                            ? searchedSubscriptionTransactions?.length || 0
                            : subscriptionTransactionsCount || 0
                        }
                        onRequestSort={handleRequestSort}
                      />
                      <TableBody>
                        {(filterName
                          ? searchedSubscriptionTransactions || subscriptionTransactions.data
                          : subscriptionTransactions.data
                        )
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            const { amount, createdAt, planId, topupId, reference, status, type } =
                              row;

                            return (
                              <TableRow key={row.id} tabIndex={-1}>
                                <TableCell>
                                  <Typography variant="subtitle2" noWrap>
                                    {rowsPerPage * (page + 1) + (index + 1) - rowsPerPage}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">{reference || 'N/A'}</TableCell>
                                <TableCell align="left">
                                  <Typography variant="subtitle2">
                                    {createdAt ? format(createdAt.toDate(), 'dd MMM yyyy') : 'N/A'}
                                  </Typography>
                                  <Typography variant="body2">
                                    {createdAt ? format(createdAt.toDate(), 'p') : 'N/A'}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Label
                                    sx={{ cursor: 'pointer' }}
                                    variant="ghost"
                                    color={
                                      (status === 'failed' && 'error') ||
                                      (status === 'pending' && 'warning') ||
                                      'success'
                                    }
                                  >
                                    {sentenceCase(status)}
                                  </Label>
                                </TableCell>
                                <TableCell align="left">{type || 'N/A'}</TableCell>
                                <TableCell align="left">
                                  {getSubscriptionName(row) || 'N/A'}
                                </TableCell>
                                <TableCell align="left">₵{amount || 'N/A'}</TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      {isEmpty(searchedSubscriptionTransactions) && filterName ? (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : null}
                    </Table>
                  </TableContainer>
                </Scrollbar>
              )}
            </>
          )}

          {!requesting.searchedSubscriptionTransactions &&
            !!subscriptionTransactions.data.length && (
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={
                  filterName
                    ? searchedSubscriptionTransactions?.length || 0
                    : subscriptionTransactionsCount || 0
                }
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
        </Card>
      </Container>
    </Page>
  );
};

export default TransactionsSubscriptions;
