import PropTypes from 'prop-types';
// form
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
// @mui
import { useSelector } from 'react-redux';
import { Stack, Divider, Typography, Button } from '@mui/material';
// hooks
import useResponsive from '../../../../hooks/useResponsive';
import useToggle from '../../../../hooks/useToggle';
// _mock
// import { _invoiceAddressFrom, _invoiceAddressTo } from '../../../../_mock';
// components
import Iconify from '../../../../components/Iconify';
//
import InvoiceAddressListDialog from './InvoiceAddressListDialog';
import { useCustomers } from '../../../../hooks/owner';

// ----------------------------------------------------------------------

export default function InvoiceNewEditAddress() {
  const {
    watch,
    setValue,
  } = useFormContext();

  const allCustomers = useCustomers({
    startAfter: undefined,
    limit: 1000,
    filterName: null
  });

  const currentUser = useSelector((state) => state.firebase.profile);

  // const [customers, setCustomers] = useState(null);

  const upMd = useResponsive('up', 'md');

  const values = watch();

  const { toggle: openTo, onOpen: onOpenTo, onClose: onCloseTo } = useToggle();

  const { invoiceTo } = values;

  const { id } = useParams();

  const toInvoice = allCustomers.data.find(customer=>customer.customerId === id);

  return !allCustomers.loading ? (
    <Stack
      spacing={{ xs: 2, md: 5 }}
      direction={{ xs: 'column', md: 'row' }}
      divider={<Divider flexItem orientation={upMd ? 'vertical' : 'horizontal'} sx={{ borderStyle: 'dashed' }} />}
      sx={{ p: 3 }}
    >
      <Stack sx={{ width: 1 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 1 }}>
          <Typography variant="h6" sx={{ color: 'text.disabled' }}>
            From:
          </Typography>

        </Stack>

        <AddressInfo name={currentUser.companyName} address={currentUser.address} phone={currentUser.phoneNumber} />
      </Stack>

      <Stack sx={{ width: 1 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 1 }}>
          <Typography variant="h6" sx={{ color: 'text.disabled' }}>
            To:
          </Typography>

          <Button
            size="small"
            startIcon={<Iconify icon={invoiceTo || toInvoice? 'eva:edit-fill' : 'eva:plus-fill'} />}
            onClick={onOpenTo}
          >
            {invoiceTo || toInvoice? 'Change' : 'Add'}
          </Button>

          <InvoiceAddressListDialog
            open={openTo}
            onClose={onCloseTo}
            selected={(selectedId) => invoiceTo?.id === selectedId || toInvoice?.id === selectedId}
            onSelect={(address) => setValue('invoiceTo', address)}
            addressOptions={allCustomers.data}
          />
        </Stack>

        {invoiceTo ? (
          <AddressInfo name={invoiceTo.customerData.firstName} address={invoiceTo.customerData.address} phone={invoiceTo.customerData.phoneNumber} />
        ) : (
          <AddressInfo name={toInvoice !== undefined ? toInvoice.customerData.firstName:null} address={toInvoice !== undefined ? toInvoice.customerData.address:null} phone={toInvoice !== undefined ? toInvoice.customerData.phoneNumber:null} />
        )}
      </Stack>
    </Stack>
  ):null;
}

// ----------------------------------------------------------------------

AddressInfo.propTypes = {
  address: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.string,
};

function AddressInfo({ name, address, phone }) {
  return (
    <>
      <Typography variant="subtitle2">{name}</Typography>
      <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
        {address}
      </Typography>
      <Typography variant="body2">Phone: {phone}</Typography>
    </>
  );
}
