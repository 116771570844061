import PropTypes from 'prop-types';
import { Page, View, Text, Document } from '@react-pdf/renderer';
import { format } from 'date-fns';
// utils
import { fCurrency } from '../../../../utils/formatNumber';
import { fDateTime } from '../../../../utils/formatTime';
//
import styles from './InvoiceStyle';

// ----------------------------------------------------------------------

InvoicePDF.propTypes = {
  invoice: PropTypes.object.isRequired,
  // invoicePayments: PropTypes.object.isRequired,
};

export default function InvoicePDF({ invoice }) {

  const {
    id,
    items,
    taxes,
    status,
    discount,
    invoiceFrom,
    invoiceTo,
    createDate,
    totalPrice,
    paidAmount,
  } = invoice;

  const currentDate = new Date()

  // const { firstName, lastName, companyName, phoneNumber, address } = useSelector((state) => state.firebase.profile);


  // if (invoicePayments === undefined) return null;
  // console.log("whahahahha=====>", invoicePayments);

  return (
    // <Document>
    //   <Page size="A6" style={styles.page}>
    //     {/* <View style={[styles.gridContainer, styles.mb40]}>
    //       <Image source="https://www.zebra.com/us/en/products/software/barcode-printers/link-os/jcr:content/mainpar/fullwidthimage_37f6/fullWidthImage.transform/image-full/image.jpg" style={{ height: 32 }} />
    //     </View> */}

    //     <View style={[styles.gridContainer, styles.mb6, styles.customer]}>
    //       <View style={styles.col6}>
    //         <Text style={styles.body1}>Company</Text>
    //         <Text style={styles.body1}>Location</Text>
    //         <Text style={styles.body1}>Contact Number</Text>
    //         <Text style={styles.body1}>Customer</Text>
    //         {/* <Text style={styles.body1}>Date</Text> */}
    //       </View>

    //       <View style={styles.col6}>
    //         <Text style={styles.body1}> {invoiceFrom.companyName}</Text>
    //         <Text style={styles.body1}> {invoiceFrom.address}</Text>
    //         <Text style={styles.body1}> {invoiceFrom.phoneNumber}</Text>
    //         <Text style={styles.body1}> {invoiceTo.customerData.firstName} {invoiceTo.customerData.lastName}</Text>
    //         {/* <Text style={styles.body1}> {fDateTime(createDate.seconds*1000)}</Text> */}
    //       </View>
    //     </View>


    //     <View style={styles.table}>
    //       <View style={styles.tableHeader}>
    //         <View style={styles.tableRow}>
    //           {/* <View style={styles.tableCell_1}>
    //             <Text style={styles.subtitle2}>#</Text>
    //           </View> */}

    //           <View style={styles.tableCell_2}>
    //             <Text style={styles.subtitle2}>Name</Text>
    //           </View>

    //           <View style={styles.tableCell_3}>
    //             <Text style={styles.subtitle2}>Qty</Text>
    //           </View>

    //           <View style={[styles.tableCell_3, styles.alignRight]}>
    //             <Text style={styles.subtitle2}>SubTotal</Text>
    //           </View>

    //         </View>
    //       </View>

    //       <View style={styles.tableBody}>
    //         {items.map((item, index) => (
    //           <View style={styles.tableRow} key={item.id}>
    //             {/* <View style={styles.tableCell_1}>
    //               <Text>{index + 1}</Text>
    //             </View> */}

    //             <View style={styles.tableCell_2}>
    //               <Text>{item.service}</Text>
    //             </View>

    //             <View style={styles.tableCell_3}>
    //               <Text>{item.quantity}</Text>
    //             </View>

    //             <View style={[styles.tableCell_3, styles.alignRight]}>
    //               <Text>₵{fCurrency(item.price * item.quantity)}</Text>
    //             </View>
    //           </View>
    //         ))}

    //     <View style={[styles.gridContainer, styles.total]}>
    //       <View style={styles.col6}>
    //         <Text style={styles.body1}>Total Tax</Text>
    //         <Text style={styles.body1}>Total Discount</Text>
    //         <Text style={styles.body1}>Total Paid</Text>
    //         <Text style={styles.body1}>Total </Text>
    //       </View>

    //       <View style={styles.col6}>
    //         <Text style={styles.body1}> ₵{fCurrency(taxes||0)}</Text>
    //         <Text style={styles.body1}> ₵{fCurrency(discount||0)}</Text>
    //         <Text style={styles.body1}> ₵{fCurrency(paidAmount||0)}</Text>
    //         <Text style={styles.body1}> ₵{fCurrency(totalPrice-taxes-discount-paidAmount)}</Text>
    //       </View>


    //     </View>
    //     <View style={[styles.col12, styles.greeting]}>
    //         <Text>Thank You for Choosing us!</Text>
    //      </View>

    //      <View style={styles.status}>
    //           <Text>{status==='partial'?'Partial Paid':status}</Text>
    //       </View>
    //       </View>
    //     </View>



    //   </Page>
    // </Document>

    <Document>
    <Page size={{width: '302.36px', height: '100%'}} style={styles.page}>
      <View style={[styles.gridContainer, styles.mb10]}>
        <View style={styles.col12}>
          <Text style={[styles.overline, styles.body1, styles.mb2]}>{invoiceFrom.companyName}</Text>
          <Text style={styles.body1}>{invoiceFrom.address}</Text>
          <Text style={styles.body1}>{invoiceFrom.phoneNumber}</Text>
        </View>
      </View>
      <View style={[styles.gridContainer, styles.mb10]}>
        <View style={styles.col12}>
          <Text style={[styles.overline, styles.mb2]}>Customer</Text>
          <Text style={styles.body1}>{invoiceTo.customerData.firstName} {invoiceTo.customerData.lastName}</Text>
        </View>
      </View>

      <View style={styles.table}>
        { items && items.length ? (
          <View style={styles.tableHeader}>
          <View style={styles.tableRow}>
            <View style={styles.tableCell_1}>
              <Text style={styles.subtitle2}>#</Text>
            </View>
            <View style={styles.tableCell_1} />
            <View style={styles.tableCell_2}>
              <Text style={styles.subtitle2}>Name</Text>
            </View>
            <View style={styles.tableCell_1} />
            <View style={[styles.tableCell_1, styles.alignRight]}>
              <Text style={styles.subtitle2}>Qty</Text>
            </View>
            <View style={styles.tableCell_1} />
            <View style={[styles.tableCell_2, styles.alignRight]}>
              <Text style={styles.subtitle2}>Unit Price</Text>
            </View>
            <View style={[styles.tableCell_2, styles.alignRight]}>
              <Text style={styles.subtitle2}>Cost</Text>
            </View>
          </View>
        </View>) : null
        }
        <View style={styles.tableBody}>
          {
            items && (items || []).map(({service, quantity, price}, index) => (
              <View key={index} style={[styles.tableRow]}>
                <View style={styles.tableCell_1}>
                  <Text style={styles.subtitle2}>{index + 1}</Text>
                </View>
                <View style={styles.tableCell_1} />
                <View style={styles.tableCell_2}>
                  <Text>{service}</Text>
                </View>
                <View style={styles.tableCell_1} />
                <View style={[styles.tableCell_1, styles.alignRight]}>
                  <Text style>{quantity}</Text>
                </View>
                <View style={styles.tableCell_1} />
                <View style={[styles.tableCell_2, styles.alignRight]}>
                  <Text>GHS {fCurrency(price||0)}</Text>
                </View>
                <View style={[styles.tableCell_2, styles.alignRight]}>
                  <Text>GHS {fCurrency(quantity * price||0)}</Text>
                </View>
              </View>
            ))
          }
          <View style={[styles.tableRow, styles.noBorder]}>
            <View style={styles.tableCell_2} />
            <View style={styles.tableCell_2}>
              <Text>Taxes</Text>
            </View>
            <View style={[styles.tableCell_2, styles.alignRight]}>
              <Text>GHS {fCurrency(taxes||0)}</Text>
            </View>
          </View>
          <View style={[styles.tableRow, styles.noBorder]}>
            <View style={styles.tableCell_2} />
            <View style={styles.tableCell_2}>
              <Text>Discount</Text>
            </View>
            <View style={[styles.tableCell_2, styles.alignRight]}>
              <Text>GHS {fCurrency(discount||0)}</Text>
            </View>
          </View>
          <View style={[styles.tableRow, styles.noBorder]}>
            <View style={styles.tableCell_2} />
            <View style={styles.tableCell_2}>
              <Text>Amount Paid</Text>
            </View>
            <View style={[styles.tableCell_2, styles.alignRight]}>
              <Text>GHS {fCurrency(paidAmount||0)}</Text>
            </View>
          </View>
          <View style={[styles.tableRow, styles.noBorder]}>
            <View style={styles.tableCell_2} />
            <View style={[styles.tableCell_2, styles.subtitle2]}>
              <Text>Total Cost</Text>
            </View>
            <View style={[styles.tableCell_2, styles.alignRight]}>
              <Text style={styles.subtitle2}>GHS {fCurrency(totalPrice-taxes-discount-paidAmount)}</Text>
            </View>
          </View>
        </View>
      </View>

      <View style={[styles.gridContainer, styles.footer]}>
        <View style={[styles.col6]}>
          <Text>{format(currentDate, 'dd MMM yyyy')} {format(currentDate, 'p')}</Text>
        </View>
        <View style={[styles.col6, styles.alignRight]}>
          <Text>Thank you for choosing us!</Text>
        </View>
      </View>
    </Page>
  </Document>
  );
}
