import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import IconButton from '@mui/material/IconButton';

StockViewDialog.propTypes = {
  isOpen: PropTypes.bool,
  stock: PropTypes.object,
  setIsOpen: PropTypes.func,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func
};

// --------------------------------------------------

const ImageListStyles = styled(ImageList)({
  position: 'relative',
  listStyle: 'none',
  // marginTop: '12px',
  padding: '0px',
  display: 'flex'
});

const ThumbBoxStyles = styled(Box)({
  top: '0px',
  left: '0px',
  right: '0px',
  bottom: '0px',
  lineHeight: 0,
  position: 'absolute',
  backgroundSize: 'cover !important'
});

const ImageListItemStyles = styled(ImageListItem)({
  justifyContent: 'flex-start',
  alignItems: 'center',
  boxSizing: 'border-box',
  textAlign: 'left',
  minWidth: '80px !important',
  height: '80px !important',
  position: 'relative',
  display: 'inline-flex',
  textDecoration: 'none',
  padding: '0px',
  margin: '4px',
  borderRadius: '10px',
  overflow: 'hidden',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'rgba(145, 158, 171, 0.24)',
  borderImage: 'initial'
});

const imgStyles = {
  width: '100%',
  height: '100%',
  objectFit: 'cover'
};

// --------------------------------------------------

export default function StockViewDialog({ stock, isOpen, setIsOpen, handleEdit, handleDelete }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = React.useCallback(() => {
    setOpen(false);
    setIsOpen(false);
  }, [setIsOpen]);

  const handleDeleteClick = () => {
    handleClose();
    handleDelete();
  };

  const handleEditClick = () => {
    handleClose();
    handleEdit();
  };

  // --------------------------------------------------

  React.useEffect(() => {
    if (isOpen) handleOpen();
    else handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClose, isOpen]);

  // --------------------------------------------------

  const fabricsThumb = stock?.fabrics?.map((file) => (
    <ImageListItemStyles key={file.name || file}>
      <ThumbBoxStyles>
        <img
          src={file.preview || file}
          style={imgStyles}
          srcSet={file.preview || file}
          alt="preview"
          loading="lazy"
        />
      </ThumbBoxStyles>
    </ImageListItemStyles>
  ));

  const {
    category,
    gender,
    size,
    itemName,
    costPrice,
    salesPrice,
    storeQuantity,
    alertQuantity,
    tax,
    vat,
    nhis,
    isShop,
    fabrics
  } = stock;
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {/* {title} */}
          View Stock
          <IconButton
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Icon icon={closeFill} width={24} height={24} />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12}>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Typography variant="subtitle1" component="p">
                      Category
                    </Typography>
                    <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                      {category}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Typography variant="subtitle1" component="p">
                      Item Name
                    </Typography>
                    <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                      {itemName || 'N/A'}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Typography variant="subtitle1" component="p">
                      Gender
                    </Typography>
                    <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                      {gender}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Typography variant="subtitle1" component="p">
                      Size
                    </Typography>
                    <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                      {size || 'N/A'}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Typography variant="subtitle1" component="p">
                      Cost Price
                    </Typography>
                    <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                      {costPrice ? `₵ ${costPrice}` : 'N/A'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Typography variant="subtitle1" component="p">
                      Sales Price
                    </Typography>
                    <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                      {salesPrice ? `₵ ${salesPrice}` : 'N/A'}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Typography variant="subtitle1" component="p">
                      Store Quantity
                    </Typography>
                    <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                      {storeQuantity ? `${storeQuantity}` : 'N/A'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Typography variant="subtitle1" component="p">
                      Alert Quantity
                    </Typography>
                    <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                      {alertQuantity ? `${alertQuantity}` : 'N/A'}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Typography variant="subtitle1" component="p">
                      Tax
                    </Typography>
                    <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                      {tax ? `${tax}` : 'N/A'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Typography variant="subtitle1" component="p">
                      Vat
                    </Typography>
                    <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                      {vat ? `${vat}` : 'N/A'}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Typography variant="subtitle1" component="p">
                      NHIS
                    </Typography>
                    <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                      {nhis ? `${nhis}` : 'N/A'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Typography variant="subtitle1" component="p">
                      Shop
                    </Typography>
                    <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                      {isShop ? `${isShop}` : 'N/A'}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              {!!fabrics.length && (
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle1" component="p">
                    Fabrics
                  </Typography>
                  <ImageListStyles>{fabricsThumb}</ImageListStyles>
                </Box>
              )}
              {/*
              {!!styles.length && (
                <Box>
                  <Typography variant="subtitle1" component="p">
                    Styles
                  </Typography>
                  <ImageListStyles>{stylesThumb}</ImageListStyles>
                </Box>
              )} */}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClick} color="error">
            Delete
          </Button>
          <Button disabled={!stock} onClick={handleEditClick} autoFocus>
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
