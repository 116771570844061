import closeFill from '@iconify/icons-eva/close-fill';
import { Icon } from '@iconify/react';
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import { parseMonthCount } from '../../../../utils/formatTime';
import { useSubscriptionsCategories } from '../../../../hooks/super_admin';

// --------------------------------------------------

SewViewDialog.propTypes = {
  isOpen: PropTypes.bool,
  plan: PropTypes.object,
  setIsOpen: PropTypes.func
};

// ------------------------------------------------------------------------

export default function SewViewDialog({ plan, isOpen, setIsOpen }) {
  const [open, setOpen] = React.useState(false);

  const categories = useSubscriptionsCategories();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = React.useCallback(() => {
    setOpen(false);
    setIsOpen(false);
  }, [setIsOpen]);

  const getCategoryName = (categoryId) => {
    if (!categories.data.length) return '';
    const category = categories.data.find((item) => item.identifier === categoryId);
    return category ? category.name : '';
  };

  // --------------------------------------------------

  React.useEffect(() => {
    if (isOpen) handleOpen();
    else handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClose, isOpen]);

  // --------------------------------------------------
  const { name, price, plans } = plan;
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          Plan
          <IconButton
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Icon icon={closeFill} width={24} height={24} />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Name
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {name || 'N/A'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Price
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {price ? `₵${price}` : 'N/A'}
                </Typography>
              </Box>
              <Grid container spacing={1} sx={{ mb: 1 }}>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component="p">
                    Category
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1" component="p">
                    Quantity
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1" component="p">
                    Duration
                  </Typography>
                </Grid>
              </Grid>
              {plans.map(({ category, quantity, duration }, index) => (
                <Grid container key={index} spacing={1} sx={{ mb: 1.5 }}>
                  <Grid item xs={6}>
                    <Typography variant="body2" component="p">
                      {getCategoryName(category)}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body2" component="p">
                      {quantity || '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body2" component="p">
                      {duration ? parseMonthCount(duration) : '-'}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
