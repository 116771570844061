export const imagesUrlArrayGet = async (type, firebase, sewingId, previewFiles) => {
  try {
    let ref;

    if (type === 'fabric') {
      ref = firebase.storage().refFromURL(`gs://fashionpalfabrics/${sewingId}`);
    }

    if (type === 'style') {
      ref = firebase.storage().refFromURL(`gs://fashionpalstyles/${sewingId}`);
    }

    if (type === 'profile') {
      ref = firebase.storage().refFromURL(`gs://fashionsgh-84af1.appspot.com/${sewingId}`);
      if (previewFiles instanceof File) {
        const response = await ref
          .child(previewFiles.name)
          .put(previewFiles, { customMetadata: { uid: firebase.auth().currentUser.uid } });
        return response.ref.getDownloadURL();
      }
      return previewFiles;
    }

    const files = Array.from(previewFiles);
    const promises = files.map(async (file) => {
      if (file instanceof File) {
        const response = await ref
          .child(file.name)
          .put(file, { customMetadata: { uid: firebase.auth().currentUser.uid } });
        return response.ref.getDownloadURL();
      }
      return file;
    });
    return Promise.all(promises);
  } catch (error) {
    throw new Error(error.message);
  }
};
