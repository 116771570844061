import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty, useFirestore } from 'react-redux-firebase';
import { format } from 'date-fns';
import plusFill from '@iconify/icons-eva/plus-fill';
import eyeOutlined from '@iconify/icons-ant-design/eye-outlined';
// material
import {
  Box,
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress
} from '@mui/material';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { AddCustomerDialog, AddSewDialog } from '../../components/_adds/owner';
import { ListHead, ListToolbar } from '../../components/_dashboard/owner/@general';
import {
  CustomersDeleteDialog,
  CustomersViewDialog,
  CustomersEmpty
} from '../../components/_dashboard/owner/customers';
import { checkForRole } from '../../utils/checkAuthUserRole';

// import { condenseText } from '../../utils/formatText';

import { useCustomers } from '../../hooks/owner';
import { PATH_DASHBOARD } from '../../routes/paths';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'contact', label: 'Contact', alignRight: false },
  { id: 'sex', label: 'Sex', alignRight: false },
  { id: 'createdOn', label: 'Created On', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

export default function Customers() {
  const { searchedCustomers } = useSelector((state) => state.firestore.ordered);
  const { requesting } = useSelector((state) => state.firestore.status);
  const { customersCount, token, ownerId } = useSelector((state) => state.firebase.profile);
  const firestore = useFirestore();

  // ----------------------------------------------------------------------

  const { identifier, permissions } = token && checkForRole(token);

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('firstName');
  const [filterName, setFilterName] = useState('');
  const [customersNum, setCustomersNum] = useState(0);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isCustomerEditOpen, setIsCustomerEditOpen] = useState(false);
  const [customerOpen, setCustomerOpen] = useState(false);
  const [isCustomerDeleteOpen, setIsCustomerDeleteOpen] = useState(false);
  const [isAddSewOpen, setIsAddSewOpen] = useState(false);
  const [cursor, setCursor] = useState(undefined);
  const allCustomers = useCustomers({
    startAfter: cursor,
    limit: rowsPerPage,
    filterName
  });

  // -----------------------------------------------------------------------
  const navigate = useNavigate();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setCursor(allCustomers.data[allCustomers.data.length - 1].customerData.createdAt);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleDelete = (item) => {
    setIsCustomerDeleteOpen(true);
    setSelectedCustomer(item);
  };

  const handleEdit = (item) => {
    setIsCustomerEditOpen(true);
    setSelectedCustomer(item);
  };

  const handleAddSew = (e, item) => {
    e.stopPropagation();
    setIsAddSewOpen(true);
    setSelectedCustomer(item);
  };

  const handleAddInvoice = (e, item) => {
    e.stopPropagation();
    // dispatch(invoiceDelete());
    navigate(PATH_DASHBOARD.invoice.edit(item.customerId));
  };

  const handleCustomerClick = (item) => {
    setSelectedCustomer(item);
    setCustomerOpen(true);
  };

  // ----------------------------------------------------------------------

  useEffect(() => {
    if (!isCustomerEditOpen && !isCustomerDeleteOpen && !isAddSewOpen && !customerOpen) {
      setSelectedCustomer(null);
    }
  }, [isCustomerEditOpen, isCustomerDeleteOpen, isAddSewOpen, customerOpen]);

  useEffect(() => {
    if (requesting.searchedCustomers) {
      if (page) setPage(0);
      else setPage(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requesting.searchedCustomers]);

  useEffect(() => {
    const getOwner = async () => {
      const owner = await firestore.collection('users').doc(ownerId).get()
      setCustomersNum(owner.data().customersCount)
    }

    if(identifier === 'staff') getOwner()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // -----------------------------------------------------------------

  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * rowsPerPage - filterName
            ? searchedCustomers?.length
            : allCustomers.data?.length
        )
      : 0;

  return (
    <Page title="Customers | FashionsPal">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Customers
          </Typography>
          <AddCustomerDialog
            isCustomerOpen={isCustomerEditOpen}
            setIsCustomerOpen={setIsCustomerEditOpen}
            selectedCustomer={selectedCustomer}
          >
            <Button variant="contained" component="div" startIcon={<Icon icon={plusFill} />}>
              Add Customer
            </Button>
          </AddCustomerDialog>
        </Stack>

        <Card>
          {!!allCustomers.data.length && (
            <ListToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
              requesting={requesting.searchedCustomers}
            />
          )}

          {allCustomers.loading || requesting.searchedCustomers ? (
            <Box
              sx={{
                height: '60vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgress size={55} />
            </Box>
          ) : (
            <>
              {!allCustomers.data.length ? (
                <CustomersEmpty />
              ) : (
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <ListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={filterName ? searchedCustomers?.length || 0 : customersCount || 0}
                        onRequestSort={handleRequestSort}
                      />
                      <TableBody>
                        {(filterName ? searchedCustomers || allCustomers.data : allCustomers.data)
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            const { firstName, lastName, phoneNumber, sex, createdAt } =
                              row.customerData;

                            return (
                              <TableRow
                                key={row.customerId}
                                sx={{ cursor: 'pointer' }}
                                tabIndex={-1}
                                onClick={() => handleCustomerClick(row)}
                              >
                                <TableCell>
                                  <Typography variant="subtitle2" noWrap>
                                    {rowsPerPage * (page + 1) + (index + 1) - rowsPerPage}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  {`${firstName} ${lastName}` || 'N/A'}
                                </TableCell>
                                <TableCell align="left">{phoneNumber || 'N/A'}</TableCell>
                                <TableCell align="left">{sex || 'N/A'}</TableCell>
                                <TableCell align="left">
                                  <Typography variant="subtitle2">
                                    {createdAt ? format(createdAt.toDate(), 'dd MMM yyyy') : 'N/A'}
                                  </Typography>
                                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    {createdAt ? format(createdAt.toDate(), 'p') : 'N/A'}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
                                  <Button
                                    variant="contained"
                                    component="div"
                                    startIcon={<Icon icon={eyeOutlined} />}
                                    onClick={() => navigate(`/dashboard/sewings/${row.customerId}/${row.customerData.searchMatch}`)}
                                    sx={{ mr: 1.5 }}
                                  >
                                    View Orders
                                  </Button>
                                  <Button
                                    variant="contained"
                                    component="div"
                                    startIcon={<Icon icon={plusFill} />}
                                    onClick={(e) => handleAddSew(e, row)}
                                    sx={{ mr: 1.5 }}
                                  >
                                    Add Order
                                  </Button>
                                  {
                                    (identifier === 'owner' || identifier === 'staff' && permissions.includes(2)) ?
                                    <Button
                                      variant="contained"
                                      component="div"
                                      startIcon={<Icon icon={plusFill} />}
                                      onClick={(e) => handleAddInvoice(e, row)}
                                    >
                                      New Invoice
                                    </Button> : null
                                  }
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      {isEmpty(searchedCustomers) && filterName ? (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : null}
                    </Table>
                  </TableContainer>
                </Scrollbar>
              )}
            </>
          )}

          {!requesting.searchedCustomers && !!allCustomers.data.length && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filterName ? searchedCustomers?.length || 0 : customersNum || customersCount || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>

        {/* ----- Dialog Start ----- */}
        {isCustomerDeleteOpen && (
          <CustomersDeleteDialog
            isOpen={isCustomerDeleteOpen}
            setIsOpen={setIsCustomerDeleteOpen}
            selectedCustomer={selectedCustomer}
          />
        )}
        <AddSewDialog
          isSewOpen={isAddSewOpen}
          setIsSewOpen={setIsAddSewOpen}
          selectedCustomer={selectedCustomer}
        />
        {customerOpen && (
          <CustomersViewDialog
            isOpen={customerOpen}
            setIsOpen={setCustomerOpen}
            customer={selectedCustomer}
            handleEdit={() => handleEdit(selectedCustomer)}
            handleDelete={() => handleDelete(selectedCustomer)}
          />
        )}
        {/* ----- Dialog End ----- */}
      </Container>
    </Page>
  );
}
