import * as React from 'react';
import PropTypes from 'prop-types';
import { PDFViewer } from '@react-pdf/renderer';
import { useSelector } from 'react-redux';
import { sentenceCase } from 'change-case';
import { styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import { format } from 'date-fns';
import closeFill from '@iconify/icons-eva/close-fill';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

import InvoicePDF from "./invoice/InvoicePDF";

import { checkForRole } from '../../../../utils/checkAuthUserRole';
import Label from '../../../Label';

// --------------------------------------------------

SewViewDialog.propTypes = {
  isOpen: PropTypes.bool,
  sew: PropTypes.object,
  setIsOpen: PropTypes.func,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func
};

// --------------------------------------------------

const ImageListStyles = styled(ImageList)({
  position: 'relative',
  listStyle: 'none',
  // marginTop: '12px',
  padding: '0px',
  display: 'flex'
});

const ThumbBoxStyles = styled(Box)({
  top: '0px',
  left: '0px',
  right: '0px',
  bottom: '0px',
  lineHeight: 0,
  position: 'absolute',
  backgroundSize: 'cover !important'
});

const ImageListItemStyles = styled(ImageListItem)({
  justifyContent: 'flex-start',
  alignItems: 'center',
  boxSizing: 'border-box',
  textAlign: 'left',
  minWidth: '80px !important',
  height: '80px !important',
  position: 'relative',
  display: 'inline-flex',
  textDecoration: 'none',
  padding: '0px',
  margin: '4px',
  borderRadius: '10px',
  overflow: 'hidden',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'rgba(145, 158, 171, 0.24)',
  borderImage: 'initial'
});

const imgStyles = {
  width: '100%',
  height: '100%',
  objectFit: 'cover'
};

// --------------------------------------------------

export default function SewViewDialog({ sew, isOpen, setIsOpen, handleEdit, handleDelete }) {
  const [open, setOpen] = React.useState(false);
  const [openPrint, setOpenPrint] = React.useState(false);

  const { token, city, companyName, phoneNumber } = useSelector((state) => state.firebase.profile);
  const currUserId = useSelector((state) => state.firebase.auth.uid);
  const { measurementsParentItems } = useSelector((state) => state.firestore.ordered);

  const { identifier } = token && checkForRole(token);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = React.useCallback(() => {
    setOpen(false);
    setIsOpen(false);
  }, [setIsOpen]);

  const handleDeleteClick = () => {
    handleClose();
    handleDelete();
  };

  const handleEditClick = () => {
    handleClose();
    handleEdit();
  };

  const getParentName = (parentId) => {
    if (!measurementsParentItems.length) return '';
    const parent = measurementsParentItems.find((item) => item.id === parentId);
    return parent ? parent.parentTitle : parentId;
  };

  const disableActions = !(identifier === 'admin' || identifier === 'owner' || identifier === 'staff' && sew?.staffId === currUserId);

  // --------------------------------------------------

  React.useEffect(() => {
    if (isOpen) handleOpen();
    else handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClose, isOpen]);

  // --------------------------------------------------

  const fabricsThumb = sew.sewingData.fabrics.map((file) => (
    <ImageListItemStyles key={file.name || file}>
      <ThumbBoxStyles>
        <img
          src={file.preview || file}
          style={imgStyles}
          srcSet={file.preview || file}
          alt="preview"
          loading="lazy"
        />
      </ThumbBoxStyles>
    </ImageListItemStyles>
  ));

  const stylesThumb = sew.sewingData.styles.map((file) => (
    <ImageListItemStyles key={file.name || file}>
      <ThumbBoxStyles>
        <img
          src={file.preview || file}
          style={imgStyles}
          srcSet={file.preview || file}
          alt="preview"
          loading="lazy"
        />
      </ThumbBoxStyles>
    </ImageListItemStyles>
  ));

  // --------------------------------------------------
  const {
    title,
    customerName,
    staffName,
    description,
    cost,
    amountPaid,
    status,
    duration,
    fabrics,
    styles,
    measurements,
    createdAt,
    items
  } = sew.sewingData;
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {title}
          <IconButton
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Icon icon={closeFill} width={24} height={24} />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Customer Name
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {customerName}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Description
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {description || 'N/A'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Assigned To
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {staffName || 'N/A'}
                </Typography>
              </Box>
              {items && items.length ? 
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Typography variant="subtitle1" component="p">
                      Items
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Typography variant="subtitle1" component="p">
                      Cost
                    </Typography>
                  </Box>
                </Grid>
              </Grid> : null}
             {(items || []).map(({cost, name}, index) => (
              <Grid key={index} container>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <Typography variant="body2" component="p">
                        {name || 'N/A'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <Typography variant="body2" component="p">
                        {cost ? `₵${cost}` : 'N/A'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
             ))}
              <Grid container style={{ marginTop: '20px' }}>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Typography variant="subtitle1" component="p">
                      Amount Paid
                    </Typography>
                    <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                      {amountPaid ? `₵${amountPaid}` : 'N/A'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Typography variant="subtitle1" component="p">
                      Total Cost
                    </Typography>
                    <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                      {cost ? `₵${cost}` : 'N/A'}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Typography variant="subtitle1" component="p">
                      Status
                    </Typography>
                    <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                      <Label
                        variant="ghost"
                        color={
                          (status === 'ordered' && 'error') ||
                          ((status === 'completed' || status === 'delivered') && 'success') ||
                          'warning'
                        }
                      >
                        {sentenceCase(status)}
                      </Label>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Typography variant="subtitle1" component="p">
                      Duration
                    </Typography>
                    <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                      {`${duration ? `${duration} days` : 'N/A'}`}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box style={{ marginBottom: '20px' }}>
                  <Typography variant="subtitle1" component="p">
                    Created on
                  </Typography>
                  <Typography variant="body2">
                    {createdAt ? format(createdAt.toDate(), 'dd MMM yyyy') : 'N/A'}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {createdAt ? format(createdAt.toDate(), 'p') : 'N/A'}
                  </Typography>
                </Box>
              </Grid>
              {!!fabrics.length && (
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle1" component="p">
                    Fabrics
                  </Typography>
                  <ImageListStyles>{fabricsThumb}</ImageListStyles>
                </Box>
              )}
              {!!styles.length && (
                <Box>
                  <Typography variant="subtitle1" component="p">
                    Styles
                  </Typography>
                  <ImageListStyles>{stylesThumb}</ImageListStyles>
                </Box>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {Object.keys(measurements).map((key) => (
                <Grid key={key} item xs={12} sx={{ mb: 1.5 }}>
                  <Grid container>
                    <Typography variant="subtitle1" component="p">
                      {getParentName(key)}
                    </Typography>
                  </Grid>
                  <Grid container spacing={1} sx={{ mb: 4 }}>
                    {measurements[key].map((measurement, index) => (
                      <Grid key={index} item xs={12} sm={6}>
                        <Typography variant="subtitle2" component="p">
                          {Object.keys(measurement)[0]}
                        </Typography>
                        <Typography variant="body2" component="p">
                          {measurements[key][index][Object.keys(measurement)[0]] || 'n/a'}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClick} color="error" disabled={disableActions}>
            Delete
          </Button>
          <Button disabled={!sew.sewingData.customerData || disableActions} onClick={handleEditClick} autoFocus>
            Edit
          </Button>
          <Button onClick={() => setOpenPrint(true)}>Print</Button>
        </DialogActions>
      </Dialog>

      <Dialog fullScreen open={openPrint}>
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <DialogActions
            sx={{
              zIndex: 9,
              padding: '12px !important',
              boxShadow: (theme) => theme.customShadows.z8,
            }}
          >
          
            <IconButton color="inherit" onClick={() => setOpenPrint(false)}>
              <Icon icon={closeFill} />
            </IconButton>
          </DialogActions>

          <Box sx={{ flexGrow: 1, height: '100%', overflow: 'hidden' }}>
            <PDFViewer width="100%" height="100%" style={{ border: 'none' }}>
              <InvoicePDF invoice={sew.sewingData} owner={{city, companyName, phoneNumber}} />
            </PDFViewer>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}
