import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fPhoneNumberToEmail } from '../../../utils/formatPhoneNumber';
import { firebaseConfig } from '../../../config/firebase';

// _mock_ data
// import STAFF from '../../../_mocks_/staff';

export const addStaff = createAsyncThunk(
  'staff/add',
  async (staffData, { extra: { getFirestore, getFirebase } }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    const {
      phoneNumber,
      firstName,
      lastName,
      address,
      sex,
      birthday,
      country,
      city,
      isAccountEnabled,
      permissions,
      searchMatch
    } = staffData;

    const formattedPhoneNumberToEmail = fPhoneNumberToEmail(phoneNumber);
    const defaultPassword = '123456';

    const ownerId = firebase.auth().currentUser.uid;
    const timestamp = firestore.FieldValue.serverTimestamp();
    const rest = {
      role: 'staff',
      searchMatch,
      createdAt: timestamp,
      updatedAt: timestamp
    };
    const staffInfo = {
      phoneNumber,
      firstName,
      lastName,
      address,
      sex,
      birthday,
      country,
      city,
      ...rest
    };

    let staffId;
    let checkIfUserExists;
    const currPermissions = isAccountEnabled ? permissions : [];

    if (phoneNumber) {
      checkIfUserExists = await firebase
        .auth()
        .fetchSignInMethodsForEmail(formattedPhoneNumberToEmail);
    }

    if (phoneNumber && !(checkIfUserExists.length > 0)) {
      const secondaryApp = firebase.initializeApp(firebaseConfig, 'Secondary');
      const staffAuth = await secondaryApp
        .auth()
        .createUserWithEmailAndPassword(formattedPhoneNumberToEmail, defaultPassword);
      staffId = staffAuth.user.uid;

      await secondaryApp.auth().signOut();

      const addStaffRole = firebase.functions().httpsCallable('addStaffRole');
      await addStaffRole({ email: formattedPhoneNumberToEmail, permissions: currPermissions });

      await firestore.set(
        { collection: 'users', doc: staffId },
        { ...staffInfo, ...rest, isEnabled: true, ownerId }
      );
    }

    const staffRefId = firestore.collection('staff').doc().id;

    await firestore.set(
      { collection: 'staff', doc: staffRefId },
      {
        ownerId,
        staffId,
        staffData: {
          ...staffInfo,
          ...rest,
          isAccountEnabled,
          permissions: currPermissions,
          deletedAt: isAccountEnabled ? false : timestamp
        }
      }
    );
  }
);

// -------------------------------------------------------------------------------

export const updateStaff = createAsyncThunk(
  'staff/update',
  async (staff, { extra: { getFirestore, getFirebase } }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    const { phoneNumber, permissions } = staff.staffData;

    const formattedPhoneNumberToEmail = fPhoneNumberToEmail(phoneNumber);

    const addStaffRole = firebase.functions().httpsCallable('addStaffRole');
    await addStaffRole({ email: formattedPhoneNumberToEmail, permissions });

    await firestore.update(
      { collection: 'staff', doc: staff.id },
      {
        staffData: {
          ...staff.staffData,
          updatedAt: firestore.FieldValue.serverTimestamp()
        }
      }
    );
  }
);

// -------------------------------------------------------------------------------

export const changeStaffStatus = createAsyncThunk(
  'staff/changeStatus',
  async (staff, { extra: { getFirestore, getFirebase } }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    const { isAccountEnabled, phoneNumber, permissions } = staff.staffData;

    const formattedPhoneNumberToEmail = fPhoneNumberToEmail(phoneNumber);
    const timestamp = firestore.FieldValue.serverTimestamp();

    if (!isAccountEnabled) {
      const addStaffRole = firebase.functions().httpsCallable('addStaffRole');
      await addStaffRole({ email: formattedPhoneNumberToEmail, permissions: [] });
    }

    await firestore.update(
      { collection: 'staff', doc: staff.id },
      {
        staffData: {
          ...staff.staffData,
          deletedAt: isAccountEnabled ? false : timestamp,
          permissions: isAccountEnabled ? permissions : [],
          updatedAt: timestamp
        }
      }
    );
  }
);

const initialState = {
  error: null
};

const staffSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addStaff.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });

    // -----------------------------------------------------------------------

    builder.addCase(updateStaff.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });
  }
});

export default staffSlice.reducer;
