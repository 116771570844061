import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Button, Checkbox, TableRow, TableCell, Typography, Stack, Link, MenuItem, } from '@mui/material';
// utils
import { fDate } from '../../../../utils/formatTime';
import createAvatar from '../../../../utils/createAvatar';
import { fCurrency } from '../../../../utils/formatNumber';
// components
import Label from '../../../../components/Label';
import Avatar from '../../../../components/Avatar';
import Iconify from '../../../../components/Iconify';
import { TableMoreMenu } from '../../../../components/table';
import PaymentDialog from './PaymentDialog';
// ----------------------------------------------------------------------

InvoiceTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onSelectRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  setPay: PropTypes.func,
};

export default function InvoiceTableRow({ setPay, row, selected, onSelectRow, onViewRow, onEditRow, onDeleteRow }) {
  const theme = useTheme();
  const { id, createDate, dueDate, status, invoiceTo, totalPrice, taxes, discount } = row;

  // let totalPrice = 0;

  // items.map(item => {
  //   totalPrice += item.total;
  //   return 0;
  // });

  const [openMenu, setOpenMenuActions] = useState(null);
  const [Open, setOpen] = useState(false)

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };


  const handleClose = () => {
    //    setError(null);
          setOpen(false);
      // if (setIsOwnerOpen) setIsOwnerOpen(false);
  };
  const openDialog = () => {
    setOpen(true);
  }

  return (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>

      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar alt={invoiceTo.customerData.firstName} color={createAvatar(invoiceTo.customerData.firstName).color} sx={{ mr: 2 }}>
          {createAvatar(invoiceTo.customerData.firstName).name}
        </Avatar>

        <Stack>
          <Typography variant="subtitle2" noWrap>
            {invoiceTo.customerData.firstName}
          </Typography>

          <Link noWrap variant="body2" onClick={onViewRow} sx={{ color: 'text.disabled', cursor: 'pointer' }}>
            {`INV-${id}`}
          </Link>
        </Stack>
      </TableCell>

      <TableCell align="left">{createDate ? fDate(createDate?.seconds * 1000) : ''}</TableCell>

      <TableCell align="left">{dueDate ? fDate(dueDate?.seconds * 1000) : ''}</TableCell>

      <TableCell align="right">GHS {fCurrency(totalPrice-discount-taxes)}</TableCell>

      <TableCell align="center" sx={{ textTransform: 'capitalize' }}>
        <Button
          sx={{ px: 2, bgcolor: 'background.neutral' }}
          onClick={openDialog}
        >
          Pay
        </Button>
      </TableCell>

      <TableCell align="left">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={
            (dueDate && dueDate?.seconds*1000 < new Date().getTime() && status !=='paid' && 'error') ||
            (status === 'paid' && 'success') ||
            (status === 'unpaid' && 'warning') ||
            (status === 'partial' && 'primary') ||
            'default'
          }
          sx={{ textTransform: 'capitalize' }}
        >
          {dueDate && dueDate?.seconds*1000 < new Date().getTime() && status!=='paid' ? 'overdue':status}
        </Label>
      </TableCell>
      <PaymentDialog
        setPay = {setPay}
        totalPrice={totalPrice - discount - taxes}
        invoice={row}
        Open={Open}
        handleClose={handleClose}
      />
      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                onClick={() => {
                  onDeleteRow();
                  handleCloseMenu();
                }}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon={'eva:trash-2-outline'} />
                Delete
              </MenuItem>

              <MenuItem
                onClick={() => {
                  onViewRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:eye-fill'} />
                View
              </MenuItem>

              <MenuItem
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:edit-fill'} />
                Edit
              </MenuItem>
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
