import { configureStore } from '@reduxjs/toolkit';
import { getFirebase, firebaseReducer } from 'react-redux-firebase';
import {
  reduxFirestore,
  getFirestore,
  firestoreReducer,
  createFirestoreInstance
} from 'redux-firestore';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';

import { firebaseConfig } from '../config/firebase';

import auth from './slices/auth';
// owner
import customers from './slices/owner/customers';
import sewings from './slices/owner/sewings';
import staff from './slices/owner/staff';
import communication from './slices/owner/communication';
import subscriptions from './slices/owner/subscriptions';
import account from './slices/owner/account';
import categories from './slices/owner/categories';

// super_admin
import measurements from './slices/super_admin/measurements';
import owners from './slices/super_admin/owners';
import finances from './slices/super_admin/finances';

firebase.initializeApp(firebaseConfig);
firebase.firestore();
firebase.storage();
firebase.auth();
firebase.functions();

const store = configureStore({
  reducer: {
    auth,
    account,
    customers,
    finances,
    sewings,
    staff,
    measurements,
    communication,
    owners,
    subscriptions,
    firebase: firebaseReducer,
    firestore: firestoreReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: { getFirebase, getFirestore }
      },
      serializableCheck: false
    }),
  enhancers: [reduxFirestore(firebase)]
});

const rrfConfig = {
  userProfile: 'users', // where profiles are stored in database
  useFirestoreForProfile: true, // use Firestore for profile instead of RTDB
  enableClaims: true
};

export const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
};

export default store;
