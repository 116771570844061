import * as Yup from 'yup';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import { isEmpty } from 'react-redux-firebase';
import { useDispatch, useSelector } from 'react-redux';
import { sentenceCase } from 'change-case';
import plusFill from '@iconify/icons-eva/plus-fill';
import {
  Container,
  Stack,
  Typography,
  Card,
  Grid,
  TextField,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Button,
  TablePagination,
  CircularProgress,
  Box,
  Alert
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormik, Form, FormikProvider } from 'formik';

import {
  ListHead,
  ListToolbar,
  DeleteDialog,
  StatusDialog
} from '../../../components/_dashboard/super_admin/@general';

import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import Label from '../../../components/Label';

import { useExpenditureCategories } from '../../../hooks/super_admin';
import {
  addExpenditureCategory,
  removeExpenditureCategory,
  updateExpenditureCategory
} from '../../../redux/slices/super_admin/finances';
import { EditExpenditureCategoryDialog } from '../../../components/_adds/super_admin';

import { condenseText } from '../../../utils/formatText';

const TABLE_HEAD = [
  { id: 'categoryName', label: 'Category name', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' }
];

function Expenditure() {
  const { searchedExpenditureCategories } = useSelector((state) => state.firestore.ordered);
  const { requesting } = useSelector((state) => state.firestore.status);
  const { expenditureCategoriesCount } = useSelector((state) => state.firebase.profile);

  // ----------------------------------------------------------------------

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('firstName');
  const [filterName, setFilterName] = useState('');
  const [cursor, setCursor] = useState(undefined);
  const [isCategoryEditOpen, setIsCategoryEditOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isCategoryStatusOpen, setIsCategoryStatusOpen] = useState(false);
  const [isCategoryDeleteOpen, setIsCategoryDeleteOpen] = useState(false);
  const [error, setError] = useState(null);
  const expenditureCategories = useExpenditureCategories({
    startAfter: cursor,
    limit: rowsPerPage,
    filterName
  });

  // ----------------------------------------------------------------------

  const dispatch = useDispatch();

  // ----------------------------------------------------------------------

  const CategorySchema = Yup.object().shape({
    categoryName: Yup.string().required('Category is required')
  });

  const [initialValues] = useState({
    categoryName: ''
  });

  const formik = useFormik({
    initialValues,
    validationSchema: CategorySchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        setError(null);
        const categoryInfo = {
          ...values,
          searchMatch: condenseText(values.categoryName)
        };
        await dispatch(addExpenditureCategory(categoryInfo));
        resetForm();
      } catch (error) {
        setError(error.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  // ----------------------------------------------------------------------

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setCursor(expenditureCategories.data[expenditureCategories.data.length - 1].createdAt);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteCategory = (category) => {
    setSelectedCategory(category);
    setIsCategoryDeleteOpen(true);
  };

  const handleEditCategory = (category) => {
    setSelectedCategory(category);
    setIsCategoryEditOpen(true);
  };

  const handleChangeStatus = (category) => {
    setSelectedCategory(category);
    setIsCategoryStatusOpen(true);
  };

  // ----------------------------------------------------------------------

  useEffect(() => {
    if (!isCategoryEditOpen && !isCategoryDeleteOpen && !isCategoryStatusOpen) {
      setSelectedCategory(null);
    }
  }, [isCategoryEditOpen, isCategoryDeleteOpen, isCategoryStatusOpen]);

  useEffect(() => {
    if (requesting.searchedExpenditureCategories) {
      if (page) setPage(0);
      else setPage(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requesting.searchedExpenditureCategories]);

  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * rowsPerPage - filterName
            ? searchedExpenditureCategories?.length
            : expenditureCategories.data?.length
        )
      : 0;

  // ----------------------------------------------------------------------

  const { errors, isSubmitting, handleSubmit, getFieldProps } = formik;

  // -----------------------------------------------------------------------

  return (
    <Page title="Super Admin: Expenditure | FashionsPal">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Categories
          </Typography>
        </Stack>
        {error && (
          <Alert sx={{ mb: 2 }} severity="error">
            {error}
          </Alert>
        )}
        <Card sx={{ p: 2.5, mb: 5 }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={10}>
                  <TextField
                    fullWidth
                    label="Category name"
                    {...getFieldProps('categoryName')}
                    error={Boolean(errors.categoryName)}
                    helperText={errors.categoryName}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <LoadingButton
                    sx={{ mt: 0.5 }}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    startIcon={<Icon icon={plusFill} />}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Add
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Card>

        <Card>
          {!!expenditureCategories.data.length && (
            <ListToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
              requesting={requesting.searchedExpenditureCatogorieE}
            />
          )}
          {expenditureCategories.loading || requesting.searchedExpenditureCategories ? (
            <Box
              sx={{
                height: '60vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgress size={55} />
            </Box>
          ) : (
            <>
              {!expenditureCategories.data.length ? (
                'Is empty'
              ) : (
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <ListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={
                          filterName
                            ? searchedExpenditureCategories?.length || 0
                            : expenditureCategoriesCount || 0
                        }
                        onRequestSort={handleRequestSort}
                      />
                      <TableBody>
                        {(filterName
                          ? searchedExpenditureCategories || expenditureCategories.data
                          : expenditureCategories.data
                        )
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            const { categoryName, isActive } = row;

                            return (
                              <TableRow key={row.id} tabIndex={-1}>
                                <TableCell>
                                  <Typography variant="subtitle2" noWrap>
                                    {rowsPerPage * (page + 1) + (index + 1) - rowsPerPage}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">{categoryName}</TableCell>
                                <TableCell align="left">
                                  <Label
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => handleChangeStatus(row)}
                                    variant="ghost"
                                    color={(!isActive && 'error') || 'success'}
                                  >
                                    {isActive ? sentenceCase('active') : sentenceCase('inactive')}
                                  </Label>
                                </TableCell>
                                <TableCell align="right">
                                  <Button
                                    variant="contained"
                                    component="div"
                                    onClick={() => handleEditCategory(row)}
                                    sx={{ mr: 1.5 }}
                                  >
                                    Edit
                                  </Button>
                                  <Button
                                    variant="contained"
                                    component="div"
                                    color="error"
                                    onClick={() => handleDeleteCategory(row)}
                                  >
                                    Delete
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      {isEmpty(searchedExpenditureCategories) && filterName ? (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : null}
                    </Table>
                  </TableContainer>
                </Scrollbar>
              )}
            </>
          )}

          {!requesting.searchedExpenditureCategories && !!expenditureCategories.data.length && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={
                filterName
                  ? searchedExpenditureCategories?.length || 0
                  : expenditureCategoriesCount || 0
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>

        {/* ---- Dialogs start ---- */}
        {isCategoryDeleteOpen && (
          <DeleteDialog
            isOpen={isCategoryDeleteOpen}
            setIsOpen={setIsCategoryDeleteOpen}
            selectedItem={selectedCategory}
            delFunc={removeExpenditureCategory}
          />
        )}
        {isCategoryEditOpen && (
          <EditExpenditureCategoryDialog
            isOpen={isCategoryEditOpen}
            setIsOpen={setIsCategoryEditOpen}
            selectedCategory={selectedCategory}
          />
        )}
        {isCategoryStatusOpen && (
          <StatusDialog
            isOpen={isCategoryStatusOpen}
            setIsOpen={setIsCategoryStatusOpen}
            selectedItem={selectedCategory}
            statusFunc={updateExpenditureCategory}
          />
        )}
        {/* ---- Dialogs end ---- */}
      </Container>
    </Page>
  );
}

export default Expenditure;
