import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { Page, View, Text, Document } from '@react-pdf/renderer';

import styles from './InvoiceStyle';

export default function InvoicePDF({ invoice, owner }) {
  const {
    title,
    customerName,
    cost,
    amountPaid,
    items
  } = invoice;

  const {city, companyName, phoneNumber} = owner

  const currentDate = new Date()

  return (
    <Document>
      <Page size={{width: '302.36px'}} style={styles.page}>
        <View style={[styles.gridContainer, styles.mb10]}>
          <View style={styles.col12}>
            <Text style={[styles.overline, styles.body1, styles.mb2]}>{companyName}</Text>
            <Text style={styles.body1}>{city}</Text>
            <Text style={styles.body1}>{phoneNumber}</Text>
          </View>
        </View>
        <View style={[styles.gridContainer, styles.mb10]}>
          <View style={styles.col12}>
            <Text style={[styles.overline, styles.mb2]}>Customer</Text>
            <Text style={styles.body1}>{customerName}</Text>
          </View>
        </View>
        <View style={[styles.gridContainer, styles.mb10]}>
          <View style={styles.col12}>
            <Text style={[styles.overline, styles.mb2]}>Title</Text>
            <Text style={styles.body1}>{title}</Text>
          </View>
        </View>

        <View style={styles.table}>
          { items && items.length ? (
            <View style={styles.tableHeader}>
            <View style={styles.tableRow}>
              <View style={styles.tableCell_1}>
                <Text style={styles.subtitle2}>#</Text>
              </View>
              <View style={styles.tableCell_1} />
              <View style={styles.tableCell_2}>
                <Text style={styles.subtitle2}>Items</Text>
              </View>
              <View style={[styles.tableCell_2, styles.alignRight]}>
                <Text style={styles.subtitle2}>Cost</Text>
              </View>
            </View>
          </View>) : null
          }
          <View style={styles.tableBody}>
            {
              items && (items || []).map(({name, cost}, index) => (
                <View key={index} style={[styles.tableRow]}>
                  <View style={styles.tableCell_1}>
                    <Text style={styles.subtitle2}>{index + 1}</Text>
                  </View>
                  <View style={styles.tableCell_1} />
                  <View style={styles.tableCell_2}>
                    <Text>{name}</Text>
                  </View>
                  <View style={[styles.tableCell_2, styles.alignRight]}>
                    <Text>GHS {cost}</Text>
                  </View>
                </View>
              ))
            }
            <View style={[styles.tableRow, styles.noBorder]}>
              <View style={styles.tableCell_2} />
              <View style={styles.tableCell_2}>
                <Text>Amount Paid</Text>
              </View>
              <View style={[styles.tableCell_2, styles.alignRight]}>
                <Text>GHS {amountPaid}</Text>
              </View>
            </View>
            <View style={[styles.tableRow, styles.noBorder]}>
              <View style={styles.tableCell_2} />
              <View style={styles.tableCell_2}>
                <Text>Amount Left</Text>
              </View>
              <View style={[styles.tableCell_2, styles.alignRight]}>
                <Text>GHS {Number(cost) - Number(amountPaid)}</Text>
              </View>
            </View>
            <View style={[styles.tableRow, styles.noBorder]}>
              <View style={styles.tableCell_2} />
              <View style={[styles.tableCell_2, styles.subtitle2]}>
                <Text>Total Cost</Text>
              </View>
              <View style={[styles.tableCell_2, styles.alignRight]}>
                <Text style={styles.subtitle2}>GHS {cost}</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={[styles.gridContainer, styles.footer]}>
          <View style={[styles.col6]}>
            <Text>{format(currentDate, 'dd MMM yyyy')} {format(currentDate, 'p')}</Text>
          </View>
          <View style={[styles.col6, styles.alignRight]}>
            <Text>Thank you for choosing us!</Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}