import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const addSubcriptionTransaction = createAsyncThunk(
  'subscription/addSubcriptionTransaction',
  async (transaction, { extra: { getFirestore } }) => {
    const firestore = getFirestore();

    const timestamp = firestore.FieldValue.serverTimestamp();

    await firestore.set(
      { collection: 'subscriptionTransactions', doc: transaction.id },
      {
        ...transaction,
        createdAt: timestamp,
        updatedAt: timestamp
      }
    );
  }
);

// -----------------------------------------------------------------------------------

const initialState = {
  error: null
};

const subscriptionsSlice = createSlice({
  name: 'sewings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addSubcriptionTransaction.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });
  }
});

export default subscriptionsSlice.reducer;
