import * as React from 'react';
import PropTypes from 'prop-types';
import { sentenceCase } from 'change-case';
import { Icon } from '@iconify/react';
import { format } from 'date-fns';
import closeFill from '@iconify/icons-eva/close-fill';
import checkmarkFill from '@iconify/icons-eva/checkmark-fill';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import Label from '../../../Label';

import { fDate } from '../../../../utils/formatTime';

// --------------------------------------------------

StaffViewDialog.propTypes = {
  isOpen: PropTypes.bool,
  staff: PropTypes.object,
  setIsOpen: PropTypes.func,
  handleEdit: PropTypes.func
};

const PERMISSIONS_OPTIONS = [
  {
    value: 'viewCustomerContact',
    label: 'View customers contact',
    permissionClaim: 1
  },
  {
    value: 'viewFinancialDetails',
    label: 'View financial details',
    permissionClaim: 2
  },
  {
    value: 'sendSMS',
    label: 'Send customers SMS',
    permissionClaim: 3
  },
  {
    value: 'accessShop',
    label: 'Access shop and inventory',
    permissionClaim: 4
  }
];

// --------------------------------------------------

export default function StaffViewDialog({ staff, isOpen, setIsOpen, handleEdit }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = React.useCallback(() => {
    setOpen(false);
    setIsOpen(false);
  }, [setIsOpen]);

  const handleEditClick = () => {
    handleClose();
    handleEdit();
  };

  // --------------------------------------------------

  React.useEffect(() => {
    if (isOpen) handleOpen();
    else handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClose, isOpen]);

  // --------------------------------------------------

  const {
    firstName,
    lastName,
    createdAt,
    phoneNumber,
    email,
    isAccountEnabled,
    country,
    city,
    sex,
    birthday,
    permissions,
    address,
    deletedAt
  } = staff.staffData;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          Staff
          <IconButton
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Icon icon={closeFill} width={24} height={24} />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Name
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {`${firstName || ''} ${lastName || ''}`}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Phone
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {phoneNumber || 'N/A'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Email
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {email || 'N/A'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Sex
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {sex || 'N/A'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Date of Birth
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {
                    // eslint-disable-next-line no-nested-ternary
                    birthday ?
                      typeof birthday === 'string' ?
                      birthday : fDate(birthday?.toDate())
                    : 'N/A'
                  }
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Country
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {country || 'N/A'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" component="p">
                  City
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {city || 'N/A'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Address
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {address || 'N/A'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Account Status
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  <Label variant="ghost" color={(!isAccountEnabled && 'error') || 'success'}>
                    {isAccountEnabled ? sentenceCase('enabled') : sentenceCase('disabled')}
                  </Label>
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Joined On
                </Typography>
                <Typography variant="body2">
                  {createdAt ? format(createdAt.toDate(), 'dd MMM yyyy') : 'N/A'}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {createdAt ? format(createdAt.toDate(), 'p') : 'N/A'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="overline" sx={{ color: 'text.secondary' }}>
                Permissions
              </Typography>
              {PERMISSIONS_OPTIONS.map(({ value, label, permissionClaim }) => (
                <Stack direction="row" key={value} spacing={1} sx={{ mb: 1 }}>
                  <Icon
                    icon={permissions.includes(permissionClaim) ? checkmarkFill : closeFill}
                    color={permissions.includes(permissionClaim) ? '#54D62C' : '#FF4842'}
                    width={24}
                    height={24}
                    sx={{ marginRight: '8px' }}
                  />
                  <Typography variant="body2" component="p">
                    {label}
                  </Typography>
                </Stack>
              ))}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClick} disabled={!!deletedAt}>
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
