import * as Yup from 'yup';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'react-redux-firebase';
import { sentenceCase } from 'change-case';
import {
  Container,
  Stack,
  Typography,
  Grid,
  TextField,
  Alert,
  Card,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  CircularProgress,
  Button,
  Box,
  TablePagination
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useFormik, Form, FormikProvider } from 'formik';
import {
  ListHead,
  ListToolbar,
  DeleteDialog,
  StatusDialog
} from '../../../components/_dashboard/super_admin/@general';
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import Label from '../../../components/Label';
//
import { useMeasurementsParent, useMeasurementsList } from '../../../hooks/super_admin';
//
import {
  addMeasurementsList,
  removeMeasurementsList,
  updateMeasurementsList
} from '../../../redux/slices/super_admin/measurements';
//
import { EditMeasurementsListDialog } from '../../../components/_adds/super_admin';
//
import { condenseText } from '../../../utils/formatText';

const TABLE_HEAD = [
  { id: 'parent', label: 'Parent', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' }
];

export default function MeasurementsList() {
  const { searchedMeasurementsList } = useSelector((state) => state.firestore.ordered);
  const { requesting } = useSelector((state) => state.firestore.status);
  const { measurementsListCount } = useSelector((state) => state.firebase.profile);

  // --------------------------------------------------------------

  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('firstName');
  const [filterName, setFilterName] = useState('');
  const [cursor, setCursor] = useState(undefined);
  const [isListEditOpen, setIsListEditOpen] = useState(false);
  const [selectedList, setSelectedList] = useState(null);
  const [isListStatusOpen, setIsListStatusOpen] = useState(false);
  const [isListDeleteOpen, setIsListDeleteOpen] = useState(false);
  const measurementsParent = useMeasurementsParent();
  const measurementsList = useMeasurementsList({
    startAfter: cursor,
    limit: rowsPerPage,
    filterName
  });

  // -----------------------------------------------------------------

  const dispatch = useDispatch();

  // -----------------------------------------------------------------

  const MeasurementsListSchema = Yup.object().shape({
    parentId: Yup.string().required('Parent is required'),
    name: Yup.string().required('Name is required')
  });

  // ------------------------------------------------------------------

  const [initialValues] = useState({
    parentId: '',
    name: ''
  });

  const formik = useFormik({
    initialValues,
    validationSchema: MeasurementsListSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        setError(null);
        const listInfo = {
          ...values,
          searchMatch: condenseText(values.name)
        };
        await dispatch(addMeasurementsList(listInfo));
        resetForm();
      } catch (error) {
        setError(error.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  // ------------------------------------------------------------------

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setCursor(measurementsList.data[measurementsList.data.length - 1].createdAt);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteList = (listItem) => {
    setSelectedList(listItem);
    setIsListDeleteOpen(true);
  };

  const handleEditList = (listItem) => {
    setSelectedList(listItem);
    setIsListEditOpen(true);
  };

  const handleChangeStatus = (listItem) => {
    setSelectedList(listItem);
    setIsListStatusOpen(true);
  };

  const getParentName = (parentId) => {
    if (!measurementsParent.data.length) return '';
    const parent = measurementsParent.data.find((item) => item.id === parentId);
    return parent ? parent.parentTitle : '';
  };

  // ------------------------------------------------------------------

  useEffect(() => {
    if (!isListEditOpen && !isListDeleteOpen && !isListStatusOpen) {
      setSelectedList(null);
    }
  }, [isListEditOpen, isListDeleteOpen, isListStatusOpen]);

  useEffect(() => {
    if (requesting.searchedMeasurementsList) {
      if (page) setPage(0);
      else setPage(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requesting.searchedMeasurementsList]);

  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * rowsPerPage - filterName
            ? searchedMeasurementsList?.length
            : measurementsList.data?.length
        )
      : 0;

  // ------------------------------------------------------------------

  const { errors, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Page title="Super admin: Measurements List | FashionsPal">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Measurements List
          </Typography>
        </Stack>

        {error && (
          <Alert sx={{ mb: 2 }} severity="error">
            {error}
          </Alert>
        )}
        <Card sx={{ p: 2.5, mb: 5 }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={5}>
                  <FormControl fullWidth error={Boolean(errors.parent)}>
                    <InputLabel id="demo-simple-select-label">Parent</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      {...getFieldProps('parentId')}
                      label="Parent"
                    >
                      {measurementsParent.data.map((parent) => (
                        <MenuItem key={parent.id} value={parent.id}>
                          {parent.parentTitle}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{errors.parent}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    fullWidth
                    label="Name"
                    {...getFieldProps('name')}
                    error={Boolean(errors.name)}
                    helperText={errors.name}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <LoadingButton
                    sx={{ mt: 0.5 }}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    startIcon={<Icon icon={plusFill} />}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Add
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Card>

        <Card>
          {!!measurementsList.data.length && (
            <ListToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
              requesting={requesting.searchedMeasurementsList}
            />
          )}
          {measurementsList.loading || requesting.searchedMeasurementsList ? (
            <Box
              sx={{
                height: '60vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgress size={55} />
            </Box>
          ) : (
            <>
              {!measurementsList.data.length ? (
                'Is empty'
              ) : (
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <ListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={
                          filterName
                            ? searchedMeasurementsList?.length || 0
                            : measurementsListCount || 0
                        }
                        onRequestSort={handleRequestSort}
                      />
                      <TableBody>
                        {(filterName
                          ? searchedMeasurementsList || measurementsList.data
                          : measurementsList.data
                        )
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            const { name, parentId, isActive } = row;

                            return (
                              <TableRow key={row.id} tabIndex={-1}>
                                <TableCell>
                                  <Typography variant="subtitle2" noWrap>
                                    {rowsPerPage * (page + 1) + (index + 1) - rowsPerPage}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">{getParentName(parentId)}</TableCell>
                                <TableCell align="left">{name}</TableCell>
                                <TableCell align="left">
                                  <Label
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => handleChangeStatus(row)}
                                    variant="ghost"
                                    color={(!isActive && 'error') || 'success'}
                                  >
                                    {isActive ? sentenceCase('active') : sentenceCase('inactive')}
                                  </Label>
                                </TableCell>
                                <TableCell align="right">
                                  <Button
                                    variant="contained"
                                    component="div"
                                    onClick={() => handleEditList(row)}
                                    sx={{ mr: 1.5 }}
                                  >
                                    Edit
                                  </Button>
                                  <Button
                                    variant="contained"
                                    component="div"
                                    color="error"
                                    onClick={() => handleDeleteList(row)}
                                  >
                                    Delete
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      {isEmpty(searchedMeasurementsList) && filterName ? (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : null}
                    </Table>
                  </TableContainer>
                </Scrollbar>
              )}
            </>
          )}

          {!requesting.searchedMeasurementsList && !!measurementsList.data.length && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={
                filterName ? searchedMeasurementsList?.length || 0 : measurementsListCount || 0
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>

        {/* ---- Dialogs start ---- */}
        {isListDeleteOpen && (
          <DeleteDialog
            isOpen={isListDeleteOpen}
            setIsOpen={setIsListDeleteOpen}
            selectedItem={selectedList}
            delFunc={removeMeasurementsList}
          />
        )}
        {isListEditOpen && (
          <EditMeasurementsListDialog
            isOpen={isListEditOpen}
            setIsOpen={setIsListEditOpen}
            selectedList={selectedList}
          />
        )}
        {isListStatusOpen && (
          <StatusDialog
            isOpen={isListStatusOpen}
            setIsOpen={setIsListStatusOpen}
            selectedItem={selectedList}
            statusFunc={updateMeasurementsList}
          />
        )}
        {/* ---- Dialogs end ---- */}
      </Container>
    </Page>
  );
}
