import * as React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

ShopViewDialog.propTypes = {
  isOpen: PropTypes.bool,
  shop: PropTypes.object,
  setIsOpen: PropTypes.func,
  handleDelete: PropTypes.func
};

// --------------------------------------------------


export default function ShopViewDialog({ shop, isOpen, setIsOpen, handleDelete }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = React.useCallback(() => {
    setOpen(false);
    setIsOpen(false);
  }, [setIsOpen]);

  const handleDeleteClick = () => {
    handleClose();
    handleDelete();
  };

//   const handleEditClick = () => {
//     handleClose();
//     handleEdit();
//   };

  // --------------------------------------------------

  React.useEffect(() => {
    if (isOpen) handleOpen();
    else handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClose, isOpen]);

  // --------------------------------------------------


  const {
    shopName,
    location,
    owner,
  } = shop;
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {/* {title} */}
          View shop
          <IconButton
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Icon icon={closeFill} width={24} height={24} />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12}>
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <Box>
                    <Typography variant="subtitle1" component="p">
                      ShopName
                    </Typography>
                    <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                      {shopName}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box>
                    <Typography variant="subtitle1" component="p">
                      Location
                    </Typography>
                    <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                      {location}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box>
                    <Typography variant="subtitle1" component="p">
                      PhoneNumber
                    </Typography>
                    <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                      {owner}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClick} color="error">
            Delete
          </Button>
          {/* <Button disabled={!shop} onClick={handleEditClick} autoFocus>
            Edit
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
