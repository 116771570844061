import * as Yup from 'yup';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import Proptypes from 'prop-types';
import {
  Button,
  Dialog,
  Alert,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Grid,
  TextField,
  Box
} from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';

import { condenseText } from '../../../utils/formatText';

import { updateExpenditureCategory } from '../../../redux/slices/super_admin/finances';

EditExpenditureCategoryDialog.propTypes = {
  isOpen: Proptypes.bool,
  setIsOpen: Proptypes.func,
  selectedCategory: Proptypes.object
};

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function EditExpenditureCategoryDialog({ isOpen, setIsOpen, selectedCategory }) {
  const [open, setOpen] = React.useState(true);
  const [error, setError] = React.useState(null);

  // ---------------------------------------------------------------------------

  const _isMounted = React.useRef(true);
  const dispatch = useDispatch();

  // ----------------------------------------------------------------------

  const CategorySchema = Yup.object().shape({
    categoryName: Yup.string().required('Category name is required')
  });

  const [initialValues] = React.useState({
    categoryName: ''
  });

  const formik = useFormik({
    initialValues,
    validationSchema: CategorySchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        const categoryInfo = {
          ...values,
          searchMatch: condenseText(values.categoryName)
        };
        await dispatch(updateExpenditureCategory(categoryInfo));
        resetForm();
        handleClose();
      } catch (error) {
        setError(error.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  // ----------------------------------------------------------------------

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = React.useCallback(() => {
    setOpen(false);
    setIsOpen(false);
  }, [setIsOpen]);

  // ----------------------------------------------------------------------

  React.useEffect(() => {
    if (isOpen) {
      handleOpen();
      if (selectedCategory) {
        formik.setValues(selectedCategory);
      }
    } else {
      formik.resetForm();
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClose, isOpen]);

  React.useEffect(
    () => () => {
      _isMounted.current = false;
    },
    []
  );

  // ----------------------------------------------------------------------

  const { errors, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {error && <Alert severity="error">{error}</Alert>}
        <DialogTitle>Edit {selectedCategory.categoryName}</DialogTitle>
        <DialogContent>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate>
              <Box sx={{ pt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Category Name"
                      {...getFieldProps('categoryName')}
                      error={Boolean(errors.categoryName)}
                      helperText={errors.categoryName}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Form>
          </FormikProvider>
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={handleSubmit} loading={isSubmitting}>
            Update
          </LoadingButton>
          <Button disabled={isSubmitting} onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
