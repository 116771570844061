import * as React from 'react';
import PropTypes from 'prop-types';
import { sentenceCase } from 'change-case';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { format } from 'date-fns';

import Label from '../../../Label';

// --------------------------------------------------

OwnersViewDialog.propTypes = {
  isOpen: PropTypes.bool,
  owner: PropTypes.object,
  setIsOpen: PropTypes.func,
  handlePwdReset: PropTypes.func
  // handleEdit: PropTypes.func,
  // handleDelete: PropTypes.func
};

// --------------------------------------------------

export default function OwnersViewDialog({ owner, isOpen, setIsOpen, handlePwdReset }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = React.useCallback(() => {
    setOpen(false);
    setIsOpen(false);
  }, [setIsOpen]);

  const handlePasswordReset = () => {
    handleClose();
    handlePwdReset();
  };

  // const handleDeleteClick = () => {
  //   handleClose();
  //   handleDelete();
  // };

  // const handleEditClick = () => {
  //   handleClose();
  //   handleEdit();
  // };

  // --------------------------------------------------------------------------

  React.useEffect(() => {
    if (isOpen) handleOpen();
    else handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClose, isOpen]);

  // --------------------------------------------------------------------------

  const {
    companyName,
    firstName,
    lastName,
    phoneNumber,
    createdAt,
    address,
    isEnabled,
    customersCount,
    sewingsCount,
    totalIncome,
    senderId,
    totalExpenditure
  } = owner;
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          Owner
          <IconButton
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Icon icon={closeFill} width={24} height={24} />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Company Name
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {companyName || 'N/A'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Owner's Name
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {`${firstName} ${lastName}` || 'N/A'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Phone Number
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {phoneNumber || 'N/A'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Sender ID
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {senderId || 'N/A'}
                </Typography>
              </Box>
              <Box sx={{ marginBottom: '20px' }}>
                <Typography variant="subtitle1" component="p">
                  Joined at
                </Typography>
                <Typography variant="body2">
                  {createdAt ? format(createdAt.toDate(), 'dd MMM yyyy') : 'N/A'}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {createdAt ? format(createdAt.toDate(), 'p') : 'N/A'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Address
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {address || 'N/A'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Account Status
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  <Label variant="ghost" color={(!isEnabled && 'error') || 'success'}>
                    {isEnabled ? sentenceCase('enabled') : sentenceCase('disabled')}
                  </Label>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Total Customers
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {customersCount}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Total Sewings
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {sewingsCount}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Total Income
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {totalIncome}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Total Expenditure
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {totalExpenditure}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePasswordReset}>Reset Password</Button>
          {/* <Button onClick={handleEditClick} autoFocus>
            Edit
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
