// hooks
// import useAuth from '../hooks/useAuth';
import { useSelector } from 'react-redux';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { profileImage, firstName, lastName } = useSelector((state) => state.firebase.profile);

  const displayName = `${firstName} ${lastName}`;

  return (
    <Avatar
      src={profileImage}
      alt={displayName}
      color={displayName ? 'default' : createAvatar(displayName).color}
      {...other}
    >
      {createAvatar(displayName).name}
    </Avatar>
  );
}
