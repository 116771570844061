import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'react-redux-firebase';
import eyeOutlined from '@iconify/icons-ant-design/eye-outlined';
// material
import {
  Box,
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress
} from '@mui/material';
// components
import { ListHead, ListToolbar } from '../../components/_dashboard/super_admin/@general';

import {
  CustomersViewDialog,
  CustomersEmpty
} from '../../components/_dashboard/super_admin/customers';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';

import { useCustomers } from '../../hooks/super_admin';

// ---------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'contact', label: 'Contact', alignRight: false },
  { id: 'sex', label: 'Sex', alignRight: false },
  { id: 'owner', label: 'Owner', alignRight: false },
  { id: '' }
];

export default function Customers() {
  const { searchedCustomers } = useSelector((state) => state.firestore.ordered);
  const { users } = useSelector((state) => state.firestore.data);
  const { requesting } = useSelector((state) => state.firestore.status);
  const { customersCount } = useSelector((state) => state.firebase.profile);

  // ----------------------------------------------------------------------

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('firstName');
  const [filterName, setFilterName] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  // const [isCustomerEditOpen, setIsCustomerEditOpen] = useState(false);
  const [customerOpen, setCustomerOpen] = useState(false);
  const [cursor, setCursor] = useState(undefined);
  const allCustomers = useCustomers({
    startAfter: cursor,
    limit: rowsPerPage,
    filterName
  });
  // const allCustomers = { data: [] };

  // ----------------------------------------------------------------------

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setCursor(allCustomers.data[allCustomers.data.length - 1].customerData.createdAt);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  // const handleCustomerEditOpen = (customer) => {
  //   setSelectedCustomer(customer);
  //   setIsCustomerEditOpen(true);
  // };

  const handleCustomerClick = (customer) => {
    setSelectedCustomer(customer);
    setCustomerOpen(true);
  };

  // ----------------------------------------------------------------------

  useEffect(() => {
    if (!customerOpen) {
      setSelectedCustomer(null);
    }
  }, [customerOpen]);

  useEffect(() => {
    if (requesting.searchedCustomers) {
      if (page) setPage(0);
      else setPage(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requesting.searchedCustomers]);

  // -----------------------------------------------------------------

  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * rowsPerPage - filterName
            ? searchedCustomers?.length
            : allCustomers.data?.length
        )
      : 0;

  return (
    <Page title="Super admin: Customers | FashionsPal">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Customers
          </Typography>
        </Stack>
      </Container>

      <Card>
        {!!allCustomers.data.length && (
          <ListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            requesting={requesting.searchedCustomers}
          />
        )}

        {allCustomers.loading || requesting.searchedCustomers ? (
          <Box
            sx={{
              height: '60vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <CircularProgress size={55} />
          </Box>
        ) : (
          <>
            {!allCustomers.data.length ? (
              <CustomersEmpty />
            ) : (
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <ListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={filterName ? searchedCustomers?.length || 0 : customersCount || 0}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {(filterName ? searchedCustomers || allCustomers.data : allCustomers.data)
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          const { firstName, lastName, phoneNumber, sex } = row.customerData;

                          return (
                            <TableRow
                              key={row.customerId}
                              sx={{ cursor: 'pointer' }}
                              tabIndex={-1}
                              onClick={() => handleCustomerClick(row)}
                            >
                              <TableCell>
                                <Typography variant="subtitle2" noWrap>
                                  {rowsPerPage * (page + 1) + (index + 1) - rowsPerPage}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                {`${firstName} ${lastName}` || 'N/A'}
                              </TableCell>
                              <TableCell align="left">{phoneNumber || 'N/A'}</TableCell>
                              <TableCell align="left">{sex || 'N/A'}</TableCell>
                              <TableCell align="left">
                                {users ? users[row.ownerId]?.companyName || 'N/A' : 'N/A'}
                              </TableCell>
                              {/* <TableCell align="right">
                                <Button
                                  variant="contained"
                                  component="div"
                                  onClick={() => handleCustomerEditOpen(row)}
                                  sx={{ mr: 1.5 }}
                                >
                                  Edit
                                </Button>
                              </TableCell> */}
                              <TableCell align="right">
                                <Button
                                  variant="contained"
                                  component="div"
                                  startIcon={<Icon icon={eyeOutlined} />}
                                  sx={{ mr: 1.5 }}
                                >
                                  View
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isEmpty(searchedCustomers) && filterName ? (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : null}
                  </Table>
                </TableContainer>
              </Scrollbar>
            )}
          </>
        )}

        {!requesting.searchedCustomers && !!allCustomers.data.length && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filterName ? searchedCustomers?.length || 0 : customersCount || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Card>

      {/* ----- Dialogs start ----- */}
      {customerOpen && (
        <CustomersViewDialog
          isOpen={customerOpen}
          setIsOpen={setCustomerOpen}
          customer={selectedCustomer}
        />
      )}
      {/* ----- Dialogs end ------ */}
    </Page>
  );
}
