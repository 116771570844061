import * as Yup from 'yup';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import Proptypes from 'prop-types';
import {
  Button,
  Dialog,
  Alert,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Grid,
  TextField,
  MenuItem,
  Box,
  FormControl,
  InputLabel,
  Select,
  FormHelperText
} from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';

import { condenseText } from '../../../utils/formatText';
import { useMeasurementsParent } from '../../../hooks/super_admin';

EditMeasurementsListDialog.propTypes = {
  isOpen: Proptypes.bool,
  setIsOpen: Proptypes.func,
  selectedList: Proptypes.object,
  updateFunc: Proptypes.func
};

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function EditMeasurementsListDialog({
  isOpen,
  setIsOpen,
  selectedList,
  updateFunc
}) {
  const [open, setOpen] = React.useState(true);
  const [error, setError] = React.useState(null);
  const measurementsParent = useMeasurementsParent();

  // ---------------------------------------------------------------------------

  const _isMounted = React.useRef(true);
  const dispatch = useDispatch();

  // ----------------------------------------------------------------------

  const MeasurementsListSchema = Yup.object().shape({
    parentId: Yup.string().required('Parent is required'),
    name: Yup.string().required('Name is required')
  });

  const [initialValues] = React.useState({
    parentId: '',
    name: ''
  });

  const formik = useFormik({
    initialValues,
    validationSchema: MeasurementsListSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        const listInfo = {
          ...values,
          searchMatch: condenseText(values.name)
        };
        await dispatch(updateFunc(listInfo));
        resetForm();
        handleClose();
      } catch (error) {
        setError(error.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  // ----------------------------------------------------------------------

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = React.useCallback(() => {
    setOpen(false);
    setIsOpen(false);
  }, [setIsOpen]);

  // ----------------------------------------------------------------------

  React.useEffect(() => {
    if (isOpen) {
      handleOpen();
      if (selectedList) {
        formik.setValues(selectedList);
      }
    } else {
      formik.resetForm();
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClose, isOpen]);

  React.useEffect(
    () => () => {
      _isMounted.current = false;
    },
    []
  );

  // ----------------------------------------------------------------------

  const { errors, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {error && <Alert severity="error">{error}</Alert>}
        <DialogTitle>Edit {selectedList.name}</DialogTitle>
        <DialogContent>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate>
              <Box sx={{ pt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth error={Boolean(errors.parent)}>
                      <InputLabel id="demo-simple-select-label">Parent</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        {...getFieldProps('parentId')}
                        label="Parent"
                      >
                        {measurementsParent.data.map((parent) => (
                          <MenuItem key={parent.id} value={parent.id}>
                            {parent.parentTitle}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{errors.parent}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Name"
                      {...getFieldProps('name')}
                      error={Boolean(errors.name)}
                      helperText={errors.name}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Form>
          </FormikProvider>
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={handleSubmit} loading={isSubmitting}>
            Update
          </LoadingButton>
          <Button disabled={isSubmitting} onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
