export function fPhoneNumber(phoneNumber) {
  let phoneNumberString;

  if (typeof phoneNumber === 'string') {
    phoneNumberString = phoneNumber.replace(/\D/g, '');
    if (phoneNumberString.startsWith(0)) {
      return `+233${phoneNumberString.substr(1)}`;
    }
    return `+233${phoneNumberString}`;
  }
  return `+233${phoneNumber}`;
}

export function fPhoneNumberToEmail(phoneNumber) {
  return `+233${parseInt(phoneNumber, 10)}@fashionpal.com`;
}

export function stringfyPhoneNumber(phoneNumber) {
  const phoneNumberString = phoneNumber.toString().replace(/\D/g, '');
  if (phoneNumberString)
    return phoneNumberString.startsWith(0) ? `${phoneNumberString}` : `0${phoneNumberString}`;
  return '';
}
