import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { isEmpty, useFirestore } from 'react-redux-firebase';
import plusFill from '@iconify/icons-eva/plus-fill';
import eyeOutlined from '@iconify/icons-ant-design/eye-outlined';
import checkmarkCircleFill from '@iconify/icons-eva/checkmark-circle-fill';
import filterLeft from '@iconify/icons-bi/filter-left';
// material
import {
  Box,
  Card,
  Table,
  Stack,
  Badge,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  IconButton,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress
} from '@mui/material';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { AddSewDialog } from '../../components/_adds/owner';
import {
  SewListHead,
  SewListToolbar,
  SewStatusDialog,
  SewDeleteDialog,
  SewPayDialog,
  SewViewDialog,
  SewEmpty,
  SewingFilterDialog
} from '../../components/_dashboard/owner/sewings';

import { useSewings } from '../../hooks/owner';
import { checkForRole } from '../../utils/checkAuthUserRole';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'customer', label: 'Customer', alignRight: false },
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'staff', label: 'Staff', alignRight: false },
  { id: 'sewing_status', label: 'Status', alignRight: false },
  { id: 'cost', label: 'Cost', alignRight: false },
  { id: 'amount_paid', label: 'Paid', alignRight: false },
  { id: 'payment_status', label: 'Payment Status', alignRight: false },
  { id: '', alignRight: true }
];

// ----------------------------------------------------------------------

function Sewings({ title }) {
  const { searchedSewings } = useSelector((state) => state.firestore.ordered);
  const { requesting } = useSelector((state) => state.firestore.status);
  const { sewingsCount, token, ownerId, totalAmountPaid, totalCost } = useSelector((state) => state.firebase.profile);
  const currUserId = useSelector((state) => state.firebase.auth.uid);
  const firestore = useFirestore();

  // ------------------------------------------------------------------------------

  const { identifier } = token && checkForRole(token);
  const { customerId } = useParams();

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedSew, setSelectedSew] = useState(null);
  const [sewOpen, setSewOpen] = useState(false);
  const [isSewEditOpen, setIsSewEditOpen] = useState(false);
  const [isSewPayOpen, setIsSewPayOpen] = useState(false);
  const [isSewStatusOpen, setIsSewStatusOpen] = useState(false);
  const [isSewDeleteOpen, setIsSewDeleteOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filter, setFilter] = useState([])
  const [sewingsNum, setSewingsNum] = useState(0);
  const [cursor, setCursor] = useState(undefined);
  const sewings = useSewings({
    startAfter: cursor,
    limit: rowsPerPage,
    filterName,
    filter,
    customerId,
    staffId: title === 'My Orders' ? currUserId : ''
  });

  const navigate = useNavigate();

  // ----------------------------------------------------------------------

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setCursor(sewings.data[sewings.data.length - 1].sewingData.createdAt);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleEdit = (item) => {
    setIsSewEditOpen(true);
    setSelectedSew(item);
  };

  const handleStatusChange = (e, item) => {
    e.stopPropagation();
    if (identifier === 'staff' && currUserId !== item.staffId) return;
    setIsSewStatusOpen(true);
    setSelectedSew(item);
  };

  const handleDelete = (item) => {
    setIsSewDeleteOpen(true);
    setSelectedSew(item);
  };

  const handleSewClick = (item) => {
    setSelectedSew(item);
    setSewOpen(true);
  };

  const handlePayClick = (e, item) => {
    e.stopPropagation();
    setSelectedSew(item);
    setIsSewPayOpen(true);
  };

  const _getPaymentStatus = (cost, amountPaid) => {
    const costNum = Number(cost);
    const amountPaidNum = Number(amountPaid);
    if (costNum && amountPaidNum && costNum === amountPaidNum) return 'Fully Paid';
    if (costNum && amountPaidNum && costNum > amountPaidNum) return 'Partially Paid';
    if (costNum && amountPaidNum === 0) return 'Not Paid';
  };

  const applyFilter = (filter) => {
    let queries = [];
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for(const key in filter) {
      if (key === 'status' && filter[key].length) {
        queries = [...queries, [`sewingData.${key}`, 'in', filter[key]]]
      }

      if (key === 'dateRange' && filter[key]) {
        queries = [...queries, [`sewingData.createdAt`, ">=", filter[key].startDate], [`sewingData.createdAt`, "<=", filter[key].endDate]]
      }
    }
    setFilter(queries)
  }

  const handleClearFilter = () => {
    setFilter([])
  }

  const calcTotalAmountPaid = () => {
    if (customerId) {
      let num = 0;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < sewings.data.length; i++) {
        if (sewings.data[i].sewingData.amountPaid)
        num += Number(sewings.data[i].sewingData.amountPaid);
      }
      return num
    }
    return 0
  }

  const calcTotalCost = () => {
    if (customerId) {
      let num = 0;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < sewings.data.length; i++) {
        if (sewings.data[i].sewingData.cost)
          num += Number(sewings.data[i].sewingData.cost);
      }
      return num
    }
    return 0
  }

  // ----------------------------------------------------------------------

  useEffect(() => {
    if (!isSewEditOpen && !isSewStatusOpen && !isSewDeleteOpen && !sewOpen && !isSewPayOpen) {
      setSelectedSew(null);

    }
  }, [isSewEditOpen, isSewStatusOpen, isSewDeleteOpen, sewOpen, isSewPayOpen]);

  useEffect(() => {
    if (requesting.searchedSewings) {
      if (page) setPage(0);
      else setPage(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requesting.searchedSewings]);

  useEffect(() => {
    const getOwner = async () => {
      const owner = await firestore.collection('users').doc(ownerId).get()
      setSewingsNum(owner.data().sewingsCount)
    }

    if (!title && identifier === 'staff') {
      getOwner()
    } else setSewingsNum(0)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title])


  useEffect(() => {
    setPage(0)
    setCursor(0)
  }, [customerId, filter])

  // ----------------------------------------------------------------------

  const totalIndividualPayments = sewings.loading ? 0 : calcTotalAmountPaid();

  const totalIndividualCost = sewings.loading ? 0 : calcTotalCost();

  // -----------------------------------------------------------------------

  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * rowsPerPage - filterName ? searchedSewings?.length : sewings.data?.length
        )
      : 0;

  return (
    <Page title="Orders | FashionsPal">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            {title || 'Orders'}
          </Typography>
          <Stack direction="row" alignItems="center" spacing={2}>
          <Button variant="contained" component="div" startIcon={<Icon icon={plusFill} />}>
            New Invoice
          </Button>
          <AddSewDialog
            isSewOpen={isSewEditOpen}
            setIsSewOpen={setIsSewEditOpen}
            selectedSew={selectedSew}
          >
            <Button variant="contained" component="div" startIcon={<Icon icon={plusFill} />}>
              Add Order
            </Button>
          </AddSewDialog>
          </Stack>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} mb={5}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <SewingFilterDialog isFilterOpen={isFilterOpen} setIsFilterOpen={setIsFilterOpen} applyFilter={applyFilter} filters={filter}>
                <Badge color="error" variant="dot" invisible={!filter.length}>
                  <Button variant="contained" aria-label="filter">
                    Filter
                  </Button>
                </Badge>
            </SewingFilterDialog>
            <Button onClick={handleClearFilter}>Clear filters</Button>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Label
              variant="ghost"
              color="success"
              sx={{ fontSize: '0.875rem', padding: '18px 16px' }}
            >
              Total Payments: ₵{!sewings.loading ? (totalIndividualPayments || totalAmountPaid || 0) : 0}
            </Label>
            <Label
              variant="ghost"
              color="error"
              sx={{ fontSize: '0.875rem', padding: '18px 16px' }}
            >
              Total Debt: ₵{!sewings.loading ?( (totalIndividualCost - totalIndividualPayments) || (totalCost - totalAmountPaid) || 0) : 0}
            </Label>
            <Button variant="contained" component="div" onClick={() => navigate(`/dashboard/payment-history/${customerId || ''}`)}>
              Payments
            </Button>
          </Stack>
        </Stack>

        <Card>
          {!!sewings.data.length && !customerId && (
            <SewListToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
              requesting={requesting.searchedSewings}
            />
          )}
          {sewings.loading || requesting.searchedSewings ? (
            <Box
              sx={{
                height: '60vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgress size={55} />
            </Box>
          ) : (
            <>
              {!sewings.data.length ? (
                <SewEmpty />
              ) : (
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <SewListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={filterName ? searchedSewings?.length || 0 : sewingsCount || 0}
                        onRequestSort={handleRequestSort}
                      />
                      <TableBody>
                        {(filterName ? searchedSewings || sewings.data : sewings.data)
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            const { title, customerName, status, amountPaid, cost, customerData, staffName } =
                              row.sewingData;
                            return (
                              <TableRow
                                key={index}
                                sx={{ cursor: 'pointer' }}
                                tabIndex={-1}
                                onClick={() => handleSewClick(row)}
                              >
                                <TableCell
                                  sx={{ color: `${!customerData ? 'text.disabled' : ''}` }}
                                >
                                  <Typography variant="subtitle2" noWrap>
                                    {rowsPerPage * (page + 1) + (index + 1) - rowsPerPage}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{ color: `${!customerData ? 'text.disabled' : ''}` }}
                                >
                                  {customerName}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{ color: `${!customerData ? 'text.disabled' : ''}` }}
                                >
                                  {title}
                                </TableCell>
                                <TableCell align="left">
                                  {staffName || 'N/A'}
                                </TableCell>
                                <TableCell align="left">
                                  <Label
                                    variant="ghost"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={(e) => handleStatusChange(e, row)}
                                    color={
                                      (status === 'ordered' && 'error') ||
                                      ((status === 'completed' || status === 'delivered') &&
                                        'success') ||
                                      'warning'
                                    }
                                  >
                                    {sentenceCase(status)}
                                  </Label>
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{ color: `${!customerData ? 'text.disabled' : ''}` }}
                                >
                                  {cost || cost === 0 ? `₵${cost}` : '--'}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{ color: `${!customerData ? 'text.disabled' : ''}` }}
                                >
                                  {amountPaid || amountPaid === 0 ? `₵${amountPaid}` : '--'}
                                </TableCell>
                                <TableCell align="left">
                                  {cost ? (
                                    <Label
                                      variant="ghost"
                                      sx={{ cursor: 'pointer' }}
                                      color={
                                        (_getPaymentStatus(cost, amountPaid) === 'Not Paid' &&
                                          'error') ||
                                        (_getPaymentStatus(cost, amountPaid) === 'Fully Paid' &&
                                          'success') ||
                                        'warning'
                                      }
                                    >
                                      {sentenceCase(_getPaymentStatus(cost, amountPaid))}
                                    </Label>
                                  ) : (
                                    'N/A'
                                  )}
                                </TableCell>
                                <TableCell onClick={(e) => e.stopPropagation()} align="right">
                                  <Button
                                    variant="contained"
                                    component="div"
                                    disabled={
                                      !!(!cost && !amountPaid) ||
                                      !!(cost && amountPaid && Number(cost) === Number(amountPaid))
                                    }
                                    startIcon={<Icon icon={checkmarkCircleFill} />}
                                    onClick={(e) => handlePayClick(e, row)}
                                    sx={{ mr: 1.5 }}
                                  >
                                    Pay
                                  </Button>
                                  <Button
                                    variant="contained"
                                    component="div"
                                    startIcon={<Icon icon={eyeOutlined} />}
                                    onClick={() => handleSewClick(row)}
                                    sx={{ mr: 1.5 }}
                                  >
                                    View
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      {isEmpty(searchedSewings) && filterName ? (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : null}
                    </Table>
                  </TableContainer>
                </Scrollbar>
              )}
            </>
          )}
          {!requesting.searchedSewings && !!sewings.data.length && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={(filterName || customerId || filter.length ) ? searchedSewings?.length || sewings.data.length || 0 : sewingsNum || sewingsCount || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>

        {/* ----- Dialogs Start ------ */}
        <SewStatusDialog
          isOpen={isSewStatusOpen}
          setIsOpen={setIsSewStatusOpen}
          selectedSew={selectedSew}
        />
        {isSewDeleteOpen && (
          <SewDeleteDialog
            isOpen={isSewDeleteOpen}
            setIsOpen={setIsSewDeleteOpen}
            selectedSew={selectedSew}
          />
        )}
        {sewOpen && (
          <SewViewDialog
            isOpen={sewOpen}
            setIsOpen={setSewOpen}
            sew={selectedSew}
            handleEdit={() => handleEdit(selectedSew)}
            handleDelete={() => handleDelete(selectedSew)}
          />
        )}
        {isSewPayOpen && (
          <SewPayDialog
            isOpen={isSewPayOpen}
            setIsOpen={setIsSewPayOpen}
            selectedSew={selectedSew}
          />
        )}
        {/* ----- Dialogs End ------ */}
      </Container>
    </Page>
  );
}

export default Sewings;
