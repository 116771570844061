// @mui
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import {
  Button,
  Box,
  Card,
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  CardHeader,
  Typography,
  TableContainer,
} from '@mui/material';
// utils
import { fCurrency } from '../../../../utils/formatNumber';
// components
import Label from '../../../../components/Label';
import Image from '../../../../components/Image';
import Scrollbar from '../../../../components/Scrollbar';
import { TableHeadCustom } from '../../../../components/table';
import createAvatar from '../../../../utils/createAvatar';
import {PATH_DASHBOARD} from '../../../../routes/paths';
// ----------------------------------------------------------------------

EcommerceBestSalesman.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  tableData: PropTypes.array.isRequired,
  tableLabels: PropTypes.array.isRequired,
};

export default function EcommerceBestSalesman({ title, subheader, tableData, tableLabels, ...other }) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} sx={{ mb: 3 }} />
      <Scrollbar>
        <TableContainer lg={6}>
          <Table>
            <TableHeadCustom headLabel={tableLabels} />

            <TableBody>
              {tableData.map((row, index) => (
                <EcommerceBestSalesmanRow key={row.id} index={index} row={row}/>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
}

// ----------------------------------------------------------------------

EcommerceBestSalesmanRow.propTypes = {
  row: PropTypes.shape({
    avatar: PropTypes.string,
    category: PropTypes.string,
    email: PropTypes.string,
    flag: PropTypes.string,
    name: PropTypes.string,
    rank: PropTypes.string,
    total: PropTypes.number,
  }),
};

function EcommerceBestSalesmanRow({ index, row }) {
  const theme = useTheme();
  const navigate=useNavigate();
  const handleClick = (id, event) => {
    event.preventDefault();
    // navigate(`${PATH_DASHBOARD.invoice.list}?id=${id}`);
    navigate(`${PATH_DASHBOARD.invoice.list}`, {
      state: {
        'id' : id
      }
    });
  }
  return (
    <TableRow>
      <TableCell>
        <Stack direction="row" alignItems="center">
        <Avatar alt={row.customerData.firstName} color={createAvatar(row.customerData.firstName).color}>
          {createAvatar(row.customerData.firstName).name}
        </Avatar>

          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2"> {row.name} </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {row.customerData.firstName} {row.customerData.lastName}
            </Typography>
          </Box>
        </Stack>
      </TableCell>

      <TableCell>{row.count}</TableCell>

      {/* <TableCell>
        <Image src={row.flag} alt="country flag" sx={{ maxWidth: 28, mx: 'auto' }} />
      </TableCell> */}

      <TableCell>₵{fCurrency(row.amount)}</TableCell>

      <TableCell align="right" >
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={
            (index === 0 && 'primary') ||
            (index === 1 && 'info') ||
            (index === 2 && 'success') ||
            (index === 3 && 'warning') ||
            'error'
          }
        >
            Top{index + 1}
        </Label>
      </TableCell>
      <TableCell align="right" >
        <Button onClick={ (event) => handleClick(row.id, event)}>GO</Button>
      </TableCell>
    </TableRow>
  );
}
