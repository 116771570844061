import * as Yup from 'yup';
import { useState, forwardRef, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import PropTypes from 'prop-types';
import closeFill from '@iconify/icons-eva/close-fill';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { /* alpha, */ styled } from '@mui/material/styles';
import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Box,
  Container,
  TextField,
  Grid,
  MenuItem,
  Alert,
  InputAdornment,
  CircularProgress
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Toaster from '../../Toaster';

import { addCustomer, updateCustomer } from '../../../redux/slices/owner/customers';

import { stringfyPhoneNumber } from '../../../utils/formatPhoneNumber';
import { condenseName } from '../../../utils/formatText';
import { getErrorMessage } from '../../../utils/firebaseError';
import { fDate } from '../../../utils/formatTime';
import { checkForRole } from '../../../utils/checkAuthUserRole';

const SEX = [
  {
    name: 'male',
    value: 'male'
  },
  {
    name: 'female',
    value: 'female'
  },
  {
    name: 'other',
    value: 'other'
  }
];

const COUNTRIES = [
  {
    name: 'Ghana',
    value: 'ghana'
  }
];

AddCustomerDialog.propTypes = {
  children: PropTypes.node,
  selectedCustomer: PropTypes.object,
  isCustomerOpen: PropTypes.bool,
  setIsCustomerOpen: PropTypes.func
};

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

// ----------------------------------------------------------------------

const ChildrenButtonStyle = styled(Button)(() => ({
  width: '100%',
  padding: 0
}));

// ----------------------------------------------------------------------

export default function AddCustomerDialog({
  children,
  selectedCustomer,
  isCustomerOpen,
  setIsCustomerOpen
}) {
  const [open, setOpen] = useState(false);
  const [dateValue, setDateValue] = useState(null);
  const [error, setError] = useState(null);
  const [checkPhoneQuery, setCheckPhoneQuery] = useState(null);
  const [initialValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: '',
    sex: 'other',
    birthday: '',
    country: 'ghana',
    city: ''
  });

  const customerContainerRef = useRef(null);
  const toastRef = useRef();

  // -----------------------------------------------------------------

  const dispatch = useDispatch();
  const firestore = useFirestore();
  const { existingCustomer } = useSelector((state) => state.firestore.ordered);
  const { requesting } = useSelector((state) => state.firestore.status);
  const authUserUid = useSelector((state) => state.firebase.auth.uid);
  const { token, ownerId } = useSelector((state) => state.firebase.profile);


  const { identifier } = token && checkForRole(token);

  const ownerUid = identifier === 'staff' ? ownerId : authUserUid;

  // -----------------------------------------------------------------

  const CustomerSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    phoneNumber:
      selectedCustomer || existingCustomer?.length > 0
        ? Yup.string().max(10, 'Invalid phone number').min(10, 'Invalid phone number')
        : Yup.string().max(9, 'Invalid phone number').min(9, 'Invalid phone number')
  });

  const formik = useFormik({
    initialValues,
    validationSchema: CustomerSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        if (selectedCustomer) {
          const customerInfo = {
            ...selectedCustomer,
            customerData: {
              ...values,
              searchMatch: condenseName(values.firstName, values.lastName)
            }
          };
          await dispatch(updateCustomer(customerInfo));
          toastRef.current.handleOpen('Customer updated successfully');
        } else {
          const customerInfo = {
            ...values,
            phoneNumber: existingCustomer?.length
              ? values.phoneNumber
              : stringfyPhoneNumber(values.phoneNumber),
            searchMatch: condenseName(values.firstName, values.lastName)
          };
          await dispatch(addCustomer({customerInfo, ownerId: ownerUid}));
          toastRef.current.handleOpen('Customer added successfully');
        }
        // reset form
        resetForm();
        setDateValue(null);
        // clsoe dialog
        handleClose();
      } catch (error) {
        customerContainerRef.current.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        setError(getErrorMessage(error) || error.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  // -----------------------------------------------------------------

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setError(null);
    setCheckPhoneQuery(null);
    formik.resetForm();
    setOpen(false);
    if (setIsCustomerOpen) setIsCustomerOpen(false);
  };

  const handleDateChange = (newValue) => {
    setDateValue(newValue);
    formik.setFieldValue('birthday', newValue);
  };

  // -----------------------------------------------------------------

  // watch isSewOpen change
  useEffect(() => {
    if (isCustomerOpen) {
      setOpen(true);
      if (selectedCustomer) {
        const { birthday } = selectedCustomer.customerData;
        formik.setValues(selectedCustomer.customerData);
        handleDateChange(birthday ? fDate(birthday.toDate()) : null);
      }
    } else {
      formik.resetForm();
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomerOpen]);

  useEffect(() => {
    const { phoneNumber } = formik.values;
    if (phoneNumber.toString().length === 9) {
      setCheckPhoneQuery({
        collection: 'users',
        where: [['phoneNumber', '==', stringfyPhoneNumber(phoneNumber)]],
        storeAs: 'existingCustomer'
      });
    }

    if (!phoneNumber.toString().length) {
      setCheckPhoneQuery(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.phoneNumber]);

  useEffect(() => {
    const checkCustomerExistence = async (customerId) => {
      try {
        if (existingCustomer[0].role !== 'customer') {
          const error = new Error(
            `This user is already signed up as ${existingCustomer[0].role}. Please try a different number.`
          );
          error.code = 'user/different-role';
          throw error;
        }

        const docSnapshot = await firestore
          .collection('customers')
          .doc(`${ownerUid}_${customerId}`)
          .get();

        if (docSnapshot.exists) {
          const error = new Error('You have already added this customer');
          error.code = 'user/already-customer';
          throw error;
        }

        const { birthday } = existingCustomer[0];
        formik.setValues(existingCustomer[0]);
        handleDateChange(birthday ? fDate(birthday.toDate()) : null);
      } catch (error) {
        customerContainerRef.current.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        setError(error);
      }
    };

    if (!requesting.existingCustomer && existingCustomer?.length) {
      checkCustomerExistence(existingCustomer[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requesting.existingCustomer]);

  // -----------------------------------------------------------------

  useFirestoreConnect(
    () =>
      checkPhoneQuery || {
        collection: 'users',
        where: [['phoneNumber', '==', null]],
        storeAs: 'existingCustomer'
      }
  );

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <ChildrenButtonStyle onClick={handleClickOpen}>{children}</ChildrenButtonStyle>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <div ref={customerContainerRef} style={{ overflowY: 'auto' }}>
          <AppBar sx={{ position: 'sticky' }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <Icon icon={closeFill} width={24} height={24} />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {`${selectedCustomer ? 'Edit' : 'Add'} Customer`}
              </Typography>
            </Toolbar>
          </AppBar>
          <Container maxWidth="sm" sx={{ pb: 5 }}>
            <Box sx={{ pb: 3, pt: 4 }}>
              <Typography variant="h4">{`${
                selectedCustomer ? 'Edit' : 'Add New'
              } Customer`}</Typography>
            </Box>
            {error && (
              <Alert sx={{ mt: -1.5, mb: 3 }} severity="error">
                {error.message || error}
              </Alert>
            )}
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Phone Number"
                      disabled={
                        !!selectedCustomer ||
                        !!existingCustomer?.length ||
                        error?.code === 'user/already-customer' ||
                        error?.code === 'user/different-role'
                      }
                      {...getFieldProps('phoneNumber')}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {requesting.existingCustomer ? (
                              <CircularProgress color="inherit" size={22} />
                            ) : (
                              <>
                                {!!existingCustomer?.length && (
                                  <IconButton
                                    onClick={() => {
                                      formik.resetForm();
                                      setError(null);
                                    }}
                                    edge="end"
                                  >
                                    <Icon icon={closeFill} />
                                  </IconButton>
                                )}
                              </>
                            )}
                          </InputAdornment>
                        )
                      }}
                      error={Boolean(errors.phoneNumber)}
                      helperText={errors.phoneNumber}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      disabled={
                        error?.code === 'user/already-customer' ||
                        error?.code === 'user/different-role'
                      }
                      type="email"
                      label="Email"
                      {...getFieldProps('email')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      disabled={
                        !!existingCustomer?.length ||
                        error?.code === 'user/already-customer' ||
                        error?.code === 'user/different-role'
                      }
                      label="First Name"
                      {...getFieldProps('firstName')}
                      error={Boolean(touched.firstName && errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled={
                        !!existingCustomer?.length ||
                        error?.code === 'user/already-customer' ||
                        error?.code === 'user/different-role'
                      }
                      fullWidth
                      label="Last Name"
                      {...getFieldProps('lastName')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled={
                        error?.code === 'user/already-customer' ||
                        error?.code === 'user/different-role'
                      }
                      fullWidth
                      select
                      label="Sex"
                      {...getFieldProps('sex')}
                    >
                      {SEX.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        label="Date of birth"
                        inputFormat="dd/MM/yyyy"
                        value={dateValue}
                        onChange={handleDateChange}
                        disabled={
                          error?.code === 'user/already-customer' ||
                          error?.code === 'user/different-role'
                        }
                        renderInput={(params) => <TextField fullWidth {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled={
                        error?.code === 'user/already-customer' ||
                        error?.code === 'user/different-role'
                      }
                      fullWidth
                      select
                      label="Country"
                      {...getFieldProps('country')}
                    >
                      {COUNTRIES.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled={
                        error?.code === 'user/already-customer' ||
                        error?.code === 'user/different-role'
                      }
                      fullWidth
                      type="text"
                      label="City"
                      {...getFieldProps('city')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={
                        error?.code === 'user/already-customer' ||
                        error?.code === 'user/different-role'
                      }
                      fullWidth
                      label="Address"
                      multiline
                      rows={3}
                      {...getFieldProps('address')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LoadingButton
                      disabled={
                        error?.code === 'user/already-customer' ||
                        error?.code === 'user/different-role' ||
                        requesting.existingCustomer
                      }
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                      onClick={handleSubmit}
                    >
                      Save
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Form>
            </FormikProvider>
          </Container>
        </div>
      </Dialog>

      <Toaster ref={toastRef} />
    </div>
  );
}
