import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { isEmpty } from 'react-redux-firebase';

// material
import {
  Card,
  Box,
  Table,
  Stack,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  CircularProgress,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { ListHead, ListToolbar } from '../../components/_dashboard/owner/@general';

import { usePaymentHistory } from '../../hooks/owner';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'customer_name', label: 'Name', alignRight: false },
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'paid_on', label: 'Paid On', alignRight: false },
  { id: 'cost', label: 'Cost', alignRight: false },
  { id: 'amount_paid', label: 'Amount Paid', alignRight: false },
  { id: 'amount_left', label: 'Amount Left', alignRight: false },
  // { id: 'status', label: 'Status', alignRight: false },
];


const PaymentHistory = () => {
  const { searchedPaymentHistory } = useSelector((state) => state.firestore.ordered);
  const { requesting } = useSelector((state) => state.firestore.status);
  const { sewingsPaymentsCount } = useSelector((state) => state.firebase.profile);

  const { customerId } = useParams();

  // ----------------------------------------------------------------------

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('firstName');
  const [filterName, setFilterName] = useState('');
  const [cursor, setCursor] = useState(undefined);
  const paymentHistory = usePaymentHistory({
    startAfter: cursor,
    limit: rowsPerPage,
    customerId,
    filterName
  });

  // -----------------------------------------------------------------------

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setCursor(paymentHistory.data[paymentHistory.data.length - 1].createdAt);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  // ------------------------------------------------------------------

  useEffect(() => {
    if (requesting.searchedPaymentHistory) {
      if (page) setPage(0);
      else setPage(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requesting.searchedPaymentHistory]);

  useEffect(() => {
    setPage(0)
    setCursor(0)
  }, [customerId])

  // -----------------------------------------------------------------

  const emptyRows =
    page > 0
      ? Math.max(
        0,
        (1 + page) * rowsPerPage - filterName ? searchedPaymentHistory?.length : paymentHistory.data?.length
      )
      : 0;

  return (
    <Page title="Payment History | FashionsPal">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Payment History
          </Typography>
        </Stack>

        <Card>
          {!!paymentHistory.data.length && !customerId && (
            <ListToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
              requesting={requesting.searchedPaymentHistory}
            />
          )}

          {paymentHistory.loading || requesting.searchedPaymentHistory ? (
            <Box
              sx={{
                height: '60vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgress size={55} />
            </Box>
          ) : (
            <>
              {!paymentHistory.data.length ? (
                <Paper sx={{height: '60vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <Typography variant="subtitle1">No payments yet</Typography>
                </Paper>
              ) : (
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <ListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={filterName ? searchedPaymentHistory?.length || 0 : sewingsPaymentsCount || 0}
                        onRequestSort={handleRequestSort}
                      />
                      <TableBody>
                          {(filterName ? searchedPaymentHistory || paymentHistory.data : paymentHistory.data)
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            const { sewingData } = row;

                            return (
                              <TableRow
                                key={row.id}
                                tabIndex={-1}
                              >
                                <TableCell>
                                  <Typography variant="subtitle2" noWrap>
                                    {rowsPerPage * (page + 1) + (index + 1) - rowsPerPage}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  {`${sewingData?.customerData?.firstName} ${sewingData?.customerData?.lastName}` || 'N/A'}
                                </TableCell>
                                <TableCell align="left">
                                  {sewingData?.title}
                                </TableCell>
                                <TableCell align="left">
                                  <Typography variant="subtitle2">
                                    {row.createdAt ? format(row.createdAt.toDate(), 'dd MMM yyyy') : 'N/A'}
                                  </Typography>
                                  <Typography variant="body2">
                                    {row.createdAt ? format(row.createdAt.toDate(), 'p') : 'N/A'}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  ₵{row.cost ? row.cost : 'N/A'}
                                </TableCell>
                                <TableCell align="left">
                                  ₵{row.amountPaid ? row.amountPaid : 'N/A'}
                                </TableCell>
                                <TableCell align="left">
                                  ₵{sewingData.cost - sewingData.amountPaid}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      {isEmpty(searchedPaymentHistory) && filterName ? (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : null}
                    </Table>
                  </TableContainer>
                </Scrollbar>
              )}
            </>
          )}

          {!requesting.searchedPaymentHistory && !!paymentHistory.data.length && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={(filterName || customerId) ? searchedPaymentHistory?.length || paymentHistory.data.length || 0 : sewingsPaymentsCount || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>
      </Container>
    </Page>
  )
}

export default PaymentHistory