import PropTypes from 'prop-types';
import React from 'react';
import { useFormContext } from 'react-hook-form';
// @mui
import { Grid, Box, Autocomplete, TextField } from '@mui/material';
// utils
// ----------------------------------------------------------------------

const invCategory = [
  'Service',
  'Product',
];

InvoiceCategory.propTypes = {
  setCategory: PropTypes.func,
  // invoicePayments: PropTypes.object.isRequired,
};

export default function InvoiceCategory({ setCategory }) {
  //  const { control, setValue, watch } = useFormContext();

  //  const { fields, append, remove } = useFieldArray({
  //   control,
  //   name: 'items',
  // });

  // const [category, setCategory] = useState(invCategory[0]);

  const {
    watch,

  } = useFormContext();

  const handleCategory = (value) => {
    setCategory(value);
  };

  const values = watch();
  const { invoiceCategory} = values;

  return (
    <Box sx={{ p: 3 }}>
      <Grid item xs={12} sm={4} sx={{ pr: 1 }}>
        <Autocomplete
          disabled
          readOnly
          disablePortal
          id="combo-box-demo"
          options={invCategory}
          defaultValue={invoiceCategory}
          renderInput={(params) => <TextField {...params} label="Category" />}
          onChange={(event, value) => handleCategory(value)}
        />
      </Grid>
    </Box>
  );

}
