import * as Yup from 'yup';
import throttle from 'lodash/throttle';
import { useState, forwardRef, useEffect, useRef, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
import plusFill from '@iconify/icons-eva/plus-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import { /* alpha, */ styled } from '@mui/material/styles';
import { useFormik, Form, FormikProvider, FieldArray } from 'formik';
// material
import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Container,
  Grid,
  Box,
  Stack,
  Alert,
  MenuItem,
  TextField,
  InputAdornment,
  CircularProgress,
  Autocomplete
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import AddSortMeasurements from './AddSortMeasurements';

import { addSewing, updateSewing, recordMeasurement } from '../../../redux/slices/owner/sewings';

import { condenseText } from '../../../utils/formatText';
import { checkForRole } from '../../../utils/checkAuthUserRole';

// components
import { UploadMultiFile } from '../../upload';
import Toaster from '../../Toaster';

// ----------------------------------------------------------------------

AddSewDialog.propTypes = {
  children: PropTypes.node,
  isSewOpen: PropTypes.bool,
  setIsSewOpen: PropTypes.func,
  selectedSew: PropTypes.object,
  selectedCustomer: PropTypes.object
};

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

// ----------------------------------------------------------------------

const ChildrenButtonStyle = styled(Button)(() => ({
  width: '100%',
  padding: 0
}));

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1)
}));

// ----------------------------------------------------------------------

const STATUS = [
  {
    value: 'ordered',
    label: 'ordered'
  },
  {
    value: 'cutting',
    label: 'cutting'
  },
  {
    value: 'sewing',
    label: 'sewing'
  },
  {
    value: 'completed',
    label: 'completed'
  },
  {
    value: 'delivered',
    label: 'delivered'
  }
];

// ----------------------------------------------------------------------

export default function AddSewDialog({
  children,
  selectedSew,
  isSewOpen,
  setIsSewOpen,
  selectedCustomer
}) {
  const [open, setOpen] = useState(false);
  const [showMeasurements, setShowMeasurements] = useState(false);
  const [selectedMeasurement, setSelectedMeasurement] = useState('');
  const [customerValue, setCustomerValue] = useState(null);
  const [customersOptions, setCustomersOptions] = useState([]);
  const [customerInputValue, setCustomerInputValue] = useState('');
  const [isCustomersLoading, setIsCustomersLoading] = useState(false);
  const [staffValue, setStaffValue] = useState(null);
  const [staffOptions, setStaffOptions] = useState([]);
  const [staffInputValue, setStaffInputValue] = useState('');
  const [isStaffLoading, setIsStaffLoading] = useState(false);
  const [error, setError] = useState(null);
  const [measurementsListQuery, setMeasurementsListQuery] = useState({
    collection: 'measurementsList',
    where: [
      ['isActive', '==', true],
      ['parentId', '==', selectedMeasurement]
    ],
    storeAs: 'measurementsListItems'
  });
  const [measurementsParentQuery, setMeasurementsParentQuery] = useState({
    collection: 'measurementsParent',
    where: [
      ['isActive', '==', true],
      customerValue?.customerData.sex ?
        ['sex', '==', customerValue?.customerData.sex || null]
        :
        ['isActive', '==', true]
    ],
    storeAs: 'measurementsParentItems'
  });
  const [measurementRecordQuery, setMeasurementRecordQuery] = useState({
    collection: 'measurementsRecord',
    where: [
      ['ownerId', '==', null],
      ['parentId', '==', null]
    ],
    storeAs: 'measurementsRecord'
  });
  const [initialValues] = useState({
    customerName: '',
    title: '',
    description: '',
    staffName: '',
    fabrics: [],
    styles: [],
    duration: '',
    cost: '',
    amountPaid: '',
    items: [],
    status: 'ordered',
    measurements: {}
  });
  const [measurements, setMeasurements] = useState({});
  const [showSortMeasurements, setShowSortMeasurements] = useState(false);
  const [measurementsToEdit, setMeasurementsToEdit] = useState([]);
  const { customerMatch } = useParams();

  const sewContainerRef = useRef(null);
  const toastRef = useRef();

  // ----------------------------------------------------------------------

  const authUserUid = useSelector((state) => state.firebase.auth.uid);
  const { token, ownerId } = useSelector((state) => state.firebase.profile);
  const { measurementsParentItems } = useSelector((state) => state.firestore.ordered);
  const { measurementsListItems } = useSelector((state) => state.firestore.ordered);
  const { measurementsRecord } = useSelector((state) => state.firestore.ordered);
  const { requesting } = useSelector((state) => state.firestore.status);
  const dispatch = useDispatch();
  const firestore = useFirestore();

  const { identifier } = token && checkForRole(token);

  const ownerUid = identifier === 'staff' ? ownerId : authUserUid;

  // ----------------------------------------------------------------------

  const SewSchema = Yup.object().shape({
    customerName: Yup.string().required('Customer name is required'),
    title: Yup.string().required('Title is required'),
    items: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required('Name is required'),
          cost: Yup.number().required('Required'),
        })
      )
  });

  const formik = useFormik({
    initialValues,
    validationSchema: SewSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      // if (!Object.keys(values.measurements).length) {
      //   setError('Please select at least one measurement');
      //   return;
      // }

      if (Number(values.amountPaid) > Number(values.cost)) {
        return;
      }

      try {
        if (selectedSew) {
          const sewingInfo = {
            ...selectedSew,
            customerId: customerValue.customerId,
            staffId: identifier === 'staff' ? authUserUid : staffValue?.staffId || null,
            sewingData: {
              ...values,
              customerData: customerValue.customerData
            }
          };
          await dispatch(updateSewing(sewingInfo));
          toastRef.current.handleOpen('Sewing updated successfully');
        } else {
          const sewingInfo = {
            ownerId: ownerUid,
            customerId: customerValue.customerId,
            sewingData: {
              ...values,
              customerData: customerValue.customerData
            },
            deletedForCustomer: false,
            deletedForOwner: false,
            deletedForStaff: false,
            staffId: identifier === 'staff' ? authUserUid : staffValue?.staffId || null
          };
          await dispatch(addSewing(sewingInfo));
          toastRef.current.handleOpen('Sewing added successfully');
        }
        // reset form
        resetForm();
        setMeasurements({});
        setCustomerValue(null);
        setStaffValue(null);
        // hidden measurements select box if showing
        setShowMeasurements(false);
        // clsoe dialog
        handleClose();
      } catch (error) {
        sewContainerRef.current.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        setError(error.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  // ----------------------------------------------------------------------

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setError(null);
    setOpen(false);
    if (setIsSewOpen) setIsSewOpen(false);
  };

  const handleAddMeasurement = () => {
    setShowMeasurements(true);
  };

  const handleRemoveMeasurement = (key) => {
    // remove measurement from formik
    const newMeasurements = { ...measurements };
    delete newMeasurements[key];
    setMeasurements(newMeasurements);
  };

  const fetchCustomers = useMemo(
    () =>
      throttle(async (request /* , callback */) => {
        try {
          setIsCustomersLoading(true);
          setError(null);
          let snapshot;
          if (Number.isNaN(parseInt(request.input, 10))) {
            const keyword = condenseText(request.input);
            snapshot = await firestore.get({
              collection: 'customers',
              where: [
                ['ownerId', '==', ownerUid],
                ['customerData.searchMatch', '>=', keyword],
                ['customerData.searchMatch', '<', `${keyword}z`]
              ],
              limit: 15
            });
          } else {
            const parsedValue = request.input.toString();
            snapshot = await firestore.get({
              collection: 'customers',
              where: [
                ['ownerId', '==', ownerUid],
                ['customerData.phoneNumber', '>=', parsedValue],
                ['customerData.phoneNumber', '<', `${parsedValue}z`]
              ],
              limit: 15
            });
          }
          const results = snapshot.docs.filter((doc) => doc.exists).map((x) => x.data()) || [];
          setCustomersOptions([...results]);
          return results;
        } catch (error) {
          setError(error.message);
        } finally {
          setIsCustomersLoading(false);
        }
        return undefined;
      }, 200),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authUserUid, firestore]
  );

  const fetchStaff = useMemo(
    () =>
      throttle(async (request /* , callback */) => {
        try {
          setIsStaffLoading(true);
          setError(null);
          let snapshot;
          if (Number.isNaN(parseInt(request.input, 10))) {
            const keyword = condenseText(request.input);
            snapshot = await firestore.get({
              collection: 'staff',
              where: [
                ['ownerId', '==', ownerUid],
                ['staffData.searchMatch', '>=', keyword],
                ['staffData.searchMatch', '<', `${keyword}z`]
              ],
              limit: 15
            });
          } else {
            const parsedValue = request.input.toString();
            snapshot = await firestore.get({
              collection: 'staff',
              where: [
                ['ownerId', '==', ownerUid],
                ['staffData.phoneNumber', '>=', parsedValue],
                ['staffData.phoneNumber', '<', `${parsedValue}z`]
              ],
              limit: 15
            });
          }
          const results = snapshot.docs.filter((doc) => doc.exists).map((x) => x.data()) || [];
          setStaffOptions([...results]);
          return results;
        } catch (error) {
          setError(error.message);
        } finally {
          setIsStaffLoading(false);
        }
        return undefined;
      }, 200),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authUserUid, firestore]
  );

  const getParentName = (parentId) => {
    if (!measurementsParentItems.length) return '';
    const parent = measurementsParentItems.find((item) => item.id === parentId);
    return parent ? parent.parentTitle : parentId;
  };

  const handleFabricDrop = useCallback(
    (acceptedFiles) => {
      formik.setFieldValue('fabrics', [
        ...formik.values.fabrics,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      ]);
    },
    [formik]
  );

  const handleFabricRemove = (file) => {
    const filteredItems = formik.values.fabrics?.filter((_file) => _file !== file);
    formik.setFieldValue('fabrics', filteredItems);
  };

  const handleStyleDrop = useCallback(
    (acceptedFiles) => {
      formik.setFieldValue('styles', [
        ...formik.values.styles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      ]);
    },
    [formik]
  );

  const handleStyleRemove = (file) => {
    const filteredItems = formik.values.styles?.filter((_file) => _file !== file);
    formik.setFieldValue('styles', filteredItems);
  };

  const setSortedMeasurements  = (items) => {
    const newMeasurement = items
      .filter((measurement) => measurement.parentId === selectedMeasurement)
      .map((measurementItem) => ({ [measurementItem.name]: '' }))

    const measurementRecord = {
      measurements: newMeasurement,
      parentId: selectedMeasurement,
      ownerId: ownerUid,
    }

    setMeasurements((oldValue) => ({
      ...oldValue,
      [selectedMeasurement]: newMeasurement
    }));

    try {
      dispatch(recordMeasurement(measurementRecord));
    } catch (error) {
      console.log(error)
    }
  }

  const handleEditMeasurement = (key) => {
    const msms = measurements[key]
      .map((i, idx) => ({
        parentId: key,
        name: Object.keys(i)[0],
        id: `${Object.keys(i)[0]}-${idx}`
      }));
    setMeasurementsToEdit(msms);
    setSelectedMeasurement(key);
  }

  const reqMeasurementsList = () => {
    setMeasurementsListQuery({
      collection: 'measurementsList',
      where: [
        ['isActive', '==', true],
        ['parentId', '==', selectedMeasurement]
      ],
      storeAs: 'measurementsListItems'
    });
  }

  const reqMeasurementRecord = () => {
    setMeasurementRecordQuery({
      collection: 'measurementsRecord',
      where: [
        ['ownerId', '==', ownerUid],
        ['parentId', '==', selectedMeasurement]
      ],
      storeAs: 'measurementsRecord'
    });
  }

  const handleLoadPrevious = async (key) => {
    const prevSnap = await firestore.collection("customersRecords")
      .doc(customerValue.customerId)
      .get()

    const prevData = prevSnap.data()

    if (prevSnap.exists) {
      if (prevData.measurements[key]) {
        const normalizedPrevData = prevData.measurements[key].reduce((obj, item) => ({ ...obj, ...item }), {})

        const updatedData = measurements[key].map(i => {
          const currKey = Object.keys(i)[0]
          return {
            [currKey]: normalizedPrevData[currKey]
          }
        })
        setMeasurements((prev) => ({
          ...prev,
          [key]: updatedData
        }))
      } else {
        // eslint-disable-next-line no-alert
        alert('No previous data found')
      }

    } else {
      // eslint-disable-next-line no-alert
      alert('No previous data found')
    }
  }

  // ----------------------------------------------------------------------

  // watch for selected measurement change
  useEffect(() => {
    reqMeasurementRecord();

    setShowMeasurements(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMeasurement]);

  // watch measurements change
  useEffect(() => {
    formik.setFieldValue('measurements', measurements);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measurements]);

  useEffect(() => {
    if (measurementsListItems && measurementsListItems.length) {
      setShowSortMeasurements(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measurementsListItems]);

  // watch isSewOpen change
  useEffect(() => {
    async function reqDefaultValue() {
      const customerRes = await fetchCustomers({ input: selectedSew.sewingData.customerName });
      setCustomerValue(customerRes[0]);

      if(selectedSew.sewingData.staffName) {
        const staffRes = await fetchStaff({ input: selectedSew.sewingData.staffName });
        setStaffValue(staffRes[0]);
      }
    }

    if (isSewOpen) {
      setOpen(true);
      if (selectedSew) {
        reqDefaultValue();
        formik.setValues(selectedSew.sewingData);
        setMeasurements(selectedSew.sewingData.measurements);
      }
      if (selectedCustomer) {
        setCustomerValue(selectedCustomer);
        const { firstName, lastName } = selectedCustomer.customerData;
        formik.setFieldValue('customerName', `${firstName} ${lastName}`);
      }
    } else {
      formik.resetForm();
      setMeasurements({});
      setCustomerValue(null);
      setStaffValue(null);
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSewOpen]);

  useEffect(() => {
    if (customerInputValue === '') {
      setCustomersOptions(customerValue ? [customerValue] : []);
      return undefined;
    }

    if (selectedSew) {
      setCustomersOptions(() => {
        const allResults = [...customersOptions];
        const arrUniq = [...new Map(allResults.map((v) => [v.customerId, v])).values()];
        return arrUniq;
      });
    }

    fetchCustomers({ input: customerInputValue });

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerInputValue]);

  useEffect(() => {
    if (customerValue) {
      // if (!selectedSew) setMeasurements({});
      const { firstName, lastName } = customerValue.customerData;
      formik.setFieldValue('customerName', `${firstName} ${lastName}`);

      const { sex } = customerValue.customerData;

      setMeasurementsParentQuery({
        collection: 'measurementsParent',
        where: [
          ['isActive', '==', true],
          sex !== 'other' ? ['sex', '==', sex] : ['isActive', '==', true]
        ],
        storeAs: 'measurementsParentItems'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerValue]);

  useEffect(() => {
    if (staffInputValue === '') {
      setStaffOptions(staffValue ? [staffValue] : []);
      return undefined;
    }

    if (selectedSew) {
      setStaffOptions(() => {
        const allResults = [...staffOptions];
        const arrUniq = [...new Map(allResults.map((v) => [v.staffId, v])).values()];
        return arrUniq;
      });
    }

    fetchStaff({ input: staffInputValue });

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffInputValue]);

  useEffect(() => {
    if (staffValue) {
      const { firstName, lastName } = staffValue.staffData;
      formik.setFieldValue('staffName', `${firstName} ${lastName}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffValue]);

  useEffect(() => {
    if (customerValue) {
      const { sex } = customerValue.customerData;

      if (!requesting.measurementsParentItems && measurementsParentItems && sex !== 'other') {
        Object.keys(measurements).forEach((m) => {
          const index = measurementsParentItems.findIndex((item) => item.id === m);
          if (index === -1) {
            const newMeasurements = { ...measurements };
            delete newMeasurements[m];
            setMeasurements(newMeasurements);
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requesting.measurementsParentItems]);

  useEffect(() => {
    if (!showSortMeasurements) {
      setSelectedMeasurement('');
      setMeasurementsToEdit([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSortMeasurements]);

  useEffect(() => {
    if (!requesting.measurementsRecord) {
      if (measurementsRecord && measurementsRecord.length && !measurementsToEdit.length) {
        const msr = measurementsRecord[0];
        setMeasurements((oldValue) => ({
          ...oldValue,
          [msr.parentId]: msr.measurements
        }));
        setSelectedMeasurement('');
      } else {
        reqMeasurementsList();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requesting.measurementsRecord])

  useEffect(() => {
    if (customerMatch) setCustomerValue(customersOptions[0])
  }, [customerMatch, customersOptions])

  useEffect(() => {
    const total = formik.values.items.reduce((total, currVal) => total + currVal.cost, 0)
    formik.setFieldValue('cost', total);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.items])

  useEffect(() => {
    if (customerMatch) {
      setCustomerInputValue(customerMatch)
    }
  }, [])

  // --------------------------------------------------------------------------------

  useFirestoreConnect(() => [measurementsParentQuery, measurementsListQuery, measurementRecordQuery]);

  // ----------------------------------------------------------------------

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, handleChange } =
    formik;

  return (
    <div>
      <ChildrenButtonStyle onClick={handleClickOpen}>{children}</ChildrenButtonStyle>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        scroll="paper"
        TransitionComponent={Transition}
      >
        <div ref={sewContainerRef} style={{ overflowY: 'auto' }}>
          <AppBar sx={{ position: 'sticky' }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <Icon icon={closeFill} width={24} height={24} />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {`${selectedSew ? 'Edit' : 'Add'} Order`}
              </Typography>
            </Toolbar>
          </AppBar>
          <Container maxWidth="md" sx={{ pb: 5 }}>
            <Box sx={{ pb: 3, pt: 4 }}>
              <Typography variant="h4"> {`${selectedSew ? 'Edit' : 'Add New'} Order`}</Typography>
            </Box>
            {error && (
              <Alert sx={{ mt: -1.5, mb: 3 }} severity="error">
                {error}
              </Alert>
            )}
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate>
                    <Stack spacing={3}>
                      <Autocomplete
                        getOptionLabel={(option) => {
                          if (option && option.customerData) {
                            const { firstName, lastName } = option.customerData;
                            return firstName ? `${firstName} ${lastName}` : '';
                          }
                          return option;
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.customerId === value.customerId
                        }
                        filterOptions={(x) => x}
                        options={customersOptions}
                        autoComplete
                        disabled={!!selectedCustomer}
                        includeInputInList
                        filterSelectedOptions
                        value={customerValue}
                        onChange={(event, newValue) => {
                          setCustomersOptions(
                            newValue ? [newValue, ...customersOptions] : customersOptions
                          );
                          setCustomerValue(newValue);
                          if (newValue) {
                            const { firstName, lastName } = newValue.customerData;
                            formik.setFieldValue('customerName', `${firstName} ${lastName}`);
                          } else {
                            formik.setFieldValue('customerName', '');
                          }
                        }}
                        onInputChange={(event, newInputValue) => {
                          setCustomerInputValue(newInputValue);
                        }}
                        loading={isCustomersLoading}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Customer(s)"
                            onChange={() => {
                              if (customerValue) {
                                const { firstName, lastName } = customerValue.customerData;
                                formik.setFieldValue('customerName', `${firstName} ${lastName}`);
                              } else {
                                formik.setFieldValue('customerName', '');
                              }
                            }}
                            error={Boolean(touched.customerName && errors.customerName)}
                            helperText={touched.customerName && errors.customerName}
                            InputProps={{
                              ...params.InputProps,
                              required: !!customerValue,
                              endAdornment: (
                                <>
                                  {isCustomersLoading ? (
                                    <CircularProgress color="inherit" size={20} />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              )
                            }}
                          />
                        )}
                      />
                      <TextField
                        fullWidth
                        type="text"
                        label="Title"
                        {...getFieldProps('title')}
                        error={Boolean(touched.title && errors.title)}
                        helperText={touched.title && errors.title}
                      />
                      <TextField
                        label="Description"
                        multiline
                        rows={4}
                        {...getFieldProps('description')}
                      />
                      { identifier !== 'staff' && (
                        <Autocomplete
                          getOptionLabel={(option) => {
                            if (option && option.staffData) {
                              const { firstName, lastName } = option.staffData;
                              return firstName ? `${firstName} ${lastName}` : '';
                            }
                            return option;
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option.staffId === value.staffId
                          }
                          filterOptions={(x) => x}
                          options={staffOptions}
                          autoComplete
                          includeInputInList
                          filterSelectedOptions
                          value={staffValue}
                          onChange={(event, newValue) => {
                            setStaffOptions(
                              newValue ? [newValue, ...staffOptions] : staffOptions
                            );
                            setStaffValue(newValue);
                            if (newValue) {
                              const { firstName, lastName } = newValue.staffData;
                              formik.setFieldValue('staffName', `${firstName} ${lastName}`);
                            } else {
                              formik.setFieldValue('staffName', '');
                            }
                          }}
                          onInputChange={(event, newInputValue) => {
                            setStaffInputValue(newInputValue);
                          }}
                          loading={isStaffLoading}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Assigned To"
                              onChange={() => {
                                if (staffValue) {
                                  const { firstName, lastName } = staffValue.staffData;
                                  formik.setFieldValue('staffName', `${firstName} ${lastName}`);
                                } else {
                                  formik.setFieldValue('staffName', '');
                                }
                              }}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {isStaffLoading ? (
                                      <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                )
                              }}
                            />
                          )}
                        />)
                      }
                      <Grid item xs={12}>
                        <FieldArray
                          name="items"
                          render={arrayHelpers => (
                            <>
                              <Stack direction="row" justifyContent="space-between" sx={{mb: 2}} alignItems="center">
                                <LabelStyle style={{ marginBottom: 0 }}>Items</LabelStyle>
                                <Button  onClick={() => arrayHelpers.push({ name: '', cost: '' })} startIcon={<Icon icon={plusFill} />}>Add Item</Button>
                              </Stack>
                              <div>
                                {values.items.map((item, index) => (
                                  <div key={index}>
                                    <Grid container spacing={3}>
                                      <Grid item xs={12} sm={8}>
                                        <TextField
                                          fullWidth
                                          type="text"
                                          label="Item"
                                          {...getFieldProps(`items[${index}].name`)}
                                          error={Boolean((touched.items || [])[index]?.name && (errors.items || [])[index]?.name)}
                                          helperText={(touched.items || [])[index]?.name && (errors.items || [])[index]?.name}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={4}>
                                        <TextField
                                          fullWidth
                                          type="number"
                                          label="Cost"
                                          {...getFieldProps(`items[${index}].cost`)}
                                          error={Boolean((touched.items || [])[index]?.cost && (errors.items || [])[index]?.cost)}
                                          helperText={(touched.items || [])[index]?.cost && (errors.items || [])[index]?.cost}
                                          InputProps={{
                                            startAdornment: <InputAdornment position="start">₵</InputAdornment>
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                    <Stack direction="row" justifyContent="flex-end">
                                      <Button 
                                        sx={{zIndex: 99, position: "relative", mb: 0.5, mt: 0.5 }} 
                                        onClick={() => {arrayHelpers.remove(index)}}
                                        size="small"
                                        color="error"
                                      >
                                        Remove
                                      </Button>
                                    </Stack>
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              disabled={!!values.items.length}
                              type="number"
                              label="Total Cost"
                              {...getFieldProps('cost')}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">₵</InputAdornment>
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              type="number"
                              label="Amount Paid"
                              disabled={!values.cost || !!selectedSew}
                              {...getFieldProps('amountPaid')}
                              error={!!(Number(values.amountPaid) > Number(values.cost))}
                              helperText={
                                Number(values.amountPaid) > Number(values.cost)
                                  ? 'cannot be greater than cost'
                                  : ''
                              }
                              InputProps={{
                                startAdornment: <InputAdornment position="start">₵</InputAdornment>
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              select
                              label="Sewing Status"
                              {...getFieldProps('status')}
                            >
                              {STATUS.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              type="number"
                              label="Duration"
                              {...getFieldProps('duration')}
                              InputProps={{
                                endAdornment: <InputAdornment position="end">days</InputAdornment>
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <LabelStyle>Fabric</LabelStyle>
                      <UploadMultiFile
                        name="fabric"
                        showPreview
                        accept="image/*"
                        maxSize={3145728}
                        onDrop={handleFabricDrop}
                        onRemove={handleFabricRemove}
                        files={formik.values.fabrics}
                      />
                      <LabelStyle>Style</LabelStyle>
                      <UploadMultiFile
                        name="style"
                        showPreview
                        accept="image/*"
                        maxSize={3145728}
                        onDrop={handleStyleDrop}
                        onRemove={handleStyleRemove}
                        files={formik.values.styles}
                      />
                    </Stack>
                  </Form>
                </FormikProvider>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={1}>
                  {Object.keys(measurements).map((key) => (
                    <Grid key={key} item xs={12} sx={{ mb: 1.5 }}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography
                          variant="subtitle2"
                          component="p"
                          style={{ marginBottom: '8px' }}
                        >
                          {getParentName(key)}
                        </Typography>
                        <Box>
                          <Button
                            size="small"
                            sx={{ mb: 1 }}
                            onClick={() => handleEditMeasurement(key)}
                          >
                            edit
                          </Button>
                          <Button
                            onClick={() => handleRemoveMeasurement(key)}
                            size="small"
                            color="error"
                            sx={{ mb: 1 }}
                          >
                            remove
                          </Button>
                        </Box>
                      </Grid>
                      <Grid container spacing={1}>
                        {(values?.measurements[key] || []).map((measurement, index) => (
                          <Grid key={index} item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              type="text"
                              label={Object.keys(measurement) ? Object.keys(measurement)[0] : ''}
                              name={`measurements[${[key]}][${[index]}]${[
                                Object.keys(measurement) ? Object.keys(measurement)[0] : ''
                              ]}`}
                              onChange={handleChange}
                              value={
                                Object.keys(values?.measurements).length && values?.measurements[key]
                                  ? values?.measurements[key][index][Object.keys(measurement)[0]]
                                  : ''
                              }
                            />
                          </Grid>
                        ))}
                      </Grid>
                      <Button
                        size="small"
                        sx={{ mt: 1, float: 'right' }}
                        onClick={() => handleLoadPrevious(key)}
                      >
                        load previous
                      </Button>
                    </Grid>
                  ))}
                </Grid>
                {requesting.measurementsListItems || requesting.measurementsRecord ? (
                  <Box sx={{ textAlign: 'center', mt: 1.5 }}>
                    <CircularProgress size={20} />
                  </Box>
                ) : (
                  showMeasurements &&
                  customerValue && (
                    <TextField
                      fullWidth
                      select
                      disabled={!customerValue}
                      label="Measurement"
                      type="text"
                      onChange={(e) => {
                        setSelectedMeasurement(e.target.value);
                      }}
                      value={selectedMeasurement}
                      sx={{ mb: 0.5, mt: 1.5 }}
                    >
                      {measurementsParentItems.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.parentTitle}
                        </MenuItem>
                      ))}
                    </TextField>
                  )
                )}
                <Button
                  fullWidth
                  disabled={!customerValue}
                  size="large"
                  variant="contained"
                  component="div"
                  onClick={handleAddMeasurement}
                  startIcon={<Icon icon={plusFill} />}
                  sx={{ mt: 1.3, mb: 2.5 }}
                >
                  Add Measurement
                </Button>
                <LoadingButton
                  fullWidth
                  disabled={!customerValue}
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  onClick={handleSubmit}
                >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </Container>
        </div>
      </Dialog>

      <AddSortMeasurements
        isOpen={showSortMeasurements}
        setIsOpen={setShowSortMeasurements}
        measurements={measurementsListItems}
        setSortedMeasurements={setSortedMeasurements}
        sortedMeasurements={measurementsToEdit}
      />
      <Toaster ref={toastRef} />
    </div>
  );
}
