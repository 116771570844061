import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
// form
import { useFormik, Form, FormikProvider } from 'formik';
// @mui
import { Stack, Card, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../../components/Iconify';

import { updateAccount } from '../../../../redux/slices/owner/account';

// ----------------------------------------------------------------------

const SOCIAL_LINKS = [
  {
    value: 'facebookLink',
    icon: <Iconify icon="eva:facebook-fill" width={24} height={24} />
  },
  {
    value: 'instagramLink',
    icon: <Iconify icon="ant-design:instagram-filled" width={24} height={24} />
  },
  {
    value: 'linkedinLink',
    icon: <Iconify icon="eva:linkedin-fill" width={24} height={24} />
  },
  {
    value: 'twitterLink',
    icon: <Iconify icon="eva:twitter-fill" width={24} height={24} />
  }
];

// ----------------------------------------------------------------------

export default function AccountSocialLinks() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const uid = useSelector((state) => state.firebase.auth.uid);
  const profile = useSelector((state) => state.firebase.profile);

  const defaultValues = {
    facebookLink: profile.facebookLink || '',
    instagramLink: profile.instagramLink || '',
    linkedinLink: profile.linkedinLink || '',
    twitterLink: profile.twitterLink || ''
  };

  const formik = useFormik({
    initialValues: defaultValues,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await dispatch(updateAccount({ accountInfo: values, id: uid }));
        enqueueSnackbar('Your account has been updated', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
      setSubmitting(false);
    }
  });

  const { handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <Card sx={{ p: 3 }}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3} alignItems="flex-end">
            {SOCIAL_LINKS.map((link) => (
              <TextField
                fullWidth
                key={link.value}
                {...getFieldProps(link.value)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{link.icon}</InputAdornment>
                }}
              />
            ))}

            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              Save Changes
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </Card>
  );
}
