import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography, Grid } from '@mui/material';
// layouts
// import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';
// import AuthSocial from '../components/authentication/AuthSocial';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(() => ({
  width: '100%',
  maxWidth: '45vw',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100vh',
  borderRadius: '0',
  position: 'fixed'
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const imgStyles = {
  objectFit: 'cover',
  height: '100vh'
};

// const dwnImgStyles = {
//   width: '180px'
// };

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <RootStyle title="Login | FashionsPal">
      {/* <AuthLayout>
        Don’t have an account? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/register">
          Register/Create account
        </Link>
      </AuthLayout> */}

      <MHidden width="mdDown">
        <div style={{ marginRight: '45vw' }}>
          <SectionStyle>
            <img src="/static/illustrations/register.jpg" alt="login" style={imgStyles} />
          </SectionStyle>
        </div>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Sign in to FashionPal
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
          </Stack>
          {/* <AuthSocial /> */}

          <LoginForm />

          {/* <MHidden width="smUp"> */}
          <Typography variant="body2" align="center" sx={{ mt: 3 }}>
            Don’t have an account?&nbsp;
            <Link variant="subtitle2" component={RouterLink} to="/register">
              Register/Create account
            </Link>
          </Typography>
          {/* </MHidden> */}
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{ marginTop: '0.7rem' }}
          >
            <Grid item xs={4}>
              <a
                href="https://play.google.com/store/apps/details?id=com.fashion.pal"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/static/illustrations/playstore.png" alt="login" />
              </a>
            </Grid>
            <Grid item xs={4}>
              <a
                href="https://apps.apple.com/gh/app/myfashionpal/id1613520393"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/static/illustrations/appstore.png" alt="login" />
              </a>
            </Grid>
          </Grid>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
