import * as React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { sentenceCase } from 'change-case';
import { styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';

// import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import IconButton from '@mui/material/IconButton';

import Label from '../../../Label';

import { fDate } from '../../../../utils/formatTime';

// --------------------------------------------------

SewViewDialog.propTypes = {
  isOpen: PropTypes.bool,
  sew: PropTypes.object,
  setIsOpen: PropTypes.func
};

// --------------------------------------------------

const ImageListStyles = styled(ImageList)({
  position: 'relative',
  listStyle: 'none',
  // marginTop: '12px',
  padding: '0px',
  display: 'flex'
});

const ThumbBoxStyles = styled(Box)({
  top: '0px',
  left: '0px',
  right: '0px',
  bottom: '0px',
  lineHeight: 0,
  position: 'absolute',
  backgroundSize: 'cover !important'
});

const ImageListItemStyles = styled(ImageListItem)({
  justifyContent: 'flex-start',
  alignItems: 'center',
  boxSizing: 'border-box',
  textAlign: 'left',
  minWidth: '80px !important',
  height: '80px !important',
  position: 'relative',
  display: 'inline-flex',
  textDecoration: 'none',
  padding: '0px',
  margin: '4px',
  borderRadius: '10px',
  overflow: 'hidden',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'rgba(145, 158, 171, 0.24)',
  borderImage: 'initial'
});

const imgStyles = {
  width: '100%',
  height: '100%',
  objectFit: 'cover'
};

// --------------------------------------------------

export default function SewViewDialog({ sew, isOpen, setIsOpen }) {
  const { users } = useSelector((state) => state.firestore.data);

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = React.useCallback(() => {
    setOpen(false);
    setIsOpen(false);
  }, [setIsOpen]);

  // --------------------------------------------------

  React.useEffect(() => {
    if (isOpen) handleOpen();
    else handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClose, isOpen]);

  // --------------------------------------------------

  const fabricsThumb = sew.sewingData.fabrics.map((file) => (
    <ImageListItemStyles key={file.name || file}>
      <ThumbBoxStyles>
        <img
          src={file.preview || file}
          style={imgStyles}
          srcSet={file.preview || file}
          alt="preview"
          loading="lazy"
        />
      </ThumbBoxStyles>
    </ImageListItemStyles>
  ));

  const stylesThumb = sew.sewingData.styles.map((file) => (
    <ImageListItemStyles key={file.name || file}>
      <ThumbBoxStyles>
        <img
          src={file.preview || file}
          style={imgStyles}
          srcSet={file.preview || file}
          alt="preview"
          loading="lazy"
        />
      </ThumbBoxStyles>
    </ImageListItemStyles>
  ));

  // --------------------------------------------------
  const {
    title,
    customerName,
    description,
    cost,
    amountPaid,
    status,
    duration,
    fabrics,
    styles,
    measurements,
    createdAt
  } = sew.sewingData;
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {title}
          <IconButton
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Icon icon={closeFill} width={24} height={24} />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Owner
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {users ? users[sew.ownerId]?.companyName || 'N/A' : 'N/A'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Owner's Name
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {users
                    ? `${users[sew.ownerId]?.firstName} ${users[sew.ownerId]?.lastName}`
                    : 'N/A'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Owner's Contact
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {users ? users[sew.ownerId]?.phoneNumber || 'N/A' : 'N/A'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Customer's Name
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {customerName}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Description
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {description || 'N/A'}
                </Typography>
              </Box>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Typography variant="subtitle1" component="p">
                      Cost
                    </Typography>
                    <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                      {cost ? `₵ ${cost}` : 'N/A'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Typography variant="subtitle1" component="p">
                      Amount Paid
                    </Typography>
                    <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                      {amountPaid ? `₵ ${amountPaid}` : 'N/A'}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Typography variant="subtitle1" component="p">
                      Status
                    </Typography>
                    <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                      <Label
                        variant="ghost"
                        color={
                          (status === 'ordered' && 'error') ||
                          ((status === 'completed' || status === 'delivered') && 'success') ||
                          'warning'
                        }
                      >
                        {sentenceCase(status)}
                      </Label>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Typography variant="subtitle1" component="p">
                      Duration
                    </Typography>
                    <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                      {`${duration ? `${duration} days` : 'N/A'}`}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <Typography variant="subtitle1" component="p">
                    Created on
                  </Typography>
                  <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                    {createdAt ? fDate(createdAt.toDate()) : 'N/A'}
                  </Typography>
                </Box>
              </Grid>
              {!!fabrics.length && (
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle1" component="p">
                    Fabrics
                  </Typography>
                  <ImageListStyles>{fabricsThumb}</ImageListStyles>
                </Box>
              )}
              {!!styles.length && (
                <Box>
                  <Typography variant="subtitle1" component="p">
                    Styles
                  </Typography>
                  <ImageListStyles>{stylesThumb}</ImageListStyles>
                </Box>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {Object.keys(measurements).map((key) => (
                <Grid key={key} item xs={12} sx={{ mb: 1.5 }}>
                  <Grid container>
                    <Typography variant="subtitle1" component="p">
                      {key}
                    </Typography>
                  </Grid>
                  <Grid container spacing={1} sx={{ mb: 4 }}>
                    {measurements[key].map((measurement, index) => (
                      <Grid key={index} item xs={12} sm={6}>
                        <Typography variant="subtitle2" component="p">
                          {Object.keys(measurement)[0]}
                        </Typography>
                        <Typography variant="body2" component="p">
                          {measurements[key][index][Object.keys(measurement)[0]] || 'n/a'}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
