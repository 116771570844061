import * as React from 'react';
import Proptypes from 'prop-types';
import {
  Button,
  Alert,
  Dialog,
  Grid,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  List,
  Card,
  CardHeader,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Checkbox
} from '@mui/material';

AddSortMeasurements.propTypes = {
  measurements: Proptypes.array,
  setIsOpen: Proptypes.func,
  isOpen: Proptypes.bool,
  setSortedMeasurements: Proptypes.func,
  sortedMeasurements: Proptypes.array,
};

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const not = (a, b) => a.filter((value) => b.map(i => i.name).indexOf(value.name) === -1)

const intersection = (a, b) => a.filter((value) => b.map(i => i.name).indexOf(value.name) !== -1)

const union = (a, b) => [...a, ...not(b, a)]

export default function AddSortMeasurements({
  measurements, isOpen, setIsOpen, setSortedMeasurements, sortedMeasurements
}) {
  const [open, setOpen] = React.useState(false);
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const [error, setError] = React.useState(false);

  // ----------------------------------------------------------------------

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  // ----------------------------------------------------------------------

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = React.useCallback(() => {
    setOpen(false);
    setIsOpen(false);
    setError(false);
  }, [setIsOpen]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.map(i => i.name).indexOf(value.name);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAdd = () => {
    if(right.length < 1) {
      setError(true);
      return;
    }

    setSortedMeasurements(right);
    handleClose();
  }

  const customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={
              numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: 200,
          height: 230,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value.id}-label`;

          return (
            <ListItem
              key={value.id}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.map(i => i.name).indexOf(value.name) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.name} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  // ----------------------------------------------------------------------

  React.useEffect(() => {
    if (isOpen) handleOpen();
    else handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClose, isOpen]);


  React.useEffect(() => {
    const newLeft = (measurements || [])
      .filter(measurement => !sortedMeasurements.map(i => i.name).includes(measurement.name));
    setLeft(newLeft || []);
    setRight(sortedMeasurements || []);
    setChecked([]);
  }, [measurements, sortedMeasurements]);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{pb: 2}}>Sort Measurements</DialogTitle>
        {error && <Alert severity="error">Please choose at least one measurement</Alert>}
        <DialogContent>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item>{customList('Choices', left)}</Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
              </Grid>
            </Grid>
            <Grid item>{customList('Chosen', right)}</Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleAdd}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
