import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  fPhoneNumber,
  fPhoneNumberToEmail,
  stringfyPhoneNumber
} from '../../utils/formatPhoneNumber';
import { condenseName } from '../../utils/formatText';

export const signup = createAsyncThunk(
  'auth/register',
  async (credentials, { extra: { getFirebase } }) => {
    const firebase = getFirebase();
    const { phoneNumber } = credentials;
    const formattedPhoneNumber = fPhoneNumber(phoneNumber);
    const formattedPhoneNumberToEmail = fPhoneNumberToEmail(phoneNumber);

    const checkIfUserExists = await firebase
      .auth()
      .fetchSignInMethodsForEmail(formattedPhoneNumberToEmail);

    if (checkIfUserExists.length > 0) throw new Error('This phone number is already exist');

    const appVerifier = window.recaptchaVerifier;

    const confirmationResult = await firebase
      .auth()
      .signInWithPhoneNumber(formattedPhoneNumber, appVerifier);
    window.confirmationResult = confirmationResult;
    localStorage.setItem('u_t_b_s', JSON.stringify(credentials));
    return credentials;
  }
);

export const verification = createAsyncThunk(
  'auth/verify',
  async (data, { getState, extra: { getFirebase, getFirestore } }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const { code } = data;
    const timestamp = firestore.FieldValue.serverTimestamp();

    const result = await window.confirmationResult.confirm(code);

    const { phoneNumber, password, lastName, firstName, companyName, address, profileImage } =
      getState().auth.userToBeSignedUp;
    const formattedPhoneNumber = fPhoneNumberToEmail(phoneNumber);

    const credential = await firebase.auth.EmailAuthProvider.credential(
      formattedPhoneNumber,
      password
    );

    await result.user.linkWithCredential(credential);

    const addAdminRole = firebase.functions().httpsCallable('addAdminRole');
    await addAdminRole({ email: formattedPhoneNumber });

    await firestore.set(
      { collection: 'users', doc: result.user.uid },
      {
        firstName,
        lastName,
        phoneNumber: stringfyPhoneNumber(phoneNumber),
        companyName,
        address,
        profileImage,
        searchMatch: condenseName(firstName, lastName),
        customersCount: 0,
        sewingsCount: 0,
        totalIncome: 0,
        totalExpenditure: 0,
        isEnabled: true,
        role: 'owner',
        senderId: 'fashionspal',
        createdAt: timestamp,
        updatedAt: timestamp
      }
    );

    localStorage.removeItem('u_t_b_s');
    return result;
  }
);

export const signin = createAsyncThunk(
  'auth/login',
  async (credentials, { extra: { getFirebase } }) => {
    const firebase = getFirebase();
    const { phoneNumber, password } = credentials;
    const formattedPhoneNumber = fPhoneNumberToEmail(phoneNumber);

    const response = await firebase
      .auth()
      .signInWithEmailAndPassword(formattedPhoneNumber, password);
    return response;
  }
);

export const signout = createAsyncThunk(
  'auth/logout',
  async (credentials, { extra: { getFirebase } }) => {
    const firebase = getFirebase();
    firebase.auth().signOut();
  }
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (credentials, { extra: { getFirebase } }) => {
    const firebase = getFirebase();
    const { phoneNumber } = credentials;
    const formattedPhoneNumber = fPhoneNumber(phoneNumber);
    const appVerifier = window.recaptchaVerifier;

    const confirmationResult = await firebase
      .auth()
      .signInWithPhoneNumber(formattedPhoneNumber, appVerifier);
    window.confirmationResult = confirmationResult;
  }
);

export const newPassword = createAsyncThunk(
  'auth/newPassword',
  async (credentials, { extra: { getFirebase } }) => {
    const firebase = getFirebase();
    const { code, password } = credentials;
    // const result = await window.confirmationResult.confirm(code);
    const credential = await firebase.auth.PhoneAuthProvider.credential(
      window.confirmationResult.verificationId,
      code
    );
    const user = await firebase.auth().signInWithCredential(credential);
    console.log(user);
    // await result.user.updatePassword(password);
  }
);

const initialState = {
  error: null,
  userToBeSignedUp: null
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(signup.fulfilled, (state, { payload }) => {
      const storedCredentials = JSON.parse(localStorage.getItem('u_t_b_s'));
      state.userToBeSignedUp = payload || JSON.parse(storedCredentials);
    });

    builder.addCase(signup.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });

    // ----------------------------------------------------------------------

    builder.addCase(verification.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });

    // ----------------------------------------------------------------------

    builder.addCase(signin.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });

    // ----------------------------------------------------------------------

    builder.addCase(signout.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });

    // ----------------------------------------------------------------------

    builder.addCase(resetPassword.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });

    // ----------------------------------------------------------------------

    builder.addCase(newPassword.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });
  }
});

export default authSlice.reducer;
