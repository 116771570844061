// material
import { Box, Grid, Container, Typography } from '@mui/material';
// components
import Page from '../../components/Page';

import {
  CommunicationSMS
  // CommunicationEmail
} from '../../components/_dashboard/owner/communication';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  return (
    <Page title="Communication | FashionsPal">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Communication</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <CommunicationSMS />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={3}>
            <CommunicationEmail />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
